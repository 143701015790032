import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  courseListId: '',
  courseListName: '',
  courses: [],
  checksheetId: '',
};

export const courseListSlice = createSlice({
  name: 'courseList',
  initialState,
  reducers: {
    setCourseListAll: (state, action) => {
      state.courseListId = action.payload.courseListId;
      state.courseListName = action.payload.courseListName;
      state.courses = action.payload.courses;
      state.checksheetId = action.payload.checksheetId;

      return state;
    },
    setChecksheetId: (state, action) => {
      const updatedState = { ...state };

      updatedState.checksheetId = action.payload;

      return updatedState;
    },
    setCourses: (state, action) => {
      const updatedState = { ...state };

      updatedState.courses = action.payload;

      return updatedState;
    },

    setCourseListName: (state, action) => {
      const updatedState = { ...state };

      updatedState.courseListName = action.payload;

      return updatedState;
    },

    setCourseListId: (state, action) => {
      const updatedState = { ...state };

      updatedState.courseListId = action.payload;

      return updatedState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCourseListAll,
  setCourses,
  setCourseListId,
  setCourseListName,
  setChecksheetId,
} = courseListSlice.actions;

export default courseListSlice.reducer;
