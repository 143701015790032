import React from 'react';
// import PendingTasks from 'components/PendingTasks';
// import CatalogRollover from 'components/CatalogRollover';
import ProgramsSummary from 'components/ProgramsSummary';

const PATHome = () => {
  return (
    <div className="d-flex flex-column gap-4 my-6">
      {/* <div className="d-flex gap-4">
        <PendingTasks />
        <CatalogRollover />
      </div> */}
      <ProgramsSummary />
    </div>
  );
};

export default PATHome;
