import { createSlice } from '@reduxjs/toolkit';

export const settingsSlice = createSlice({
  name: 'settings',
  initialState: {
    year: '',
  },
  reducers: {
    setYear: (state, action) => {
      state.year = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setYear } = settingsSlice.actions;

export default settingsSlice.reducer;
