import React from 'react';
import TemplateForm from 'components/TemplateForm';

const Template = () => {
  const params = new URLSearchParams(window.location.search);
  const paramId = params.get('id');

  return (
    <div>
      <div className="px-0 text-gray-7">
        <TemplateForm tempId={paramId} />
      </div>
    </div>
  );
};

export default Template;
