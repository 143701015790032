import React, { useEffect } from 'react';
import useServiceAuth from 'auth/useServiceAuth';
import Loading from 'components/Loading';

const ServiceAuthRoute = ({ children }) => {
  const { isAuthenticated, isLoading, redirectToServiceauth } =
    useServiceAuth();

  useEffect(() => {
    if (!isAuthenticated && !isLoading) {
      console.log('User is not authenticated');
      (async () => {
        await redirectToServiceauth();
      })();
    }
  }, [isAuthenticated, isLoading, redirectToServiceauth]);

  return isAuthenticated ? children : <Loading />;
};

export default ServiceAuthRoute;
