import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateSettings } from 'state/slices/templateSlice';
import StaticData from './StaticData';
import TemplateValidationRuleRow from './TemplateValidationRuleRow';

const TemplateFormValidationRules = () => {
  const dispatch = useDispatch();

  const templateSettings = useSelector(
    (state) => state.template.templateSettings
  );

  const [temporarySettings, setTemporarySettings] = useState({
    ...templateSettings,
  });

  const handleChangeHoursSettings = (property, event) => {
    const settings = { ...templateSettings };
    settings[property] = +event.target.value;

    setTemporarySettings(settings);
  };

  const handleChangeGpaSettings = (property, event) => {
    const settings = { ...templateSettings };
    settings[property] = +event.target.value;

    dispatch(setTemplateSettings(settings));
  };

  useEffect(() => {
    setTemporarySettings({ ...templateSettings });
  }, [templateSettings]);

  return (
    <>
      <div className="mb-4">
        Validation rules configured here will apply to the overall program and
        do not belong to any specific section.
      </div>
      <div className="bg-gray-1">
        <div className="p-2">
          <div className="fw-bold fs-5 mb-2">Overall Program Requirements</div>
          <div className="mb-2 d-flex gap-7 align-items-center">
            <div>
              <div className="fw-bold">Total Credit Hours</div>
              <div className="d-flex gap-2">
                <div>
                  <div>Min</div>
                  <input
                    className="text-end"
                    type="number"
                    min="0"
                    step="1"
                    value={temporarySettings.hours ?? 0}
                    onChange={(e) => handleChangeHoursSettings('hours', e)}
                    onBlur={() => {
                      if (temporarySettings.hours !== templateSettings.hours)
                        dispatch(setTemplateSettings({ ...temporarySettings }));
                    }}
                  />
                </div>
                <div>
                  <div>Max</div>
                  <input
                    className="text-end"
                    type="number"
                    min="0"
                    step="1"
                    value={temporarySettings.maxHours ?? 0}
                    onChange={(e) => handleChangeHoursSettings('maxHours', e)}
                    onBlur={() => {
                      if (
                        temporarySettings.maxHours !== templateSettings.maxHours
                      )
                        dispatch(setTemplateSettings({ ...temporarySettings }));
                    }}
                  />
                </div>
                <div>
                  <div>Upper Division</div>
                  <input
                    className="text-end"
                    type="number"
                    min="0"
                    step="1"
                    value={temporarySettings.upperDivisionHours ?? 0}
                    onChange={(e) =>
                      handleChangeHoursSettings('upperDivisionHours', e)
                    }
                    onBlur={() => {
                      if (
                        temporarySettings.upperDivisionHours !==
                        templateSettings.upperDivisionHours
                      )
                        dispatch(setTemplateSettings({ ...temporarySettings }));
                    }}
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="fw-bold">Min GPA</div>
              <div className="d-flex gap-2">
                <div>
                  <div>GPA Minimum</div>
                  <select
                    value={
                      templateSettings.gpa !== ''
                        ? templateSettings.gpa
                        : 'undefined'
                    }
                    onChange={(e) => handleChangeGpaSettings('gpa', e)}
                    className="text-gray-7"
                    style={{ padding: '0.25rem' }}
                  >
                    {/* <option disabled value="undefined">
                            &mdash;
                          </option> */}
                    {StaticData.gpaList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div>
                  <div>Major GPA Minimum</div>
                  <select
                    value={
                      templateSettings.majorGpa !== ''
                        ? templateSettings.majorGpa
                        : 'undefined'
                    }
                    onChange={(e) => handleChangeGpaSettings('majorGpa', e)}
                    className="text-gray-7"
                    style={{ padding: '0.25rem' }}
                  >
                    {/* <option disabled value="undefined">
                            &mdash;
                          </option> */}
                    {StaticData.gpaList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="pb-2">
          <table className="w-100">
            <thead>
              <tr className="row align-items-center mx-2">
                <th className="col-6 ps-0">
                  <div className="fs-5">General Studies</div>
                </th>
                <th className="col">
                  <div className="text-center text-nowrap ms-1">
                    Credit Hours Min
                  </div>
                </th>
                <th className="col">
                  <div className="text-center text-nowrap">
                    Upper Division Hours Min
                  </div>
                </th>
                <th className="col pe-0">
                  <div className="text-end text-nowrap me-4">GPA Min</div>
                </th>
              </tr>
            </thead>
            <tbody>
              {StaticData.gsList.map((gs, index) => (
                <TemplateValidationRuleRow gs={gs} index={index} key={index} />
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default TemplateFormValidationRules;
