import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  templateId: '',
  year: '2023',
  templateName: 'Untitled template',
  templateProgramType: '',
  templateSettings: {
    hours: 0,
    maxHours: 0,
    upperDivisionHours: 0,
    gpa: 0,
    majorGpa: 0,
  },
  templateComponentIds: [],
  templateComponentObjects: {},
  templateValidationList: [],
  templateTextOptions: [],
  templateMilestones: {},
  past: [],
  future: [],
  currentAction: '',
  futureAction: '',
};

export const templateSlice = createSlice({
  name: 'template',
  initialState,
  reducers: {
    setAll: (state, action) => {
      state.templateId = action.payload.templateId;
      state.year = action.payload.year;
      state.templateName = action.payload.templateName;
      state.templateProgramType = action.payload.templateProgramType;
      state.templateSettings = action.payload.templateSettings;
      state.templateComponentIds = action.payload.templateComponentIds;
      state.templateComponentObjects = action.payload.templateComponentObjects;
      state.templateValidationList = action.payload.templateValidationList;
      state.templateTextOptions = action.payload.templateTextOptions;
      state.templateMilestones = action.payload.templateMilestones;

      return state;
    },
    setTemplateId: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateId = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'set template ID';

      return updatedState;
    },
    setYear: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.year = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change catalog year';

      return updatedState;
    },
    setTemplateName: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateName = action.payload;
      updatedState.future = [];
      updatedState.currentAction = `change template name from "${state.templateName}" to "${action.payload}"`;

      return updatedState;
    },
    setTemplateProgramType: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateProgramType = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change program type';

      return updatedState;
    },
    setTemplateSettings: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateSettings = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change template settings';

      return updatedState;
    },
    setTemplateComponentIds: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateComponentIds = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'reorder sections in template';

      return updatedState;
    },
    setTemplateComponentObjects: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateComponentObjects = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'set template section objects';

      return updatedState;
    },
    setTemplateValidationList: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateValidationList = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change validation rule';

      return updatedState;
    },
    setTemplateTextOptions: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateTextOptions = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change text option';

      return updatedState;
    },
    setTemplateMilestones: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];
      updatedState.templateMilestones = action.payload;
      updatedState.future = [];
      updatedState.currentAction = 'change milestone';

      return updatedState;
    },
    addTemplateComponent: (state, action) => {
      if (!state.templateComponentIds.includes(action.payload.id)) {
        const updatedState = { ...state };

        updatedState.past = [...state.past, { ...state }];
        updatedState.templateComponentIds = [
          ...state.templateComponentIds,
          action.payload.id,
        ];
        updatedState.templateComponentObjects = {
          ...state.templateComponentObjects,
          [action.payload.id]: action.payload.object,
        };
        updatedState.future = [];

        const componentName = JSON.parse(
          action.payload.object.details
        ).componentName;

        updatedState.currentAction = `add section "${componentName}" to template`;

        return updatedState;
      } else {
        const componentName = JSON.parse(
          state.templateComponentObjects[action.payload.id].details
        ).componentName;

        alert(`"${componentName}" is already included in this template.`);

        return state;
      }
    },
    editTemplateComponent: (state, action) => {
      state.templateComponentObjects[action.payload.id] = action.payload.object;

      return state;
    },
    removeTemplateComponent: (state, action) => {
      const updatedState = { ...state };

      updatedState.past = [...state.past, { ...state }];

      const index = state.templateComponentIds.indexOf(action.payload);

      updatedState.templateComponentIds = [
        ...state.templateComponentIds.toSpliced(index, 1),
      ];

      const { [action.payload]: value, ...rest } =
        state.templateComponentObjects;
      updatedState.templateComponentObjects = { ...rest };

      updatedState.future = [];

      const componentName = JSON.parse(
        state.templateComponentObjects[action.payload].details
      ).componentName;

      updatedState.currentAction = `remove section "${componentName}" from template`;

      return updatedState;
    },
    clearTemplate: (state) => {
      console.log('clearTemplate');

      return { ...initialState };
    },
    undo: (state) => {
      if (state.past.length > 0) {
        console.log('Undoing...');

        const previousState = state.past[state.past.length - 1];
        const updatedPast = state.past.slice(0, -1);

        return {
          ...previousState,
          past: updatedPast,
          future: [...state.future, { ...state }],
          futureAction: state.currentAction,
        };
      }

      return state;
    },
    redo: (state) => {
      if (state.future.length > 0) {
        console.log('Redoing...');

        const nextState = state.future[state.future.length - 1];
        const updatedFuture = state.future.slice(0, -1);

        return {
          ...nextState,
          past: [...state.past, { ...state }],
          future: updatedFuture,
        };
      }

      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setAll,
  setTemplateId,
  setYear,
  setTemplateName,
  setTemplateProgramType,
  setTemplateSettings,
  setTemplateComponentIds,
  setTemplateComponentObjects,
  setTemplateValidationList,
  setTemplateTextOptions,
  setTemplateMilestones,
  addTemplateComponent,
  editTemplateComponent,
  removeTemplateComponent,
  clearTemplate,
  undo,
  redo,
} = templateSlice.actions;

export default templateSlice.reducer;
