import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useMutation } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import OutsideClickHandler from 'react-outside-click-handler';
import { templateDelete } from 'apis/templateAPIs';
import { Button } from '@asu/components-core';
import { classNames } from 'utils';
import StaticData from './StaticData';

const TemplateRow = ({ template, last = false, refetch }) => {
  const { getAccessToken } = useServiceAuth();
  const date = new Date(template.updatedOn).toLocaleDateString('en-us', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  });

  const [isMenuOpen, setMenuOpen] = useState(false);

  const { mutate: deleteTemplate } = useMutation({
    mutationFn: templateDelete,
    onSuccess: async () => {
      refetch();
    },
  });

  const handleDeleteTemplate = async (id) => {
    const token = await getAccessToken();
    deleteTemplate({ id, token });
  };

  return (
    <div
      key={template.id}
      className={classNames(
        !last && 'border-bottom border-gray-3',
        'row align-items-center py-2 mx-0'
      )}
    >
      <div className="col-auto fw-bold ps-1" style={{ width: '40%' }}>
        {template.details.templateName}
      </div>
      <div className="col-auto" style={{ width: '19%', fontSize: '14px' }}>
        {template.details.programType
          ? StaticData.programTypeList.find(
              (programType) =>
                programType.value === template.details.programType
            ).label
          : '\u2014'}
      </div>
      <div className="col-auto" style={{ width: '14%', fontSize: '14px' }}>
        {template.updatedBy}
      </div>
      <div className="col-auto" style={{ width: '14%', fontSize: '14px' }}>
        {date}
      </div>
      <div
        className="col-auto d-flex gap-3 justify-content-end align-items-center pe-0"
        style={{ height: 'fit-content', width: '13%' }}
      >
        <div className="flex-grow-1 d-flex justify-content-center ">
          <Button
            label="View/edit"
            color="maroon"
            size="small"
            element={Link}
            to={`/template?id=${template.id}`}
          />
        </div>
        <OutsideClickHandler onOutsideClick={() => setMenuOpen(false)}>
          <div className="position-relative">
            <div
              className={classNames(
                isMenuOpen ? 'visible opacity-100' : 'invisible opacity-0',
                'mini-menu-container bg-white px-2 py-2 border border-gray-3'
              )}
            >
              <div
                className="mini-menu-option text-nowrap px-1 mb-1 rounded-1 opacity-50 pe-none" // not implemented yet
                role="button"
              >
                Copy template
              </div>
              <div
                className="mini-menu-option text-nowrap px-1 mb-1 rounded-1 opacity-50 pe-none" // not implemented yet
              >
                Rename
              </div>
              <div
                className="mini-menu-option text-nowrap px-1 rounded-1"
                onClick={() => handleDeleteTemplate(template.id)}
                role="button"
              >
                Delete
              </div>
            </div>
            <div
              className={classNames(
                isMenuOpen && 'bg-gray-3',
                'vertical-ellipsis p-1 lh-1 rounded-1'
              )}
              onClick={() => setMenuOpen(!isMenuOpen)}
              role="button"
            >
              <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
          </div>
        </OutsideClickHandler>
      </div>
    </div>
  );
};

export default TemplateRow;
