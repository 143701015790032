import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateTextOptions } from 'state/slices/templateSlice';
import { Button } from '@asu/components-core';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faGripVertical,
  faPenToSquare,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { classNames } from 'utils';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const Sortable = ({ id, disabled, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const TextOptionRow = ({
  option = '',
  index = null,
  groupIndex,
  showBorder,
  newOption = false,
  setNewOption = null,
  isOpen = false,
  onOpenEditOption = null,
}) => {
  const dispatch = useDispatch();

  const [textOption, setTextOption] = useState(option);
  const [showModal, setShowModal] = useState(false);

  const templateTextOptions = useSelector(
    (state) => state.template.templateTextOptions
  );

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSaveOption = () => {
    const next = [...templateTextOptions];

    const nextOptions = [...next[groupIndex].options];

    if (index === null) nextOptions.push(textOption);
    else nextOptions[index] = textOption;

    next[groupIndex] = {
      ...next[groupIndex],
      options: nextOptions,
    };

    dispatch(setTemplateTextOptions(next));

    if (newOption) {
      setTextOption('');
      setNewOption(false);
    } else {
      onOpenEditOption(null);
    }
  };

  const handleCancelEdit = () => {
    if (newOption) {
      setTextOption('');
      setNewOption(false);
    } else {
      setTextOption(option);
      onOpenEditOption(null);
    }
  };

  const handleDeleteTextOption = () => {
    const next = [...templateTextOptions];

    const nextOptions = [...next[groupIndex].options];
    console.log('Next options: ', nextOptions);
    console.log('Value: ', nextOptions[index]);
    nextOptions.splice(index, 1);

    next[groupIndex] = {
      ...next[groupIndex],
      options: nextOptions,
    };

    dispatch(setTemplateTextOptions(next));

    setShowModal(false);
  };

  useEffect(() => {
    setTextOption(option);
  }, [option, isOpen]);

  return (
    <>
      <div className="text-option-row">
        <div
          className={classNames(
            showBorder && 'border-bottom',
            newOption && 'border-top',
            'mx-2 py-2 border-gray-3'
          )}
        >
          {isOpen || newOption ? (
            <div className="d-flex gap-2">
              <textarea
                className="p-1"
                style={{ width: '70%' }}
                rows={3}
                value={textOption}
                onChange={(e) => setTextOption(e.target.value)}
              />
              <div className="d-flex gap-2" style={{ height: 'min-content' }}>
                <Button
                  label={newOption ? 'Add text option' : 'Update text option'}
                  color="maroon"
                  size="small"
                  onClick={handleSaveOption}
                />
                <Button
                  label="Cancel"
                  size="small"
                  onClick={handleCancelEdit}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex gap-2 justify-content-between">
              <div className="d-flex gap-2">
                <FontAwesomeIcon icon={faGripVertical} />
                <div>{textOption}</div>
              </div>
              <div className="d-flex gap-3">
                <div
                  className="button-link"
                  onClick={() => onOpenEditOption(option)}
                  role="button"
                >
                  Edit
                </div>
                <div onClick={handleOpenModal} role="button">
                  <FontAwesomeIcon icon={faTrash} className="text-gray-6" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <div className="p-6" style={{ width: '828px' }}>
          <div className="fs-4 fw-bold mb-4">
            Are you sure you want to delete this option?
          </div>
          <div></div>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <Button label="Cancel" color="gray" onClick={handleCloseModal} />
            <Button
              label="Yes, delete this option"
              color="maroon"
              onClick={handleDeleteTextOption}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const TextOptionGroup = ({ group, index }) => {
  const dispatch = useDispatch();
  const groupNameRef = useRef(null);

  const templateTextOptions = useSelector(
    (state) => state.template.templateTextOptions
  );

  const [editingName, setEditingName] = useState(false);
  const [groupName, setGroupName] = useState(group?.groupName ?? 'Group name');
  // const [options, setOptions] = useState(group?.options ?? []);
  const [newOption, setNewOption] = useState(false);
  const [editOption, setEditOption] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOpenEditOption = (id) => {
    setNewOption(false);
    setEditOption(editOption === id ? null : id);
  };

  const handleNewOption = () => {
    setNewOption(true);
    setEditOption(null);
  };

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleEditGroupName = () => {
    setEditingName(true);
  };

  const handleChangeGroupName = () => {
    console.log(index);

    if (templateTextOptions[index].groupName !== groupName) {
      const next = [...templateTextOptions];

      next[index] = {
        groupName,
        options: group.options,
      };

      dispatch(setTemplateTextOptions(next));
    }
    setEditingName(false);
  };

  const handleDeleteGroup = () => {
    const next = [...templateTextOptions];

    next.splice(index, 1);

    dispatch(setTemplateTextOptions(next));
    setShowModal(false);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = group.options.findIndex(
        (option) => option === active.id
      );
      const overIndex = group.options.findIndex((option) => option === over.id);

      const updatedOptions = arrayMove(group.options, activeIndex, overIndex);

      const next = [...templateTextOptions];

      next[index] = {
        ...next[index],
        options: updatedOptions,
      };

      dispatch(setTemplateTextOptions(next));
    }
  };

  useEffect(() => {
    setGroupName(group?.groupName ?? 'Group name');
    // setOptions(group?.options ?? []);
  }, [group]);

  useEffect(() => {
    if (editingName) groupNameRef.current.select();
  }, [editingName]);

  return (
    <>
      <div className="mb-3">
        <div className="d-flex justify-content-between align-items-center">
          {editingName ? (
            <input
              type="text"
              ref={groupNameRef}
              className="p-1 fs-4"
              style={{ width: '50%', margin: '7px 0' }}
              value={groupName}
              onChange={(e) => setGroupName(e.target.value)}
              onBlur={handleChangeGroupName}
            />
          ) : (
            <h3 onClick={handleEditGroupName} role="button">
              {groupName}
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="text-maroon ms-2"
                width={16}
              />
            </h3>
          )}
          <div className="button-link" onClick={handleOpenModal} role="button">
            Delete group
          </div>
        </div>
        <div className="mb-2">
          <div className="row border-bottom border-gray-7 border-2 py-1 m-0">
            <div className="col-auto ps-0">
              <div className="fw-bold">Requirement</div>
            </div>
          </div>
          <div className="border border-top-0 border-gray-3">
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToVerticalAxis]}
            >
              <SortableContext
                items={group.options.map((option) => option)}
                strategy={verticalListSortingStrategy}
              >
                {!!group.options?.length &&
                  group.options.map((option, optionIndex, array) => (
                    <Sortable
                      key={option}
                      id={option}
                      disabled={editOption !== null || newOption}
                    >
                      <TextOptionRow
                        key={optionIndex}
                        option={option}
                        index={optionIndex}
                        groupIndex={index}
                        showBorder={optionIndex !== array.length - 1}
                        isOpen={editOption === option}
                        onOpenEditOption={handleOpenEditOption}
                      />
                    </Sortable>
                  ))}
              </SortableContext>
            </DndContext>
            <div
              className={classNames(
                newOption ? 'visible opacity-100' : 'invisible opacity-0',
                'new-text-option'
              )}
              style={{ height: newOption ? '105px' : 0 }}
            >
              <TextOptionRow
                groupIndex={index}
                newOption={true}
                setNewOption={setNewOption}
              />
            </div>
            {!group.options?.length && !newOption && (
              <div className="text-gray-6 p-3">No text options added</div>
            )}
          </div>
        </div>
        <Button
          label="Create new option"
          color="maroon"
          size="small"
          onClick={handleNewOption}
        />
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <div className="p-6" style={{ width: '828px' }}>
          <div className="fs-4 fw-bold mb-4">
            Are you sure you want to delete this group?
          </div>
          <div></div>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <Button label="Cancel" color="gray" onClick={handleCloseModal} />
            <Button
              label="Yes, delete this group"
              color="maroon"
              onClick={handleDeleteGroup}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const TemplateFormTextOptions = () => {
  const dispatch = useDispatch();

  const templateTextOptions = useSelector(
    (state) => state.template.templateTextOptions
  );

  const handleNewGroup = () => {
    const next = [...templateTextOptions];

    next.push({
      groupName: 'Group name',
      options: [],
    });

    dispatch(setTemplateTextOptions(next));
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            Configure text options for the Text requirement type. The following
            options will be available to all users of this template.
          </div>
          <Button label="New group" color="gold" onClick={handleNewGroup} />
        </div>
        <div>
          {!!templateTextOptions?.length ? (
            templateTextOptions.map((group, index, array) => (
              <TextOptionGroup key={index} group={group} index={index} />
            ))
          ) : (
            <div className="text-gray-6 py-3 fst-italic">
              No text options or groups added
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TemplateFormTextOptions;
