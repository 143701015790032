import React, { useState, useEffect, useRef } from 'react';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faMagnifyingGlass,
  // faGripVertical,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';

import { useMutation, useQuery } from '@tanstack/react-query';
import { classNames } from 'utils';
// import OutsideClickHandler from 'react-outside-click-handler';
import { subjectGetAll } from 'apis/classAPIs';

import CourseList from './RequirementCourseList';
import Elective from './RequirementElective';
import Text from './RequirementText';
import GeneralStudy from './RequirementGeneralStudy';
import Check from './RequirementCheck';

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const Course = ({
  index,
  year,
  data,
  handleInputChange,
  handleDeleteComponent,
  showCourseListOption,
  isCourseListItem = false,
  isRecommendedCourse = false,
  handleShowAsCourseList = null,
}) => {
  const [topics, setTopics] = useState([]);
  const [showTopicsDropdown, setShowTopicsDropdown] = useState(false);
  const [showSubjectDropdown, setShowSubjectDropdown] = useState(false);

  const { data: getSubjectsData, isSuccess: getSubjectsIsSuccess } = useQuery({
    queryKey: ['subjects'],
    queryFn: async () => {
      return subjectGetAll({ term: '2251' });
    },
  });

  const {
    mutate: fetchCourse,
    data: fetchCourseData,
    error: fetchCourseError,
    isSuccess: fetchCourseIsSuccess,
    isPending: fetchCourseIsLoading,
  } = useMutation({
    mutationFn: async ({ subject, catalogNumber }) => {
      var courseCatalogYear = '2257';

      if (year) {
        courseCatalogYear = createTerm(year);
      }

      const url =
        'https://api.myasuplat-dpl.asu.edu/api/course/' +
        courseCatalogYear +
        '/subject/' +
        subject +
        '/' +
        catalogNumber;

      console.log('url: ' + url);
      const response = await fetch(url);
      console.log('response: ' + response);

      if (!response.ok) {
        throw new Error(
          'Failed to fetch DPL data for ' + subject + ' ' + catalogNumber
        );
      }

      const result = await response.json();
      console.log('result: ' + JSON.stringify(result));

      return result;
    },
  });

  const inputRef = useRef(null);

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const clearRequirement = () => {
    handleInputChange(index, 'description', '');
    handleInputChange(index, 'gs', '');
    handleInputChange(index, 'hours', 0);
    handleInputChange(index, 'maxHours', 0);
    handleInputChange(index, 'topic', null);
    handleInputChange(index, 'courseId', '');
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'itemType', value);
    handleInputChange(index, 'subject', '');
    handleInputChange(index, 'number', '');
    clearRequirement();
  };

  const handleSubjectInputChange = (e) => {
    const { value } = e.target;

    clearRequirement();

    if (
      value.length === 3 &&
      data.number &&
      data.number?.length >= 2 &&
      data.number?.length <= 9
    )
      fetchCourse({
        subject: value.toUpperCase(),
        catalogNumber: data.number,
      });

    handleInputChange(index, 'subject', value.toUpperCase());
    setShowSubjectDropdown(true);
  };

  const handleSelectCourseSubject = (subject) => {
    setShowTopicsDropdown(false);
    handleInputChange(index, 'topic', null);

    if (data.number && data.number?.length >= 2 && data.number?.length <= 9) {
      fetchCourse({
        subject: subject.CODE,
        catalogNumber: data.number,
      });
    }

    handleInputChange(index, 'subject', subject.CODE);
    setShowSubjectDropdown(false);
  };

  const handleNumberInputChange = (e) => {
    const { value } = e.target;

    clearRequirement();
    setShowTopicsDropdown(false);
    handleInputChange(index, 'topic', null);

    if (
      value.length >= 2 &&
      value.length <= 9 &&
      data.subject &&
      data.subject?.length === 3
    )
      fetchCourse({
        subject: data.subject,
        catalogNumber: value,
      });

    handleInputChange(index, 'number', value);
  };

  // Sort topics by description
  const handleEditTopic = () => {
    if (data.number?.length === 3 && data.subject && data.subject?.length === 3)
      fetchCourse({
        subject: data.subject,
        catalogNumber: data.number,
      });

    setShowTopicsDropdown(true);
  };

  const handleTopicChange = (e) => {
    const { value } = e.target;
    const sortedTopics =
      topics &&
      [...topics].sort((a, b) => a.description.localeCompare(b.description));

    if (value === data.description) {
      // this is the main course
      handleInputChange(index, 'topic', {});
    } else {
      const selected = sortedTopics.find(
        (topic) => topic.description === value
      );

      handleInputChange(index, 'topic', selected);
    }
  };

  const handleProposedTitleChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'proposedTitle', value);
  };

  const handleProposedHoursChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'proposedHours', value);
  };

  function createTerm(year) {
    // Convert year to a string and extract the last two digits
    const lastTwoDigits = year.toString().slice(-2);

    // Construct the term by adding '2' at the beginning and '7' at the end
    const term = `2${lastTwoDigits}7`;

    return term;
  }

  // Handle prop updates directly
  // useEffect(() => {
  //   // if (data?.subject) setCourseSubject(data.subject);
  //   // if (data?.number) setCourseNumber(data.number);
  //   // if (data?.gs) setCourseGs(data.gs);
  //   // if (data?.hours) setCourseHours(data.hours);
  //   // if (data?.maxHours) setCourseMaxHours(data.maxHours);
  //   // if (data?.description) setCourseDescription(data.description);
  //   // if (data?.connector) setConnector(data.connector);
  //   // if (data?.topic) setCourseTopic(data.topic);
  //   // if (data?.itemType) setItemType(data.itemType);
  // }, [data]);

  useEffect(() => {
    if (
      data.subject?.length === 3 &&
      data.number?.length >= 2 &&
      data.number?.length <= 9
    ) {
      if (fetchCourseIsSuccess) {
        handleInputChange(index, 'gs', '');
        handleInputChange(index, 'description', '');
        handleInputChange(index, 'hours', 0);
        handleInputChange(index, 'maxHours', 0);
        handleInputChange(index, 'courseId', '');
        console.log('fetchCourseData: ' + JSON.stringify(fetchCourseData));

        if (fetchCourseData.length > 0) {
          setTopics(fetchCourseData[0].topics);
          handleInputChange(
            index,
            'description',
            fetchCourseData[0].description
          );
          handleInputChange(index, 'proposedTitle', '');

          if (fetchCourseData[0].topics) {
            setShowTopicsDropdown(true);
          }

          handleInputChange(
            index,
            'description',
            fetchCourseData[0].description
          );

          if (fetchCourseData[0].requirementDesignationDescription) {
            var fetchCourseGs =
              fetchCourseData[0].requirementDesignationDescription.split(
                ' '
              )[0];

            if (fetchCourseGs.length !== 4) {
              fetchCourseGs = '';
            }

            handleInputChange(index, 'gs', fetchCourseGs);
          }

          if (fetchCourseData[0].approvedUnitsMinimum) {
            handleInputChange(
              index,
              'hours',
              fetchCourseData[0].approvedUnitsMinimum
            );
          }

          if (fetchCourseData[0].approvedUnitsMaximum) {
            handleInputChange(
              index,
              'maxHours',
              fetchCourseData[0].approvedUnitsMaximum
            );
          }

          if (fetchCourseData[0].courseId) {
            handleInputChange(index, 'courseId', fetchCourseData[0].courseId);
          }
        } else {
          handleInputChange(index, 'description', 'course not found');
        }
      }

      if (fetchCourseError) {
        console.error('Error fetching data:', fetchCourseError.message);
      }
    } else {
      handleInputChange(index, 'description', '');
      handleInputChange(index, 'gs', '');
      handleInputChange(index, 'hours', 0);
      handleInputChange(index, 'maxHours', 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCourseIsSuccess, fetchCourseData]);

  useEffect(() => {
    let filteredSubjects;

    if (data.subject && getSubjectsIsSuccess)
      filteredSubjects = getSubjectsData.filter((subject) =>
        subject.CODE.includes(data.subject.toUpperCase())
      );

    if (!data.subject || !filteredSubjects?.length)
      setShowSubjectDropdown(false);
  }, [data.subject, getSubjectsData, getSubjectsIsSuccess]);

  useEffect(() => {
    // Focus the input field when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <>
      {data.itemType === 'req_course' || !data.itemType ? (
        <>
          {isCourseListItem && data.connector && (
            <div style={{ width: '80px' }} className="mx-5 pb-2">
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={
                  index === 0 &&
                  (!data.connector || data.connector.trim() === '')
                }
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}

          <div className="d-flex gap-2 pb-2">
            {/* Remove until reordering is finished */}
            {/* {!isCourseListItem && (
              <FontAwesomeIcon
                icon={faGripVertical}
                style={{ width: '15px' }}
                className="pt-4"
              />
            )} */}
            <div className="mt-4 me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteComponent(index)}
                className={`deleteButton ${showCourseListOption ? 'fa-trash-active' : ''}`}
              />
            </div>

            {!isRecommendedCourse && !isCourseListItem && (
              <div style={{ width: '132px' }}>
                <div className="fw-bold text-nowrap">Logical operator</div>
                <select
                  className="text-gray-7 p-1 w-100"
                  value={data.connector ?? ''}
                  name="connector"
                  onChange={(e) => handleConnectorChange(e)}
                  disabled={
                    index === 0 &&
                    (!data.connector || data.connector.trim() === '')
                  }
                >
                  {StaticData.connectors.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}

            {!isRecommendedCourse && (
              <div>
                <div className="fw-bold">Requirement type</div>
                <div>
                  <select
                    style={{
                      width: '150px',
                    }}
                    className="text-gray-7 p-1"
                    value={'req_course'}
                    onChange={(e) => handleRequirementTypeChange(e)}
                  >
                    {showCourseListOption
                      ? StaticData.reqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      : isCourseListItem
                        ? StaticData.courseListReqList.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))
                        : StaticData.templateReqList.map((option) => (
                            <option key={option.value} value={option.value}>
                              {option.label}
                            </option>
                          ))}
                  </select>
                </div>
              </div>
            )}

            <div>
              <div className="fw-bold">Subject</div>
              {/* <OutsideClickHandler
                onOutsideClick={() => setShowSubjectDropdown(false)}
              > */}
              <div className="position-relative">
                <input
                  type="text"
                  name="subject"
                  value={data.subject ?? ''}
                  onChange={(e) => handleSubjectInputChange(e)}
                  onBlur={() =>
                    setTimeout(() => setShowSubjectDropdown(false), 200)
                  }
                  className="p-1"
                  style={{ width: '110px' }}
                  autoComplete="off"
                  maxLength={3}
                  placeholder="Subject"
                  ref={inputRef} // Attach the ref to the input field
                />
                {data.subject?.length === 0 && (
                  <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    style={{
                      position: 'absolute',
                      color: 'gray',
                      left: '70px',
                      top: '50%',
                      transform: 'translateY(-50%)',
                    }}
                  />
                )}
                {showSubjectDropdown && (
                  <div
                    className="position-absolute border border-gray-5 overflow-y-auto"
                    style={{
                      maxHeight: '170px',
                      width: '350px',
                      zIndex: 1,
                    }}
                  >
                    {getSubjectsIsSuccess &&
                      getSubjectsData
                        .filter((subject) =>
                          subject.CODE.includes(data.subject.toUpperCase())
                        )
                        .map((subject, index, array) => (
                          <div
                            key={subject.CODE}
                            className={classNames(
                              index !== array.length - 1 && 'border-bottom',
                              'border-gray-5 p-2 bg-white d-flex gap-2'
                            )}
                            onClick={() => handleSelectCourseSubject(subject)}
                            role="button"
                          >
                            <div className="fs-6 fw-bold">{subject.CODE}</div>
                            <div>{subject.DESCR}</div>
                          </div>
                        ))}
                  </div>
                )}
              </div>
              {/* </OutsideClickHandler> */}
            </div>

            <div>
              <div className="fw-bold">Number</div>
              <input
                className="p-1"
                style={{ width: '110px' }}
                type="text"
                name="number"
                value={data.number ?? ''}
                placeholder="Number"
                onChange={(e) => handleNumberInputChange(e)}
              />
            </div>

            {showTopicsDropdown ? (
              <div className="ms-1" style={{ width: '200px' }}>
                <div className="fw-bold">Course title</div>
                {fetchCourseIsLoading ? (
                  <div>Loading...</div>
                ) : (
                  <select
                    value={(data.topic?.description || data.description) ?? ''}
                    onChange={(e) => handleTopicChange(e)}
                  >
                    <option value="" disabled>
                      Select title
                    </option>
                    {/* Add a default option for the main course */}
                    <option value={data.description}>{data.description}</option>
                    {topics &&
                      topics
                        .toSorted((a, b) =>
                          a.description.localeCompare(b.description)
                        )
                        .map((topic, index) => (
                          <option key={index} value={topic.description}>
                            {topic.description}
                          </option>
                        ))}
                  </select>
                )}
                {!!data.hours ? data.hours : '\u2014'}
                {!!data.maxHours &&
                  data.maxHours !== 0 &&
                  data.maxHours !== data.hours &&
                  ` - ${data.maxHours}`}{' '}
                credit hours
              </div>
            ) : data.proposedTitle ||
              data.description === 'course not found' ? (
              <div className="d-flex gap-2">
                <div>
                  <div className="fw-bold">Course title (proposed)</div>
                  <input
                    type="text"
                    placeholder="Course title"
                    value={data.proposedTitle ?? ''}
                    onChange={handleProposedTitleChange}
                    style={{ width: '400px' }}
                  />
                </div>
                <div style={{ width: '100px' }}>
                  <div className="fw-bold">Credit hours</div>
                  <input
                    type="number"
                    placeholder="#"
                    value={data.proposedHours ?? 0}
                    onChange={handleProposedHoursChange}
                    className="text-end"
                    style={{ width: '100px' }}
                  />
                </div>
              </div>
            ) : (
              (!!data.topic || !!data.description) && (
                <div>
                  <div className="fw-bold">Course title</div>
                  <div style={{ paddingTop: '4px' }}>
                    {data.topic?.description ? (
                      <span
                        className="text-maroon underline-hover"
                        onClick={handleEditTopic}
                        role="button"
                      >
                        <FontAwesomeIcon
                          icon={faPenToSquare}
                          className="me-1"
                        />
                        {data.topic.description}
                      </span>
                    ) : (
                      <span>{data.description}</span>
                    )}
                    {!!data.gs && <span> ({data.gs})</span>}
                    {!!data.hours && ` (${data.hours} `}
                    {!!data.maxHours &&
                      data.maxHours !== 0 &&
                      data.maxHours !== data.hours &&
                      `${' - '} ${data.maxHours}`}{' '}
                    {!!data.hours && ` credit hours) `}
                  </div>
                </div>
              )
            )}
          </div>
        </>
      ) : data.itemType === 'req_course_list' ? (
        <CourseList
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          handleShowAsCourseList={handleShowAsCourseList}
        />
      ) : data.itemType === 'req_elective' ? (
        <Elective
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_gs' ? (
        <GeneralStudy
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_text' ? (
        <Text
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_check' ? (
        <Check
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default Course;
