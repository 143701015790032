import React, { useState, useEffect } from 'react';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  // faGripVertical,
  faMagnifyingGlass,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

import { useQuery } from '@tanstack/react-query';
import { classNames } from 'utils';
// import OutsideClickHandler from 'react-outside-click-handler';
import { subjectGetAll } from 'apis/classAPIs';

import CourseList from './RequirementCourseList';
import Elective from './RequirementElective';
import Text from './RequirementText';
import GeneralStudy from './RequirementGeneralStudy';
import Course from './RequirementCourse';
import { useSelector } from 'react-redux';

const CourseFields = ({
  course,
  courseIndex,
  arrayLength,
  handleCourseInputChange,
  handleRemoveCourse,
  getSubjectsData,
  getSubjectsIsSuccess,
}) => {
  const [showCourseSubjectDropdown, setShowCourseSubjectDropdown] =
    useState(false);

  const handleCourseSubjectInputChange = (e) => {
    const { value } = e.target;

    handleCourseInputChange(courseIndex, 'subject', value.toUpperCase());
    setShowCourseSubjectDropdown(true);
  };

  const handleCourseNumberInputChange = (e) => {
    const { value } = e.target;

    handleCourseInputChange(courseIndex, 'number', value.toUpperCase());
  };

  const handleSelectCourseSubject = (sub) => {
    handleCourseInputChange(courseIndex, 'subject', sub.CODE);
    setShowCourseSubjectDropdown(false);
  };

  useEffect(() => {
    let filteredSubjects;

    if (course.subject && getSubjectsIsSuccess)
      filteredSubjects = getSubjectsData.filter((sub) =>
        sub.CODE.includes(course.subject.toUpperCase())
      );

    if (!course.subject || !filteredSubjects?.length)
      setShowCourseSubjectDropdown(false);
  }, [course.subject, getSubjectsData, getSubjectsIsSuccess]);

  return (
    <div className="d-flex align-items-center mb-1">
      {arrayLength > 2 && (
        <FontAwesomeIcon
          icon={faTrash}
          className="me-1"
          onClick={() => handleRemoveCourse(courseIndex)}
          role="button"
        />
      )}
      {/* <OutsideClickHandler
        onOutsideClick={() => setShowCourseSubjectDropdown(false)}
      > */}
      <div className="position-relative">
        <input
          type="text"
          name={'courseSubject' + courseIndex}
          value={course.subject ?? ''}
          onChange={(e) => handleCourseSubjectInputChange(e)}
          onBlur={() =>
            setTimeout(() => setShowCourseSubjectDropdown(false), 200)
          }
          className="p-1 border-end-0"
          style={{ width: '95px' }}
          autoComplete="off"
          maxLength={3}
          placeholder="Subject"
        />
        {showCourseSubjectDropdown && (
          <div
            className="position-absolute border border-gray-5 overflow-y-auto"
            style={{
              maxHeight: '170px',
              width: '350px',
              zIndex: 1,
            }}
          >
            {getSubjectsIsSuccess &&
              getSubjectsData
                .filter((sub) =>
                  sub.CODE.includes(course.subject.toUpperCase())
                )
                .map((sub, index, array) => (
                  <div
                    key={sub.CODE}
                    className={classNames(
                      index !== array.length - 1 && 'border-bottom',
                      'border-gray-5 p-2 bg-white d-flex gap-2'
                    )}
                    onClick={() => handleSelectCourseSubject(sub)}
                    role="button"
                  >
                    <div className="fs-6 fw-bold">{sub.CODE}</div>
                    <div>{sub.DESCR}</div>
                  </div>
                ))}
          </div>
        )}
      </div>
      {/* </OutsideClickHandler> */}
      <input
        type="text"
        placeholder="###"
        value={course.number ?? ''}
        onChange={(e) => handleCourseNumberInputChange(e)}
        style={{ width: '55px' }}
      />
    </div>
  );
};

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const Check = ({
  index,
  data,
  handleInputChange,
  handleDeleteComponent,
  showCourseListOption,
  isCourseListItem = false,
  handleShowAsCourseList = null,
}) => {
  const [checkCourses, setCheckCourses] = useState(
    data?.checkCourses?.length
      ? data.checkCourses
      : [
          { subject: '', number: '' },
          { subject: '', number: '' },
        ]
  );

  const [showSubjectDropdown, setShowSubjectDropdown] = useState(false);

  const templateMilestones = useSelector(
    (state) => state.template.templateMilestones
  );

  const { data: getSubjectsData, isSuccess: getSubjectsIsSuccess } = useQuery({
    queryKey: ['subjects'],
    queryFn: async () => {
      return subjectGetAll({ term: '2251' });
    },
  });

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const clearRequirement = () => {
    handleInputChange(index, 'gpa', '');
    handleInputChange(index, 'subject', '');
    handleInputChange(index, 'division', '');
    handleInputChange(index, 'milestone', '');

    setCheckCourses([
      { subject: '', number: '' },
      { subject: '', number: '' },
    ]);
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'itemType', value);
    handleInputChange(index, 'checkType', '');
    handleInputChange(index, 'checkSubtype', '');
    clearRequirement();
  };

  const handleCheckTypeChange = (e) => {
    const { value } = e.target;
    console.log('value: ', value);

    handleInputChange(index, 'checkType', value);
    handleInputChange(index, 'checkSubtype', '');
    clearRequirement();
  };

  const handleCheckSubtypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'checkSubtype', value);
    clearRequirement();
  };

  const handleGpaChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'gpa', value);
  };

  const handleDivisionChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'division', value);
  };

  const handleSubjectInputChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'subject', value.toUpperCase());
    setShowSubjectDropdown(true);
  };

  const handleSelectSubject = (sub) => {
    handleInputChange(index, 'subject', sub.CODE);
    setShowSubjectDropdown(false);
  };

  const handleAddCourse = () => {
    setCheckCourses((prevCourses) => {
      const updatedCourses = [...prevCourses];
      updatedCourses.push({ subject: '', number: '' });
      return updatedCourses;
    });
  };

  const handleRemoveCourse = (courseIndex) => {
    setCheckCourses((prevCourses) => {
      const updatedCourses = [...prevCourses];
      updatedCourses.splice(courseIndex, 1);
      return updatedCourses;
    });
  };

  const handleCourseInputChange = (courseIndex, field, value) => {
    setCheckCourses((prevCourses) => {
      const updatedCourses = [...prevCourses];
      updatedCourses[courseIndex][field] = value;
      return updatedCourses;
    });
  };

  const handleMilestoneChange = (e) => {
    const { value } = e.target;

    if (value === '') handleInputChange(index, 'milestone', {});
    else
      handleInputChange(index, 'milestone', {
        [value]: templateMilestones[value],
      });
  };

  useEffect(() => {
    handleInputChange(index, 'checkCourses', checkCourses);
  }, [checkCourses, index, handleInputChange]);

  useEffect(() => {
    let filteredSubjects;

    if (data.subject && getSubjectsIsSuccess)
      filteredSubjects = getSubjectsData.filter((sub) =>
        sub.CODE.includes(data.subject.toUpperCase())
      );

    if (!data.subject || !filteredSubjects?.length)
      setShowSubjectDropdown(false);
  }, [data.subject, getSubjectsData, getSubjectsIsSuccess]);

  return (
    <>
      {data.itemType === 'req_check' || !data.itemType ? (
        <>
          {isCourseListItem && data.connector && (
            <div style={{ width: '80px' }} className="mx-5 pb-2">
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={index === 0}
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="d-flex gap-2 pb-2">
            {/* Remove until reordering is finished */}
            {/* {!isCourseListItem && (
              <FontAwesomeIcon
                icon={faGripVertical}
                style={{ width: '15px' }}
                className="pt-4"
              />
            )} */}
            <div className="mt-4 me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteComponent(index)}
                className={`deleteButton ${showCourseListOption ? 'fa-trash-active' : ''}`}
              />
            </div>

            {!isCourseListItem && (
              <div style={{ width: '132px' }}>
                <div className="fw-bold">Logical operator</div>
                <select
                  className="text-gray-7 p-1 w-100"
                  value={data.connector ?? ''}
                  name="connector"
                  onChange={(e) => handleConnectorChange(e)}
                  disabled={index === 0}
                >
                  {StaticData.connectors.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <div className="fw-bold">Requirement type</div>
              <div>
                <select
                  style={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                  value={'req_check'}
                  onChange={(e) => handleRequirementTypeChange(e)}
                >
                  {showCourseListOption
                    ? StaticData.reqList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : StaticData.templateReqList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                </select>
              </div>
            </div>
            <div>
              <div className="fw-bold">Check type</div>
              <div className="d-flex">
                <select
                  style={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                  value={data.checkType ?? ''}
                  onChange={(e) => handleCheckTypeChange(e)}
                >
                  <option value=""></option>
                  {StaticData.checkType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                {data.checkType === 'gpa' && (
                  <select
                    style={{
                      width: '150px',
                    }}
                    className="text-gray-7 p-1"
                    value={data.checkSubtype ?? ''}
                    onChange={(e) => handleCheckSubtypeChange(e)}
                  >
                    <option value=""></option>
                    {StaticData.checkSubtype.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                )}
              </div>
            </div>
            {data.checkSubtype === 'subject' ? (
              <>
                <div>
                  <div className="fw-bold">Subject</div>
                  {/* <OutsideClickHandler
                    onOutsideClick={() => setShowSubjectDropdown(false)}
                  > */}
                  <div className="position-relative">
                    <input
                      type="text"
                      name="subject"
                      value={data.subject ?? ''}
                      onChange={(e) => handleSubjectInputChange(e)}
                      onBlur={() =>
                        setTimeout(() => setShowSubjectDropdown(false), 200)
                      }
                      className="p-1"
                      style={{ width: '110px' }}
                      autoComplete="off"
                      maxLength={3}
                      placeholder="Subject"
                    />
                    {data.subject?.length === 0 && (
                      <FontAwesomeIcon
                        icon={faMagnifyingGlass}
                        style={{
                          position: 'absolute',
                          color: 'gray',
                          left: '70px',
                          top: '50%',
                          transform: 'translateY(-50%)',
                        }}
                      />
                    )}
                    {showSubjectDropdown && (
                      <div
                        className="position-absolute border border-gray-5 overflow-y-auto"
                        style={{
                          maxHeight: '170px',
                          width: '350px',
                          zIndex: 1,
                        }}
                      >
                        {getSubjectsIsSuccess &&
                          getSubjectsData
                            .filter((sub) =>
                              sub.CODE.includes(data.subject.toUpperCase())
                            )
                            .map((sub, index, array) => (
                              <div
                                key={sub.CODE}
                                className={classNames(
                                  index !== array.length - 1 && 'border-bottom',
                                  'border-gray-5 p-2 bg-white d-flex gap-2'
                                )}
                                onClick={() => handleSelectSubject(sub)}
                                role="button"
                              >
                                <div className="fs-6 fw-bold">{sub.CODE}</div>
                                <div>{sub.DESCR}</div>
                              </div>
                            ))}
                      </div>
                    )}
                  </div>
                  {/* </OutsideClickHandler> */}
                </div>
                <div>
                  <div className="fw-bold">Level (optional)</div>
                  <select
                    style={{ width: '178px' }}
                    className={classNames(
                      data.division === '' ? 'text-gray-5' : 'text-gray-7',
                      'p-1'
                    )}
                    value={data.division ?? ''}
                    onChange={(e) => handleDivisionChange(e)}
                  >
                    <option value="" disabled>
                      Level
                    </option>
                    {StaticData.divisionList.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
              </>
            ) : (
              data.checkSubtype === 'courses' && (
                <div>
                  <div className="fw-bold">Subject</div>
                  {checkCourses.map((course, courseIndex, array) => (
                    <CourseFields
                      key={courseIndex}
                      course={course}
                      courseIndex={courseIndex}
                      arrayLength={array.length}
                      handleCourseInputChange={handleCourseInputChange}
                      handleRemoveCourse={handleRemoveCourse}
                      getSubjectsData={getSubjectsData}
                      getSubjectsIsSuccess={getSubjectsIsSuccess}
                    />
                  ))}
                  <div
                    className={classNames(
                      checkCourses.length > 2 && 'ms-3',
                      'd-flex gap-1 align-items-center text-maroon'
                    )}
                    onClick={handleAddCourse}
                    role="button"
                  >
                    <FontAwesomeIcon icon={faCirclePlus} />
                    <div>Add course</div>
                  </div>
                </div>
              )
            )}
            {data.checkType === 'gpa' && (
              <div>
                <div className="fw-bold">Min GPA</div>
                <input
                  type="number"
                  style={{ width: '95px' }}
                  placeholder="Min GPA"
                  min={0}
                  max={4.0}
                  step={1 / 3}
                  value={data.gpa ?? ''}
                  onChange={(e) => handleGpaChange(e)}
                />
              </div>
            )}
            {data.checkType === 'milestone' && (
              <div>
                <div className="fw-bold">Milestone</div>
                <select
                  value={data.milestone ? Object.keys(data.milestone)[0] : ''}
                  onChange={(e) => handleMilestoneChange(e)}
                  style={{ width: '476px' }}
                >
                  <option value=""></option>
                  <option value="" disabled>
                    {templateMilestones &&
                    !!Object.keys(templateMilestones).length
                      ? 'Select a milestone'
                      : 'No milestones configured'}{' '}
                  </option>
                  {templateMilestones &&
                    Object.entries(templateMilestones).map(
                      ([milestoneId, msText]) => (
                        <option key={milestoneId} value={milestoneId}>
                          {msText}
                        </option>
                      )
                    )}
                </select>
                {!!data.milestone &&
                  !!Object.keys(data.milestone).length &&
                  !templateMilestones[Object.keys(data.milestone)[0]] && (
                    <div>
                      Milestone '{Object.values(data.milestone)[0]}' has been
                      deleted from the template.
                    </div>
                  )}
              </div>
            )}
          </div>
        </>
      ) : data.itemType === 'req_course' ? (
        <Course
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_course_list' ? (
        <CourseList
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          handleShowAsCourseList={handleShowAsCourseList}
        />
      ) : data.itemType === 'req_elective' ? (
        <Elective
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_gs' ? (
        <GeneralStudy
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : data.itemType === 'req_text' ? (
        <Text
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          showCourseListOption={showCourseListOption}
          isCourseListItem={isCourseListItem}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default Check;
