import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addTemplateComponent } from 'state/slices/templateSlice';
import { setCurrentComponent } from 'state/slices/componentSlice';
import {
  setComponentList,
  setComponentListLoading,
  setFolderList,
} from 'state/slices/dataListsSlice';
import {
  faEllipsis,
  faFolderClosed,
  faMagnifyingGlass,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabPanel from './TabPanel';
import {
  DndContext,
  DragOverlay,
  useDroppable,
  useDraggable,
} from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';
import useServiceAuth from 'auth/useServiceAuth';
import OutsideClickHandler from 'react-outside-click-handler';
import {
  componentGet,
  componentGetAll,
  componentUpdate,
  componentDelete,
} from 'apis/componentAPIs';
import { useMutation, useQuery } from '@tanstack/react-query';
import { Button } from '@asu/components-core/dist/libCore.es';
import { classNames } from 'utils';
// import PreviewChecksheets from './PreviewChecksheets';
import LoadingSkeleton from './LoadingSkeleton';
import { Modal } from 'react-bootstrap';
import ComponentForm from './ComponentForm';

const Droppable = ({ children, id }) => {
  const { isOver, setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div
      ref={setNodeRef}
      className={classNames(
        isOver ? 'bg-gray-2 rounded' : 'bg-gray-1',
        'drop-folder ps-1'
      )}
    >
      {children}
    </div>
  );
};

const Draggable = ({ children, id, disabled }) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({
      id: id,
      disabled: disabled,
    });
  const style = {
    transform: CSS.Translate.toString(transform),
    visibility: isDragging ? 'hidden' : undefined,
  };

  return (
    <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
      {children}
    </div>
  );
};

const ComponentRow = ({
  component,
  activeId,
  disabled,
  setDisabled,
  foldered = false,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const [isMenuOpen, setMenuOpen] = useState(false);

  const { mutate: getAllComponents } = useMutation({
    mutationFn: componentGetAll,
    onSuccess: async (data) => {
      const next = [];

      data.forEach((component) => {
        if (component['details']) {
          const jsonObject = JSON.parse(component['details'].S);
          const obj = {
            id: component['sk'].S,
            details: jsonObject,
            componentObj: component,
          };
          next.push(obj);
        }
      });

      dispatch(setComponentList(next));
    },
  });

  // Delete existing component in database
  const { mutate: deleteComponent } = useMutation({
    mutationFn: componentDelete,
    onSuccess: async (data, { token }) => {
      getAllComponents({ token });
    },
  });

  const handleDeleteComponent = async (id, component) => {
    const token = await getAccessToken();
    await deleteComponent({
      id: id,
      token: token,
    });

    setMenuOpen(false);
    setDisabled(false);
  };

  const handleAddComponent = (component) => {
    const obj = {
      sk: component['sk'].S,
      updatedBy: component['updatedBy'].S,
      updatedOn: component['updatedOn'].S,
      details: component['details'].S,
      pk: component['pk'].S,
    };

    dispatch(addTemplateComponent({ id: component['sk'].S, object: obj }));

    setMenuOpen(false);
    setDisabled(false);
  };

  const handleOpenMenu = () => {
    setMenuOpen(!isMenuOpen);
    setDisabled();
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
    setDisabled(false);
  };

  return (
    <div className="d-flex justify-content-between mb-2 rounded library-component">
      <div
        className={classNames(
          activeId === component.id && 'bg-gray-2',
          foldered && 'ms-2',
          'px-1 rounded'
        )}
      >
        <Draggable key={component.id} id={component.id} disabled={disabled}>
          {activeId === component.id ? null : (
            <div
              onDoubleClick={() => handleAddComponent(component.componentObj)}
              key={component.id}
              role="button"
            >
              {component.details.componentName}
            </div>
          )}
        </Draggable>
        {activeId === component.id && (
          <div key={component.id + 'prev'}>
            {component.details.componentName}
          </div>
        )}
      </div>
      <OutsideClickHandler
        onOutsideClick={handleCloseMenu}
        disabled={!isMenuOpen}
      >
        <div className="position-relative">
          <div
            className={classNames(
              isMenuOpen ? 'visible opacity-100' : 'invisible opacity-0',
              'mini-menu-container bg-white px-2 py-2 border border-gray-3'
            )}
          >
            <div
              className={classNames(
                // !checksheetFound && 'pe-none opacity-50',
                'mini-menu-option text-nowrap px-1 mb-1 rounded-1'
              )}
              onClick={() => handleAddComponent(component.componentObj)}
              role="button"
            >
              Add to template
            </div>
            <div
              className={classNames(
                // !checksheetFound && 'pe-none opacity-50',
                'mini-menu-option text-nowrap px-1 rounded-1'
              )}
              onClick={() => handleDeleteComponent(component.id)}
              role="button"
            >
              Delete
            </div>
          </div>
          <div
            className={classNames(
              isMenuOpen && 'visible opacity-100 bg-gray-2',
              'px-1 ellipsis rounded-1'
            )}
            onClick={handleOpenMenu}
            role="button"
          >
            <FontAwesomeIcon icon={faEllipsis} />
          </div>
        </div>
      </OutsideClickHandler>
    </div>
  );
};

const ComponentFolder = ({
  children,
  folderName,
  handleUpdateComponent,
  isNewFolder = false,
  setNewFolder = null,
}) => {
  const [isEditingFolderName, setEditingFolderName] = useState(false);
  const [folderNameInput, setFolderNameInput] = useState(folderName);
  const [isMenuOpen, setMenuOpen] = useState(false);

  const componentList = useSelector((state) => state.dataLists.componentList);
  const folderList = useSelector((state) => state.dataLists.folderList);

  const dispatch = useDispatch();

  const handleChangeFolderName = (deleteFolder = false) => {
    if (folderName !== folderNameInput || deleteFolder) {
      const updatedComponents = [...componentList];
      const updatedFolders = [...folderList];

      if (!isNewFolder) {
        updatedComponents.forEach((component, index) => {
          if (component.details.folder === folderName) {
            const obj = {
              ...component,
              details: {
                ...component.details,
                folder: deleteFolder ? null : folderNameInput,
              },
            };

            updatedComponents[index] = obj;

            handleUpdateComponent(
              updatedComponents[index].id,
              updatedComponents[index]
            );
          }
        });

        const index = updatedFolders.findIndex(
          (folder) => folder === folderName
        );

        dispatch(setComponentList([...updatedComponents]));

        if (deleteFolder) updatedFolders.splice(index, 1);
        else updatedFolders[index] = folderNameInput;
      } else {
        if (!deleteFolder) updatedFolders.push(folderNameInput);
        setNewFolder(null);
      }

      dispatch(setFolderList([...updatedFolders]));

      console.log('Done');
    }
  };

  const handleOpenMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const handleCloseMenu = () => {
    setMenuOpen(false);
  };

  const handleEditFolderName = () => {
    setEditingFolderName(true);
    setMenuOpen(false);
  };

  const handleDeleteFolder = () => {
    console.log('Starting delete...');
    handleChangeFolderName(true);
    setFolderNameInput(null);
  };

  return (
    <div key={folderName} className="mb-1">
      <Droppable key={folderName} id={folderName}>
        {!isEditingFolderName ? (
          <div className="d-flex justify-content-between align-items-center library-folder">
            <div className="d-flex gap-1">
              <FontAwesomeIcon
                icon={faFolderClosed}
                className="text-gray-5"
                style={{ margin: '2px 0' }}
              />
              <div className="fw-bold">{folderName}</div>
            </div>
            <OutsideClickHandler
              onOutsideClick={handleCloseMenu}
              disabled={!isMenuOpen}
            >
              <div className="position-relative">
                <div
                  className={classNames(
                    isMenuOpen ? 'visible opacity-100' : 'invisible opacity-0',
                    'mini-menu-container bg-white px-2 py-2 border border-gray-3'
                  )}
                >
                  <div
                    className={classNames(
                      // !checksheetFound && 'pe-none opacity-50',
                      'mini-menu-option text-nowrap px-1 mb-1 rounded-1'
                    )}
                    onClick={handleEditFolderName}
                    role="button"
                  >
                    Rename folder
                  </div>
                  <div
                    className={classNames(
                      // !checksheetFound && 'pe-none opacity-50',
                      'mini-menu-option text-nowrap px-1 rounded-1'
                    )}
                    onClick={handleDeleteFolder}
                    role="button"
                  >
                    Delete folder
                  </div>
                </div>
                <div
                  className="folder-ellipsis px-1"
                  onClick={handleOpenMenu}
                  role="button"
                >
                  <FontAwesomeIcon icon={faEllipsis} />
                </div>
              </div>
            </OutsideClickHandler>
          </div>
        ) : (
          <div className="p-1 bg-gray-2 mb-1">
            <input
              type="text"
              value={folderNameInput}
              onChange={(e) => setFolderNameInput(e.target.value)}
              className="w-100 mb-1"
            />
            <div className="d-flex align-items-center gap-2">
              <Button
                label="Save"
                color="gold"
                size="xsmall"
                onClick={handleChangeFolderName}
              />
              <Button
                label="Cancel"
                color="gray"
                size="xsmall"
                onClick={() => setEditingFolderName(false)}
              />
            </div>
          </div>
        )}
        {children}
      </Droppable>
    </div>
  );
};

const TemplateSidebar = () => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [activeId, setActiveId] = useState(null);
  const [activeComponentName, setActiveComponentName] = useState(null);
  const [newFolder, setNewFolder] = useState(null);
  const [searchString, setSearchString] = useState('');
  const [disableDragging, setDisableDragging] = useState(false);
  const [showComponentForm, setShowComponentForm] = useState(false);

  const folderList = useSelector((state) => state.dataLists.folderList);
  const componentList = useSelector((state) => state.dataLists.componentList);
  const isComponentListLoading = useSelector(
    (state) => state.dataLists.isComponentListLoading
  );
  const year = useSelector((state) => state.settings.year);

  const handleCloseComponentForm = () => setShowComponentForm(false);

  const sortByProperty = (a, b, property) => {
    if (a.details[property] > b.details[property]) return 1;

    if (a.details[property] < b.details[property]) return -1;

    return 0;
  };

  const sortByFolderName = (a, b) => {
    if (a > b) return 1;

    if (a < b) return -1;

    return 0;
  };

  // Get existing component after updating and update template
  const { mutate: getUpdatedComponent } = useMutation({
    mutationFn: componentGet,
  });

  // Update existing component in database
  const { mutate: updateComponent } = useMutation({
    mutationFn: componentUpdate,
    onSuccess: async (data, { id, token }) => {
      getUpdatedComponent({ id, token });
    },
  });

  const handleUpdateComponent = async (id, component) => {
    const token = await getAccessToken();
    await updateComponent({
      id: id,
      jsonData: { ...component.details },
      token: token,
    });
  };

  const handleCreateNewComponent = () => {
    dispatch(setCurrentComponent(''));
    setShowComponentForm(true);
  };

  const handleSearchComponents = (e) => {
    setSearchString(e.target.value);
  };

  const handleToggleDragging = (drag = null) => {
    setDisableDragging(drag !== null ? drag : !disableDragging);
  };

  const handleNewFolder = () => {
    const findUniqueFolder = (folderName, index = 0) => {
      let uniqueFolder = folderName;

      if (index > 0) uniqueFolder += index;

      if (newFolder !== uniqueFolder && !folderList.includes(uniqueFolder)) {
        return uniqueFolder;
      }

      return findUniqueFolder(folderName, index + 1);
    };

    if (newFolder) {
      const updatedFolders = [...folderList];
      updatedFolders.push(newFolder);
      dispatch(setFolderList([...updatedFolders]));
    }

    const newFolderName = findUniqueFolder('newFolder');

    setNewFolder(newFolderName);
  };

  function handleDragStart(event) {
    const activeComponent = componentList.find(
      (component) => component.id === event.active.id
    );

    console.log(activeComponent);
    setActiveComponentName(activeComponent.details.componentName);
    setActiveId(event.active.id);
  }

  function handleDragEnd(event) {
    const { over } = event;
    const components = [...componentList];
    const index = components.findIndex(
      (component) => component.id === activeId
    );

    const obj = {
      ...components[index],
      details: {
        ...components[index].details,
        folder: over ? over.id : null,
      },
    };

    if (components[index].details.folder !== obj.details.folder) {
      components[index] = obj;

      handleUpdateComponent(activeId, components[index]);
      dispatch(setComponentList([...components]));

      if (newFolder && over.id === newFolder) {
        const updatedFolders = [...folderList];
        updatedFolders.push(newFolder);
        dispatch(setFolderList([...updatedFolders]));
        setNewFolder(null);
      }
    }

    setActiveId(null);
  }

  const {
    data: componentData,
    isSuccess,
    isPending,
  } = useQuery({
    queryKey: ['components', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return componentGetAll({ token });
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const next = [];

      componentData.forEach((component) => {
        if (component['details']) {
          const jsonObject = JSON.parse(component['details'].S);
          const obj = {
            id: component['sk'].S,
            details: jsonObject,
            componentObj: component,
          };
          next.push(obj);
        }
      });

      const folderedComponentList = next
        .filter(
          (component) =>
            !!component.details.folder && component.details.year === year
        )
        .sort((a, b) => a.details.folder > b.details.folder);

      const folders = [
        ...new Set(
          folderedComponentList.map((component) => component.details.folder)
        ),
      ];

      dispatch(setFolderList([...folders]));
      dispatch(setComponentList(next));
    }
  }, [componentData, dispatch, isSuccess, year]);

  useEffect(() => {
    dispatch(setComponentListLoading(isPending));
  }, [dispatch, isPending]);

  return (
    <>
      <div
        className="sidebar-menu position-fixed d-flex flex-column border-end border-bottom border-gray-4 bg-gray-1"
        style={{ width: '20%' }}
      >
        <TabPanel tabs={['Edit', 'Preview']} showExtraBorder={false}>
          <div className="d-flex flex-column gap-1 px-2 py-1 mt-2 h-100 overflow-y-hidden">
            <div className="d-flex justify-content-between align-items-center px-1 mb-1">
              <div className="fw-bold">Section Library</div>
              <div className="d-flex gap-2 align-items-center">
                <div
                  onClick={handleNewFolder}
                  className="text-gray-6"
                  title="New folder"
                  role="button"
                >
                  <FontAwesomeIcon icon={faFolderClosed} />
                </div>
                <div
                  onClick={handleCreateNewComponent}
                  className="text-gray-6"
                  title="New section"
                  role="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                </div>
              </div>
            </div>
            <div className="px-1">
              <div className="position-relative">
                <input
                  type="text"
                  className="w-100 rounded-pill border border-gray-3 bg-gray-1"
                  style={{ padding: '0.4rem 2.5rem 0.4rem 1.5rem' }}
                  placeholder="Search sections"
                  onChange={(e) => handleSearchComponents(e)}
                />
                <FontAwesomeIcon
                  icon={faMagnifyingGlass}
                  className="position-absolute top-0 end-0 text-gray-5"
                  style={{ margin: '0.7rem 1rem' }}
                />
              </div>
            </div>
            {isComponentListLoading ? (
              <div className="d-flex flex-column gap-2 mx-1 mt-1">
                {[...Array(5)].map((item, index) => (
                  <LoadingSkeleton key={index} width={'100%'} height={'20px'} />
                ))}
              </div>
            ) : (
              <div className="ms-1 mt-1 scrollbox">
                <div className="scrollbox-content">
                  {componentList.length ? (
                    <DndContext
                      onDragStart={handleDragStart}
                      onDragEnd={handleDragEnd}
                      // modifiers={[restrictToVerticalAxis]}
                    >
                      <DragOverlay
                        style={{
                          width: 'fit-content',
                          marginLeft: '-8px',
                        }}
                      >
                        {activeId ? (
                          <div className="px-1 me-4 d-flex bg-gray-3 rounded">
                            {activeComponentName}
                          </div>
                        ) : null}
                      </DragOverlay>
                      {!!newFolder && (
                        <ComponentFolder
                          folderName={newFolder}
                          handleUpdateComponent={handleUpdateComponent}
                          isNewFolder={true}
                          setNewFolder={setNewFolder}
                        />
                      )}
                      {folderList
                        .toSorted((a, b) => sortByFolderName(a, b))
                        .map((folderName) => (
                          <Fragment key={folderName}>
                            <ComponentFolder
                              folderName={folderName}
                              handleUpdateComponent={handleUpdateComponent}
                            >
                              <div className="mt-1">
                                {componentList
                                  .filter(
                                    (component) =>
                                      component.details.folder === folderName &&
                                      component.details.componentName.includes(
                                        searchString
                                      ) &&
                                      component.details.year === year
                                  )
                                  .toSorted((a, b) =>
                                    sortByProperty(a, b, 'componentName')
                                  )
                                  .map((component) => (
                                    <ComponentRow
                                      key={component.id}
                                      component={component}
                                      activeId={activeId}
                                      disabled={disableDragging}
                                      setDisabled={handleToggleDragging}
                                      foldered={true}
                                    />
                                  ))}
                              </div>
                            </ComponentFolder>
                          </Fragment>
                        ))}
                      {componentList
                        .filter(
                          (component) =>
                            !component.details.folder &&
                            component.details.componentName.includes(
                              searchString
                            ) &&
                            component.details.year === year
                        )
                        .toSorted((a, b) =>
                          sortByProperty(a, b, 'componentName')
                        )
                        .map((component) => (
                          <ComponentRow
                            key={component.id}
                            component={component}
                            activeId={activeId}
                            disabled={disableDragging}
                            setDisabled={handleToggleDragging}
                          />
                        ))}
                    </DndContext>
                  ) : (
                    <div className="fst-italic">
                      No components found in library.
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
          <div className="d-flex flex-column gap-1 px-2 py-1 mt-2 h-100 overflow-y-hidden">
            {/* <PreviewChecksheets /> */}
            <div>Checksheet previews are not available at this time.</div>
          </div>
        </TabPanel>
      </div>
      <Modal show={showComponentForm} onHide={handleCloseComponentForm}>
        <ComponentForm handleClose={handleCloseComponentForm} />
      </Modal>
    </>
  );
};

export default TemplateSidebar;
