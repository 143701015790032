import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  Fragment,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import Course from './RequirementCourse';
import GeneralStudy from './RequirementGeneralStudy';
import Elective from './RequirementElective';
import Text from './RequirementText';
import { Button } from '@asu/components-core/dist/libCore.es';
import CourseListForm from './CourseListForm';
import Check from './RequirementCheck';
// import { useSelector } from 'react-redux';
// import { useDispatch } from 'react-redux';

const MultipleCourseList = forwardRef(
  (
    {
      year,
      dataFromParent,
      closeEditModal,
      newRequirement = false,
      courses,
      setCourses,
    },
    ref
  ) => {
    const [formData, setFormData] = useState([]);
    const formDataRef = useRef(formData);
    const [selectedComponents, setSelectedComponents] = useState([]);
    const [hours, setHours] = useState();
    const [maxHours, setMaxHours] = useState();
    const [requirementId, setRequirementId] = useState('');
    const [showAsMultiple, setShowAsMultiple] = useState(true);
    const [showAsCourseList, setShowAsCourseList] = useState(false);

    const handleShowAsModal = () => {
      setShowAsMultiple(true);
      setShowAsCourseList(false);
    };

    // handle the user check AND/ Or, open course requirement modal by default
    const handleSelectChange = useCallback(
      (selectedValue, selectedConnector) => {
        if (selectedValue) {
          setSelectedComponents([...selectedComponents, selectedValue]);
          setFormData(
            formData
              ? [
                  ...formData,
                  {
                    data: {
                      itemType: selectedValue,
                      ...(selectedConnector && {
                        connector: selectedConnector,
                      }),
                    },
                  },
                ]
              : [
                  {
                    data: {
                      itemType: selectedValue,
                      ...(selectedConnector && {
                        connector: selectedConnector,
                      }),
                    },
                  },
                ]
          );
        }
      },
      [formData, selectedComponents]
    );

    const handleInputChange = (index, field, value) => {
      setFormData((prevFormData) => {
        const newFormData = [...prevFormData];
        const updatedObject = { ...newFormData[index] };
        const updatedData = { ...updatedObject.data };
        updatedData[field] = value;
        updatedObject.data = updatedData;
        newFormData[index] = updatedObject;
        return newFormData;
      });
    };

    const handleDeleteComponent = (index) => {
      setSelectedComponents(selectedComponents.filter((_, i) => i !== index));
      setFormData(formData.filter((_, i) => i !== index));
    };

    const handleAND = () => {
      handleSelectChange('req_course', 'AND');
    };

    const handleOr = () => {
      handleSelectChange('req_course', 'or');
    };

    const handleSaveCourses = async () => {
      let updatedCourse = formData.map((item) => item.data);

      updatedCourse = updatedCourse.map((course) => {
        if (
          course.itemType === 'req_check' &&
          course.checkSubtype === 'courses'
        ) {
          return {
            ...course,
            checkCourses: course.checkCourses.filter(
              (checkCourse) => checkCourse.subject !== ''
            ),
          };
        }
        return course;
      });
      // check if the first course is empty, if empty, not save the empty requirement, if not, save the requirement
      if (
        updatedCourse &&
        updatedCourse.length > 0 &&
        (updatedCourse[0].subject ||
          updatedCourse[0].gs ||
          updatedCourse[0].customText ||
          updatedCourse[0].group ||
          updatedCourse[0].itemType === 'req_elective')
      ) {
        const newCourseData = {
          ...dataFromParent.details,
          requirementId: requirementId,
          courses: updatedCourse,
          hours: hours ?? 0,
          maxHours: maxHours,
          itemType: 'req_multiple',
        };

        const next = [...courses];

        if (newRequirement) next.push(newCourseData);
        else
          for (let i = 0; i < next.length; i++)
            if (next[i].requirementId === requirementId)
              next[i] = newCourseData;

        setCourses([...next]);
      }

      closeEditModal(true);
    };

    const renderComponent = (component, index) => {
      switch (component) {
        case 'req_course':
          return (
            <div key={`course-${index}`}>
              <Course
                index={index}
                year={year}
                data={formData[index].data}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleDeleteComponent}
                showCourseListOption={false}
                isCourseListItem={true}
              />
            </div>
          );
        case 'req_gs':
          return (
            <div key={`gs-${index}`}>
              <GeneralStudy
                index={index}
                data={formData[index].data}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleDeleteComponent}
                showCourseListOption={false}
                isCourseListItem={true}
              />
            </div>
          );
        case 'req_elective':
          return (
            <div key={`elective-${index}`}>
              <Elective
                index={index}
                data={formData[index].data}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleDeleteComponent}
                showCourseListOption={false}
                isCourseListItem={true}
              />
            </div>
          );
        case 'req_text':
          return (
            <div key={`text-${index}`}>
              <Text
                index={index}
                data={formData[index].data}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleDeleteComponent}
                showCourseListOption={false}
                isCourseListItem={true}
              />
            </div>
          );
        case 'req_check':
          return (
            <div key={`text-${index}`}>
              <Check
                index={index}
                data={formData[index].data}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleDeleteComponent}
                showCourseListOption={false}
                isCourseListItem={true}
              />
            </div>
          );
        default:
          return null;
      }
    };

    useEffect(() => {
      formDataRef.current = formData;
    }, [formData]);

    // Handle prop updates directly
    useEffect(() => {
      console.log('dataFromParent:::::: ', dataFromParent);

      const initialData = {
        courses: dataFromParent.details?.courses ?? [],
        hours: dataFromParent.details?.hours,
        maxHours: dataFromParent.details?.maxHours,
        requirementId: dataFromParent.details?.requirementId ?? uuidv4(),
      };

      console.log('initialData: ', initialData);

      const courseData = initialData.courses.map((item) => ({ data: item }));

      if (
        courseData.length > 0 ||
        (formDataRef.current && formDataRef.current.length > 0)
      ) {
        setFormData(courseData);
        setHours(initialData.hours);
        setMaxHours(initialData.maxHours);
        setRequirementId(initialData.requirementId);
      } else {
        setHours();
        setMaxHours();
        setRequirementId(initialData.requirementId);
      }

      console.log('initial form data: ', courseData);

      // for new requirement, the default type is coure requirement
      if (courseData.length === 0) {
        setSelectedComponents(['req_course']);
        setFormData([
          {
            data: {
              itemType: 'req_course',
            },
          },
        ]);
      } else {
        setSelectedComponents(courseData.map((item) => item.data.itemType));
      }
    }, [dataFromParent]);

    // Function you want to expose to the parent (courseListComponent)
    const saveCourses = (message) => {
      console.log('form data:', formData);
      if (formData.length > 0 && formData[0]?.data?.hours >= 0) {
        // check if the first course has hours, if not, doesn't save
        handleSaveCourses();
      }
    };

    // Use useImperativeHandle to expose childFunction to the parent
    useImperativeHandle(ref, () => ({
      callChildFunction(message) {
        saveCourses(message);
      },
    }));

    return (
      <>
        {showAsMultiple ? (
          <div className="row py-2 mx-0 border border-top-0">
            <div className="d-flex justify-content-between">
              <div className="ms-4" style={{ width: '85%' }}>
                <div id="componentsContainer">
                  {selectedComponents.map((component, index) =>
                    renderComponent(component, index)
                  )}
                </div>
                <div className="d-flex gap-1 ms-5">
                  <Button
                    label="AND"
                    size="xsmall"
                    classes={[
                      'btn bg-white btn-sm border border-gray-4 text-gray-6',
                    ]}
                    icon={['fas', 'plus']}
                    onClick={handleAND}
                  />

                  <Button
                    label="or"
                    size="xsmall"
                    classes={[
                      'btn bg-white btn-sm border border-gray-4 text-gray-6',
                    ]}
                    icon={['fas', 'plus']}
                    onClick={handleOr}
                  />
                </div>
              </div>

              {/* <div
                className="d-flex gap-1 justify-content-end py-1"
                style={{ width: '15%', height: 'min-content' }}
              >
                <Button
                  color="maroon"
                  size="small"
                  icon={['fas', 'check']}
                  onClick={handleSaveCourses}
                />

                <Button
                  size="small"
                  icon={['fas', 'xmark']}
                  classes={[
                    'btn bg-white btn-md border border-gray-4 text-gray-6',
                  ]}
                  onClick={() => closeEditModal(true)}
                />
              </div> */}
            </div>
          </div>
        ) : showAsCourseList ? (
          <CourseListForm
            // courseListId={courseListId}
            handleClose={handleShowAsModal}
          />
        ) : (
          <div></div>
        )}
      </>
    );
  }
);

export default MultipleCourseList;
