import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  checksheetId: '',
  historyRequirements: [],

  requirements: {}, // requirements history (add/modify)
  subsections: {}, // subsections history (add/modify)
  courseLists: {}, // courseLists history (add/modify)
  deleted: {}, // requirements history (delete)
  deletedCourseLists: {}, // courseLists history
  updatedHistory: [], // updated history progress
};

export const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    setHistoryAll: (state, action) => {
      state.checksheetId = action.payload.checksheetId;
      state.requirements = action.payload.requirements;
      state.subsections = action.payload.subsections;
      state.courseLists = action.payload.courseLists;
      state.deleted = action.payload.deleted;
      state.deletedCourseLists = action.payload.deletedCourseLists;
      state.updatedHistory = action.payload.updatedHistory;
      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHistoryAll } = historySlice.actions;

export default historySlice.reducer;
