import axios from 'axios';

export async function checksheetValidationGet({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/validation/checksheet/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .get(api, headers)
    .then((response) => {
      // console.log('ChecksheetValidationData: ', response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('get Error response status:', error.response.status);
      console.log('get Error response data:', error.response.data);
    });

  return res;
}
