import React from 'react';
import CourseListForm from 'components/CourseListForm';

const CourseList = () => {
  return (
    <div>
      <br />
      <CourseListForm />
      <br />
    </div>
  );
};

export default CourseList;
