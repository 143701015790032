import React from 'react';
import { classNames } from 'utils';

const LoadingSkeleton = ({ width, height, variant = null }) => {
  const style = {
    width,
    height,
  };

  return <div className={classNames(variant, 'skeleton')} style={style}></div>;
};

export default LoadingSkeleton;
