import { createSlice } from '@reduxjs/toolkit';

export const currentSelectionSlice = createSlice({
  name: 'currentSelection',
  initialState: {
    currentChecksheet: {},
    currentTextRequirement: {},
  },
  reducers: {
    setCurrentChecksheet: (state, action) => {
      state.currentChecksheet = action.payload;
    },
    setCurrentTextRequirement: (state, action) => {
      state.currentTextRequirement = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setCurrentChecksheet, setCurrentTextRequirement } =
  currentSelectionSlice.actions;

export default currentSelectionSlice.reducer;
