import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faXmark } from '@fortawesome/free-solid-svg-icons';

const StatusDefinitions = ({ showNoChecksheet = false, handleClose }) => {
  return (
    <>
      <div className="p-6" style={{ width: '796px' }}>
        <div className="d-flex justify-content-between align-items-center mb-6">
          <div className="fs-1 fw-bold">Status Definitions</div>
          <div
            className="border border-gray-3 rounded-circle lh-1"
            style={{ padding: '7px 10px' }}
            onClick={handleClose}
            role="button"
          >
            <FontAwesomeIcon
              icon={faXmark}
              width={'10px'}
              height={'10px'}
              className="text-gray-7"
            />
          </div>
        </div>
        {showNoChecksheet && (
          <div className="border-bottom border-gray-3 pb-3 mb-3">
            <div className="fs-5 fw-bold mb-2">No checksheet exists</div>
            <div>
              A checksheet has not been created for this program yet. A
              checksheet contains a list of requirements needed to graduate with
              the specified degree.
            </div>
          </div>
        )}
        <div className="border-bottom border-gray-3 pb-3 mb-3">
          <div className="d-flex gap-1 align-items-center fs-5 fw-bold mb-2">
            <FontAwesomeIcon
              icon={faCircle}
              width={'16px'}
              className="text-gray-4"
            />
            <div>Pending department submission</div>
          </div>
          <div>Some colleges/schools utilize department-level reviewers.</div>
        </div>
        <div className="border-bottom border-gray-3 pb-3 mb-3">
          <div className="d-flex gap-1 align-items-center fs-5 fw-bold mb-2">
            <FontAwesomeIcon
              icon={faCircle}
              width={'16px'}
              className="text-gray-7"
            />
            <div>Pending college/school submission</div>
          </div>
          <div>
            <div>
              Action is required from a college/school to submit for Provost
              review.
            </div>
            <br />
            <div>
              If the department level is used, this status also indicates
              programs submitted and awaiting college review.
            </div>
          </div>
        </div>
        <div className="border-bottom border-gray-3 pb-3 mb-3">
          <div className="d-flex gap-1 align-items-center fs-5 fw-bold mb-2">
            <FontAwesomeIcon
              icon={faCircle}
              width={'16px'}
              className="text-warning"
            />
            <div>Pending Provost review</div>
          </div>
          <div>Programs submitted by college/school to Provost review.</div>
        </div>
        <div className="border-bottom border-gray-3 pb-3 mb-3">
          <div className="d-flex gap-1 align-items-center fs-5 fw-bold mb-2">
            <FontAwesomeIcon
              icon={faCircle}
              width={'16px'}
              className="text-info"
            />
            <div>Pending DARS encoding</div>
          </div>
          <div>
            Programs approved by Provost Office and ready for DARS
            implementation. Each college/school has a designated DARS encoder.
          </div>
        </div>
        <div>
          <div className="d-flex gap-1 align-items-center fs-5 fw-bold mb-2">
            <FontAwesomeIcon
              icon={faCircle}
              width={'16px'}
              className="text-success"
            />
            <div>Completed</div>
          </div>
          <div>
            The program has officially completed the review process. No further
            action is required.
          </div>
        </div>
      </div>
    </>
  );
};

export default StatusDefinitions;
