import React, {
  useState,
  useEffect,
  useRef,
  Fragment,
  useCallback,
  // useCallback,
} from 'react';
import { v4 as uuidv4 } from 'uuid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faList,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import StaticData from './StaticData';
import Course from './RequirementCourse';
import GeneralStudy from './RequirementGeneralStudy';
import CourseList from './RequirementCourseList';
import Elective from './RequirementElective';
import Text from './RequirementText';
import Check from './RequirementCheck';
import { Button } from '@asu/components-core/dist/libCore.es';
import CourseListForm from './CourseListForm';
import { useDispatch, useSelector } from 'react-redux';
import { setCourses, setCourseListId } from 'state/slices/courseListSlice';
import { classNames } from 'utils';
import CourseListDetails from './CourseListDetails';
import { useMutation } from '@tanstack/react-query';
import { componentGet, componentUpdate } from 'apis/componentAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import {
  moveRequirement,
  removeRequirement,
} from 'state/slices/componentSlice';
import { editTemplateComponent } from 'state/slices/templateSlice';
import { checksheetUpdate } from 'apis/checksheetAPIs';
import { setChecksheetRequirementList } from 'state/slices/checksheetSlice';

const Multiple = ({
  componentId,
  year,
  componentName,
  dataFromParent,
  dataToParent,
  createWrappedData,
  exitRequirement,
  fromChecksheet = false,
  readOnly = false,
  isLocked = false,
  holdsLock = false,
  // fromCourseList = false,
}) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [selectedComponents, setSelectedComponents] = useState([]);
  const [formData, setFormData] = useState([]);
  const formDataRef = useRef(formData);
  const [hours, setHours] = useState(dataFromParent?.details?.hours ?? 0);
  const [maxHours, setMaxHours] = useState(
    dataFromParent?.details?.maxHours ?? 0
  );
  const [grade, setGrade] = useState(dataFromParent?.details?.grade ?? '');
  const [notes, setNotes] = useState(dataFromParent?.details?.notes ?? '');
  const [subsectionId] = useState(dataFromParent?.subsectionId ?? '');
  const [requirementId, setRequirementId] = useState(
    dataFromParent?.details?.requirementId ?? ''
  );

  const [isConnectorVisible, setIsConnectorVisible] = useState(false);
  const [isSelectComponentVisible, setIsSelectComponentVisible] =
    useState(false);
  const [selectedConnector, setSelectedConnector] = useState();
  const [showMaxHours, setShowMaxHours] = useState(
    dataFromParent?.details?.maxHours ? true : false
  );
  const [showAsMultiple, setShowAsMultiple] = useState(true);
  const [showAsCourseList, setShowAsCourseList] = useState(false);

  const courseLists = useSelector((state) => state.checksheet.courseLists);
  const courseListId = useSelector((state) => state.courseList.courseListId);

  const [selectedComponentId, setSelectedComponentId] = useState(componentId);
  const [selectedSubsectionId, setSelectedSubsectionId] = useState(
    dataFromParent?.subsectionId ?? ''
  );

  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );
  const componentSubsections = useSelector(
    (state) => state.component.subsections
  );

  const checksheetState = useSelector((state) => state.checksheet);
  const checksheetRequirementList = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );

  const { mutate: getUpdatedComponent } = useMutation({
    mutationFn: componentGet,
    onSuccess: async (data) => {
      dispatch(editTemplateComponent({ id: data['sk'], object: data }));
    },
  });

  const {
    mutate: updateComponent,
    error: updateComponentError,
    isError: updateComponentIsError,
  } = useMutation({
    mutationFn: componentUpdate,
    onSuccess: async (data, { id, token }) => {
      getUpdatedComponent({ id, token });
    },
  });

  const {
    mutate: updateChecksheet,
    error: updateChecksheetError,
    isError: updateChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetUpdate,
    // onSuccess: async (data, { id, token }) => {
    //   getUpdatedComponent({ id, token });
    // },
  });

  const moveRequirementToNewSection = async (newCourseData) => {
    try {
      if (fromChecksheet) {
        // Get the previous and next sections for the requirement as well as the subsections
        const oldSection = {
          ...checksheetRequirementList[componentId],
        };
        const newSection = {
          ...checksheetRequirementList[selectedComponentId],
        };
        const updatedOldSubsection = {
          ...oldSection.subsections[subsectionId],
        };
        let updatedNewSubsection = {};

        // If the next section does not have existing checksheet data, create blank data
        if (
          !!newSection?.subsections &&
          !!Object.keys(newSection.subsections)?.length
        )
          updatedNewSubsection = {
            ...newSection.subsections[selectedSubsectionId],
          };

        // If the next subsection does not have existing checksheet data, create blank data
        if (!Object.keys(updatedNewSubsection).length) {
          updatedNewSubsection = {
            requirements: [],
            hours: 0,
            upperDivisionHours: 0,
            subsectionName: '',
          };
        }

        // Get the list of requirements and the original index of the requirement
        const updatedOldRequirements = [...updatedOldSubsection.requirements];
        const updatedNewRequirements = [...updatedNewSubsection.requirements];
        const requirementIndex = updatedOldRequirements.findIndex(
          (req) => req.requirementId === requirementId
        );

        // Remove the requirement from the previous subsection and push it to the next one
        updatedOldRequirements.splice(requirementIndex, 1);
        updatedNewRequirements.push(newCourseData);

        // Create a section list object
        const updatedRequirements = {
          ...checksheetRequirementList,
          [componentId]: {
            ...oldSection,
            subsections: {
              ...oldSection.subsections,
              [subsectionId]: {
                ...updatedOldSubsection,
                requirements: updatedOldRequirements,
              },
            },
          },
          [selectedComponentId]: {
            ...newSection,
            subsections: {
              ...newSection.subsections,
              [selectedSubsectionId]: {
                ...updatedNewSubsection,
                requirements: updatedNewRequirements,
              },
            },
          },
        };

        // Update the JSON
        const jsonData = {
          year: checksheetState.year.value,
          templateId: checksheetState.templateId,
          checksheetName: checksheetState.checksheetName,
          programType: checksheetState.programType,
          acadplan: checksheetState.acadplan,
          acadsubplan: checksheetState.acadsubplan,
          college: checksheetState.college,
          department: checksheetState.department,
          courseLists: checksheetState.courseLists,
          componentRequirements: updatedRequirements,
        };

        // Update the checksheet with this new data
        const token = await getAccessToken();
        await updateChecksheet({
          id: checksheetState.checksheetId,
          jsonData,
          token,
        });
        dispatch(setChecksheetRequirementList(updatedRequirements));
      } else {
        // Get the next section and subsection
        const newSection = JSON.parse(
          templateComponentObjects[selectedComponentId].details
        );
        let updatedSubsection = {};

        // If the next section does not have existing section data, create blank data
        if (!!Object.keys(newSection.subsections)?.length)
          updatedSubsection = {
            ...newSection.subsections[selectedSubsectionId],
          };

        // If the next subsection does not have existing section data, create blank data
        if (!Object.keys(updatedSubsection).length) {
          console.log('No subsection data');
          updatedSubsection = {
            requirements: [],
            hours: 0,
            upperDivisionHours: 0,
            subsectionName: '',
          };
        }

        // Push the requirement to the next subsection
        updatedSubsection.requirements.push(newCourseData);

        // Update the JSON
        const jsonData = {
          ...newSection,
          subsections: {
            ...newSection.subsections,
            [selectedSubsectionId]: updatedSubsection,
          },
        };

        // Update the section with the new data
        const token = await getAccessToken();
        await updateComponent({
          id: selectedComponentId,
          jsonData,
          token,
        });

        // Remove the requirement from the previous section's state, but don't save it
        // to the database yet
        dispatch(
          removeRequirement({
            subsectionId,
            requirementId,
          })
        );
      }
    } catch (e) {
      console.log('Error moving requirement');
      console.error(e);
      if (updateComponentIsError) console.error(updateComponentError.message);
      if (updateChecksheetIsError) console.error(updateChecksheetError.message);
    }
  };

  const handleSelectComponent = (e) => {
    const { value } = e.target;

    // const subsectionList = JSON.parse(
    //   templateComponentObjects[value].details
    // ).subsections;
    // const currentSubsection = Object.keys(subsectionList);
    setSelectedComponentId(value);
    setSelectedSubsectionId(value);
  };

  const handleShowAsModal = () => {
    setShowAsMultiple(true);
    setShowAsCourseList(false);
  };

  const handleShowAsCourseList = (createNew) => {
    if (createNew) {
      dispatch(setCourseListId(null));
      dispatch(setCourses([]));
    }
    setShowAsMultiple(false);
    setShowAsCourseList(true);
  };

  const handleCheckboxChange = (event) => {
    setShowMaxHours(event.target.checked ? true : false);
    setMaxHours(event.target.checked ? maxHours : 0);
  };

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value;

    if (selectedValue) {
      setSelectedComponents([...selectedComponents, selectedValue]);
      if (formData)
        setFormData([
          ...formData,
          { data: { itemType: selectedValue, connector: selectedConnector } },
        ]);
      else
        setFormData([
          { data: { itemType: selectedValue, connector: selectedConnector } },
        ]);
    }

    setIsConnectorVisible(true);
    setIsSelectComponentVisible(false);
  };

  const handleInputChange = useCallback((index, field, value) => {
    setFormData((prevFormData) => {
      const newFormData = [...prevFormData];
      const updatedObject = { ...newFormData[index] };
      const updatedData = { ...updatedObject.data };
      updatedData[field] = value;
      updatedObject.data = updatedData;
      newFormData[index] = updatedObject;
      return newFormData;
    });
  }, []);

  const handleDeleteComponent = (index) => {
    setSelectedComponents(selectedComponents.filter((_, i) => i !== index));
    setFormData(formData.filter((_, i) => i !== index));
  };

  const cancelData = () => {
    // if (!fromChecksheet) dataToParent(null);

    exitRequirement();
  };

  const handleAND = () => {
    console.log('Subsection ID: ', subsectionId);
    setSelectedConnector('AND');
    setIsSelectComponentVisible(true);
  };
  const handleOR = () => {
    setSelectedConnector('OR');
    setIsSelectComponentVisible(true);
  };

  const handleOr = () => {
    setSelectedConnector('or');
    setIsSelectComponentVisible(true);
  };

  const handleDeleteRequirement = async () => {
    const confirmDeletion = window.confirm(
      'Are you sure you want to delete this data?'
    );
    if (confirmDeletion) {
      dataToParent(
        createWrappedData(
          dataFromParent.index,
          'req_multiple',
          {},
          componentId,
          subsectionId
        )
      );
      exitRequirement();
    }
  };

  const handleSaveCourses = async () => {
    let courses = formData.map((item) => item.data);

    courses = courses.map((course) => {
      if (
        course.itemType === 'req_check' &&
        course.checkSubtype === 'courses'
      ) {
        return {
          ...course,
          checkCourses: course.checkCourses.filter(
            (checkCourse) => checkCourse.subject !== ''
          ),
        };
      }

      return course;
    });

    const newCourseData = {
      ...dataFromParent.details,
      requirementId: !!requirementId ? requirementId : uuidv4(),
      courses: courses,
      hours: hours ?? 0,
      maxHours: maxHours,
      grade: grade,
      notes: notes,
      itemType: 'req_multiple',
    };

    if (selectedComponentId !== componentId) {
      // If a requirement is being moved between two sections
      moveRequirementToNewSection(newCourseData);
    } else if (selectedSubsectionId !== subsectionId) {
      if (fromChecksheet) {
        // If editing a checksheet and a requirement is being moved between subsections
        // within one section

        // Get the section as well as the next and previous subsections
        const updatedSection = {
          ...checksheetRequirementList[componentId],
        };
        const updatedOldSubsection = {
          ...updatedSection.subsections[subsectionId],
        };
        let updatedNewSubsection = {
          ...updatedSection.subsections[selectedSubsectionId],
        };

        // If the next subsection does not exist in the checksheet, create a blank one
        if (!Object.keys(updatedNewSubsection).length) {
          updatedNewSubsection = {
            requirements: [],
            hours: 0,
            upperDivisionHours: 0,
            subsectionName: '',
          };
        }

        // Get the list of requirements and the original index of the requirement
        const updatedOldRequirements = [...updatedOldSubsection.requirements];
        const updatedNewRequirements = [...updatedNewSubsection.requirements];
        const requirementIndex = updatedOldRequirements.findIndex(
          (req) => req.requirementId === requirementId
        );

        // Remove the requirement from the previous subsection and push it to the next one
        updatedOldRequirements.splice(requirementIndex, 1);
        updatedNewRequirements.push(newCourseData);

        // Create a section list object
        const updatedRequirements = {
          ...checksheetRequirementList,
          [componentId]: {
            ...updatedSection,
            subsections: {
              ...updatedSection.subsections,
              [subsectionId]: {
                ...updatedOldSubsection,
                requirements: updatedOldRequirements,
              },
              [selectedSubsectionId]: {
                ...updatedNewSubsection,
                requirements: updatedNewRequirements,
              },
            },
          },
        };

        // Update the JSON
        const jsonData = {
          year: checksheetState.year.value,
          templateId: checksheetState.templateId,
          checksheetName: checksheetState.checksheetName,
          programType: checksheetState.programType,
          acadplan: checksheetState.acadplan,
          acadsubplan: checksheetState.acadsubplan,
          college: checksheetState.college,
          department: checksheetState.department,
          courseLists: checksheetState.courseLists,
          componentRequirements: updatedRequirements,
        };

        // Update the checksheet with this new data
        const token = await getAccessToken();
        await updateChecksheet({
          id: checksheetState.checksheetId,
          jsonData,
          token,
        });

        dispatch(setChecksheetRequirementList(updatedRequirements));
      } else {
        // If editing a section and a requirement is being moved between subsections
        // within that section
        dispatch(
          moveRequirement({
            oldSubsectionId: subsectionId,
            newSubsectionId: selectedSubsectionId,
            requirement: newCourseData,
          })
        );
      }
      // dataToParent(
      //   createWrappedData(
      //     9999,
      //     'req_multiple',
      //     newCourseData,
      //     componentId,
      //     selectedSubsectionId,
      //     dataFromParent.index,
      //     subsectionId
      //   )
      // );
    } else {
      // If editing a requirement and it is not moved to a new section or subsection
      dataToParent(
        createWrappedData(
          dataFromParent.index,
          'req_multiple',
          newCourseData,
          componentId,
          subsectionId
        )
      );
    }

    exitRequirement();
  };

  useEffect(() => {
    formDataRef.current = formData;
    if (!hours && formData && formData.length > 0) {
      setHours(formData[0].data.hours); // Set hours once based on formData
    }

    setShowMaxHours(!!maxHours); // Set based on maxHours
  }, [formData, maxHours, hours]);

  // Handle prop updates directly
  useEffect(() => {
    console.log('dataFromParent:::::: ', dataFromParent);

    const initialData = {
      courses: dataFromParent.details?.courses ?? [],
      hours: dataFromParent.details?.hours,
      maxHours: dataFromParent.details?.maxHours,
      grade: dataFromParent.details?.grade ?? '',
      notes: dataFromParent.details?.notes ?? '',
      subsectionId: dataFromParent.subsectionId ?? '',
      requirementId: dataFromParent.details.requirementId ?? '',
    };

    console.log('initialData: ', initialData);

    const courseData = initialData.courses.map((item) => ({ data: item }));

    if (courseData && courseData.length > 0) {
      setIsConnectorVisible(true);
      setIsSelectComponentVisible(false);
    } else {
      setIsConnectorVisible(false);
      setIsSelectComponentVisible(true);
    }

    if (
      (courseData && courseData.length > 0) ||
      (formDataRef.current && formDataRef.current.length > 0)
    ) {
      console.log('set connector visible');
      setSelectedComponents(courseData.map((item) => item.data.itemType));
      setFormData(courseData);
      // setHours(initialData.hours);
      // setMaxHours(initialData.maxHours);
      // setGrade(initialData.grade);
      // setNotes(initialData.notes);
      // setSubsectionId(initialData.subsectionId);
      setRequirementId(initialData.requirementId);
    } else {
      console.log('set connector invisible');
      // setHours(0);
      // setMaxHours(0);
      // setGrade('');
      // setNotes('');
      // setSubsectionId(initialData.subsectionId);
      // setRequirementId(initialData.requirementId);
    }

    console.log('initial form data: ', courseData);
  }, [dataFromParent]);

  const renderComponent = (component, index) => {
    switch (component) {
      case 'req_course':
        return (
          <div
            key={`course-${index}`}
            className="border-bottom border-gray-4 mb-2"
          >
            <Course
              index={index}
              year={year}
              data={formData[index].data}
              componentId={componentId}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              showCourseListOption={fromChecksheet}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );
      case 'req_gs':
        return (
          <div key={`gs-${index}`} className="border-bottom border-gray-4 mb-2">
            <GeneralStudy
              index={index}
              data={formData[index].data}
              componentId={componentId}
              // dataFromParent={dataFromParent}
              // createWrappedData={createWrappedData}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              showCourseListOption={fromChecksheet}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );
      case 'req_course_list':
        return (
          <div
            key={`courselist-${index}`}
            className="border-bottom border-gray-4 mb-2"
          >
            <CourseList
              index={index}
              data={formData[index].data}
              componentId={componentId}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );

      case 'req_elective':
        return (
          <div
            key={`elective-${index}`}
            className="border-bottom border-gray-4 mb-2"
          >
            <Elective
              index={index}
              data={formData[index].data}
              componentId={componentId}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              showCourseListOption={fromChecksheet}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );
      case 'req_text':
        return (
          <div
            key={`text-${index}`}
            className="border-bottom border-gray-4 mb-2"
          >
            <Text
              index={index}
              data={formData[index].data}
              componentId={componentId}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              showCourseListOption={fromChecksheet}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );
      case 'req_check':
        return (
          <div
            key={`check-${index}`}
            className="border-bottom border-gray-4 mb-2"
          >
            <Check
              index={index}
              data={formData[index].data}
              componentId={componentId}
              handleInputChange={handleInputChange}
              handleDeleteComponent={handleDeleteComponent}
              showCourseListOption={fromChecksheet}
              handleShowAsCourseList={handleShowAsCourseList}
            />
          </div>
        );
      default:
        return null;
    }
  };

  const CourseListAccordion = ({ course }) => {
    const [showCourseList, setShowCourseList] = useState(false);

    console.log('Courses: ', courseLists[course.data.courseListId]?.courses);

    const handleShowCourseList = (e) => {
      e.stopPropagation();
      setShowCourseList(!showCourseList);
    };

    return (
      <>
        <span className="button-link" onClick={(e) => handleShowCourseList(e)}>
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classNames(showCourseList && 'rotate-180')}
            style={{ margin: '0 4px' }}
          />
          {showCourseList ? 'Hide course list' : 'Show course list'}
        </span>
        {showCourseList && (
          <CourseListDetails
            courseListName={
              courseLists[course.data.courseListId]?.courseListName
            }
            courses={courseLists[course.data.courseListId]?.courses || []}
            edit={false}
            division={course.data.division}
          />
        )}
      </>
    );
  };

  return (
    <>
      {showAsMultiple ? (
        <div style={{ width: '1224px' }}>
          <div className="p-6">
            <div className="fs-4 fw-bold pb-2">
              {!dataFromParent?.details?.courses
                ? 'New requirement'
                : !readOnly
                  ? 'Edit requirement'
                  : 'Read only requirement'}
            </div>

            <div id="componentsContainer">
              {selectedComponents.map((component, index) =>
                renderComponent(component, index)
              )}
            </div>

            {isSelectComponentVisible && (
              <div className="border-bottom border-gray-4 pb-2 mb-2">
                <div className="fw-bold">Requirement type</div>
                <div className="d-flex">
                  <select
                    id="componentSelect"
                    className="text-gray-5 p-1"
                    style={{
                      width: '250px',
                    }}
                    value={''}
                    onChange={handleSelectChange}
                  >
                    <option disabled value="">
                      Select Type
                    </option>
                    {fromChecksheet
                      ? StaticData.reqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      : StaticData.templateReqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                  </select>
                </div>
              </div>
            )}

            {isConnectorVisible && (
              <div className="mb-5">
                <Button
                  onClick={handleAND}
                  label="+ AND"
                  color="maroon"
                  size="small"
                />
                <Button
                  onClick={handleOR}
                  label="+ OR"
                  color="gold"
                  size="small"
                  style={{
                    marginLeft: '5px',
                    marginRight: '5px',
                  }}
                />
                <Button onClick={handleOr} label="+ or" size="small" />
              </div>
            )}
            <div>
              <div className="fw-bold">Credit hours</div>
              <div className="d-flex gap-1 mb-1 align-items-center">
                <div>
                  {showMaxHours && <div>Min</div>}
                  <input
                    type="number"
                    placeholder="Min"
                    className="text-gray-7 text-end"
                    min={0}
                    style={{
                      width: '100px',
                    }}
                    value={!!hours ? hours : ''}
                    onChange={(e) => setHours(+e.target.value)}
                  />
                </div>

                {showMaxHours && (
                  <>
                    <div className="text-gray-6 pt-3">{'\u2014'}</div>
                    <div>
                      <div>Max</div>
                      <input
                        type="number"
                        placeholder="Max"
                        className="text-gray-7 text-end"
                        min={hours}
                        style={{
                          width: '100px',
                        }}
                        value={!!maxHours ? maxHours : ''}
                        onChange={(e) => setMaxHours(+e.target.value)}
                      />
                    </div>
                  </>
                )}
              </div>

              <form className="uds-form">
                <div
                  className={classNames(
                    showMaxHours ? 'mb-3' : 'mb-6',
                    'form-check'
                  )}
                  style={{ paddingLeft: '18px' }}
                >
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="loneCheckbox1"
                    checked={showMaxHours}
                    onChange={handleCheckboxChange}
                  />
                  <label
                    className="form-check-label fw-normal"
                    style={{ paddingTop: '4px' }}
                    htmlFor="loneCheckbox1"
                  >
                    Set range
                  </label>
                </div>
              </form>
            </div>

            <div className="mb-3">
              <div className="fw-bold">Min grade (optional)</div>
              <div>
                <select
                  style={{
                    width: '178px',
                  }}
                  className="text-gray-5 p-1"
                  value={grade}
                  onChange={(e) => setGrade(e.target.value)}
                >
                  <option value="" disabled>
                    Min grade
                  </option>
                  {StaticData.gradeList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="mb-3">
              <div className="fw-bold">Notes (optional)</div>
              <textarea
                name="notes"
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                placeholder="Enter notes for internal use. Students will not see this."
                rows={4}
                className="p-1"
                style={{ width: '65%' }}
              />
            </div>
            <div className="mb-6">
              <div className="fw-bold">Preview</div>
              <div
                className={classNames(
                  !!formData.length
                    ? 'border border-gray-3 py-2'
                    : 'bg-gray-1 py-1',
                  'px-2'
                )}
                style={{ width: '65%' }}
              >
                {!!formData?.length ? (
                  formData.map((course, index) => (
                    <Fragment key={index}>
                      <span>
                        {course.data.connector && (
                          <>
                            {course.data.connector !== 'or' && index !== 0 && (
                              <br />
                            )}
                            <span
                              className={`${course.data.connector} fw-bold rounded-1 text-nowrap`}
                            >
                              {course.data.connector}
                            </span>
                          </>
                        )}
                        <span>
                          {course.data.itemType === 'req_check' &&
                          course.data.checkType === 'gpa' ? (
                            <>
                              <span className="fw-bold">Check</span>:{' '}
                            </>
                          ) : (
                            course.data.checkType === 'milestone' && (
                              <>
                                <span className="fw-bold">Milestone</span>:{' '}
                              </>
                            )
                          )}
                          {course.data.gpa && `Minimum ${course.data.gpa} `}
                          {course.data.checkSubtype === 'asu_cum_gpa' &&
                            'ASU Cumulative GPA'}
                          {course.data.checkSubtype === 'major_gpa' &&
                            'Major GPA'}
                          {(course.data.checkSubtype === 'subject' ||
                            course.data.checkSubtype === 'courses') &&
                            `GPA in `}
                          {course.data.checkSubtype === 'subject' && 'all '}
                          {course.data.checkSubtype === 'courses' &&
                            course.data.checkCourses &&
                            course.data.checkCourses.map(
                              (checkCourse, index, array) => (
                                <span key={index}>
                                  {index !== 0 &&
                                    index === array.length - 1 &&
                                    'and '}
                                  {checkCourse.subject} {checkCourse.number}
                                  {array.length > 2 && index < array.length - 1
                                    ? ', '
                                    : ' '}
                                </span>
                              )
                            )}
                          {course.data.milestone && (
                            <span>
                              {Object.values(course.data.milestone)[0]}
                            </span>
                          )}
                          {course.data.subject && (
                            <span
                              className={classNames(
                                course.data.itemType !== 'req_check' &&
                                  'fw-bold'
                              )}
                            >
                              {course.data.subject}{' '}
                            </span>
                          )}
                          {course.data.number && (
                            <>
                              <span className="fw-bold">
                                {course.data.number}
                              </span>
                              :{' '}
                            </>
                          )}

                          {course.data?.topic?.description ? (
                            course.data.topic.description
                          ) : course.data?.proposedTitle ? (
                            <span>
                              {course.data.proposedTitle}
                              <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                Proposed
                              </span>
                            </span>
                          ) : (
                            course.data.description
                          )}

                          {!!course.data.division && (
                            <span>
                              {course.data.division.trim()
                                ? course.data.division === 'Upper' ||
                                  course.data.division === 'Lower'
                                  ? course.data.division + ' Division '
                                  : course.data.division + '-Level '
                                : ''}
                            </span>
                          )}
                          {course.data.checkSubtype === 'subject' && ' courses'}
                          {course.data.group && course.data.group + ': '}
                          {course.data.groupIndex !== 'custom_text' &&
                            course.data.text}
                          {course.data.customText}
                          {course.data.gs && course.data.subject && (
                            <span
                              className={classNames(
                                course.data.gs &&
                                  'text-white fw-bold bg-gray-7 rounded-1 text-nowrap mx-1'
                              )}
                              style={{
                                padding: '2px 8px',
                                fontSize: '14px',
                              }}
                            >
                              {course.data.gs}
                            </span>
                          )}
                          {course.data.itemType === 'req_gs' &&
                            !!course.data.gs && (
                              <>
                                {!!course.data.gs && (
                                  <span>
                                    {
                                      StaticData.gsList.find(
                                        (gs) => gs.value === course.data.gs
                                      ).label
                                    }{' '}
                                    ({course.data.gs})
                                  </span>
                                )}{' '}
                              </>
                            )}
                          {course.data.itemType === 'req_elective' && (
                            <span>Elective</span>
                          )}
                          {course.data?.recommended?.courses?.length > 0 && (
                            <>
                              <br />
                              <span style={{ marginLeft: '55px' }}>
                                <span className="fst-italic">Recommended:</span>
                                {course.data.recommended.courses.map(
                                  (c, recIndex, array) => (
                                    <span key={recIndex}>
                                      {recIndex !== 0 &&
                                        !c.connector?.trim(' ') &&
                                        'or '}
                                      {c.connector} {c.subject} {c.number}
                                      {!!c.proposedTitle && (
                                        <span className="text-warning-custom bg-warning-custom fw-bold mx-1 px-1">
                                          Proposed
                                        </span>
                                      )}{' '}
                                    </span>
                                  )
                                )}
                              </span>
                            </>
                          )}
                          {courseLists?.[course.data.courseListId] && (
                            <span className="fw-bold mx-1">
                              <FontAwesomeIcon
                                icon={faList}
                                style={{ marginRight: '4px' }}
                              />
                              {courseLists[course.data.courseListId]
                                ?.courseListName || 'Untitled course list'}
                            </span>
                          )}
                        </span>
                        {courseLists &&
                          courseLists[course.data.courseListId] && (
                            <CourseListAccordion course={course} />
                          )}
                      </span>
                    </Fragment>
                  ))
                ) : (
                  <div className="text-gray-5">{'\u2014'}</div>
                )}
              </div>
            </div>

            {dataFromParent?.details?.courses && !readOnly && (
              <div>
                <span
                  className="button-link"
                  onClick={() => handleDeleteRequirement()}
                  role="button"
                >
                  <FontAwesomeIcon icon={faTrash} className="me-1" />
                  Remove requirement
                </span>
              </div>
            )}
          </div>

          <div className="bg-gray-1 px-6 py-4">
            <div className="d-flex gap-1 justify-content-between align-items-center">
              <div className="d-flex gap-1">
                {!!componentId && (
                  <div>
                    <div className="fw-bold">Section</div>
                    <select
                      style={{ width: '270px' }}
                      value={selectedComponentId}
                      onChange={(e) => handleSelectComponent(e)}
                    >
                      {Object.entries(templateComponentObjects).map(
                        ([compId, compObject]) => (
                          <option key={compId} value={compId}>
                            {JSON.parse(compObject.details).componentName}
                          </option>
                        )
                      )}
                    </select>
                  </div>
                )}

                <div>
                  <div className="fw-bold">Subsection</div>
                  <select
                    style={{ width: '270px' }}
                    value={selectedSubsectionId}
                    onChange={(e) => setSelectedSubsectionId(e.target.value)}
                  >
                    {templateComponentObjects?.[selectedComponentId]?.details &&
                      !JSON.parse(
                        templateComponentObjects[selectedComponentId].details
                      )?.subsections?.[selectedComponentId] && (
                        <option value={selectedComponentId}>{'\u2014'}</option>
                      )}
                    {fromChecksheet || selectedComponentId !== componentId
                      ? Object.entries(
                          JSON.parse(
                            templateComponentObjects[selectedComponentId]
                              .details
                          ).subsections
                        ).map(([subId, subObject]) => (
                          <option key={subId} value={subId}>
                            {!!subObject.subsectionName
                              ? subObject.subsectionName
                              : '\u2014'}
                          </option>
                        ))
                      : Object.entries(componentSubsections).map(
                          ([subId, subObject]) => (
                            <option key={subId} value={subId}>
                              {!!subObject.subsectionName
                                ? subObject.subsectionName
                                : '\u2014'}
                            </option>
                          )
                        )}
                    {fromChecksheet &&
                      !!checksheetRequirementList?.[selectedComponentId] &&
                      Object.entries(
                        checksheetRequirementList[selectedComponentId]
                          .subsections
                      ).map(
                        ([subId, subObject]) =>
                          !!subObject.subsectionName && (
                            <option key={subId} value={subId}>
                              {subObject.subsectionName}
                            </option>
                          )
                      )}
                  </select>
                </div>
              </div>

              <div className="d-flex gap-1" style={{ height: 'fit-content' }}>
                {!readOnly && (
                  <Button
                    label="Save"
                    color="maroon"
                    onClick={handleSaveCourses}
                  />
                )}
                <Button label="Cancel" color="gray" onClick={cancelData} />
              </div>
            </div>
          </div>
        </div>
      ) : showAsCourseList ? (
        <CourseListForm
          courseListId={courseListId}
          handleClose={handleShowAsModal}
          isLocked={isLocked}
          holdsLock={holdsLock}
        />
      ) : (
        <div></div>
      )}
    </>
  );
};

export default Multiple;
