import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation, useQuery } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash/isEqual';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { Button } from '@asu/components-core/dist/libCore.es';
import Multiple from './RequirementMultiple';
// import GeneralStudy from './ValidationGeneralStudy';
// import ComponentSettings from './ValidationComponentSettings';
// import Notes from './Notes';
import StaticData from './StaticData';
import Component from './Component';
import useServiceAuth from 'auth/useServiceAuth';

import {
  componentGet,
  componentGetAll,
  componentSave,
  componentUpdate,
} from 'apis/componentAPIs';
import {
  clearComponent,
  setAll,
  // setComponentRequirementList,
  setSubsections,
} from 'state/slices/componentSlice';
import { setComponentList } from 'state/slices/dataListsSlice';
import {
  addTemplateComponent,
  editTemplateComponent,
} from 'state/slices/templateSlice';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const ComponentForm = ({
  id = null,
  handleClose = null,
  addToTemplate = false,
}) => {
  const { getAccessToken } = useServiceAuth();
  const [dataToModal, setDataToModal] = useState({});

  const year = useSelector((state) =>
    StaticData.yearList.find((yearObj) => yearObj.value === state.settings.year)
  );
  const componentName = useSelector((state) => state.component.componentName);
  const notes = useSelector((state) => state.component.notes);
  const componentSettings = useSelector(
    (state) => state.component.componentSettings
  );
  const subsections = useSelector((state) => state.component.subsections);
  const folder = useSelector((state) => state.component.folder);
  const [showAsComponent, setShowAsComponent] = useState(true);
  const [requirementType, setRequirementType] = useState(null);
  const [error, setError] = useState('');

  const dispatch = useDispatch();

  /********** APIs **********/

  // Get all components after saving and dispatch component list to update component library
  const { mutate: getAllComponents } = useMutation({
    mutationFn: componentGetAll,
    onSuccess: async (data) => {
      const next = [];

      data.forEach((component) => {
        if (component['details']) {
          const jsonObject = JSON.parse(component['details'].S);
          const obj = {
            id: component['sk'].S,
            details: jsonObject,
            componentObj: component,
          };
          next.push(obj);
        }
      });

      dispatch(setComponentList(next));
    },
  });

  // Get new component after saving and add to template
  const { mutate: getSavedComponent } = useMutation({
    mutationFn: componentGet,
    onSuccess: async (data) => {
      if (addToTemplate)
        dispatch(addTemplateComponent({ id: data['sk'], object: data }));
    },
  });

  // Save new component to database
  const {
    mutate: saveComponent,
    error: saveComponentError,
    isError: saveComponentIsError,
  } = useMutation({
    mutationFn: componentSave,
    onSuccess: async (data, { token }) => {
      const sectionId = data.split(' ')[2].trim();
      console.log('SECTION ID: ', sectionId);
      id = sectionId.replace('COMPONENT-', '');
      console.log('ID: ', id);
      //after a new section is created, update the subsection id to be the section id if temporary id is 0
      updateSubsectionIdToSectionId(id);
      getAllComponents({ token });
      getSavedComponent({ id, token });
    },
  });

  // Get existing component after updating and update template
  const { mutate: getUpdatedComponent } = useMutation({
    mutationFn: componentGet,
    onSuccess: async (data) => {
      dispatch(editTemplateComponent({ id: data['sk'], object: data }));
    },
  });

  // Update existing component in database
  const {
    mutate: updateComponent,
    error: updateComponentError,
    isError: updateComponentIsError,
  } = useMutation({
    mutationFn: componentUpdate,
    onSuccess: async (data, { id, token }) => {
      getUpdatedComponent({ id, token });
    },
  });

  // Get component to be shown to user
  const {
    data: componentData,
    error: getComponentError,
    isError: getComponentIsError,
    isSuccess: getComponentIsSuccess,
    isPending,
    fetchStatus,
    refetch: refetchComponent,
  } = useQuery({
    queryKey: ['component', id],
    queryFn: async () => {
      const token = await getAccessToken();
      return componentGet({
        id,
        token,
      });
    },
    enabled: !!id,
  });

  const modalSetAsComponent = () => {
    setRequirementType(null);
    setShowAsComponent(true);
  };

  const modalSetAsRequirement = (type) => {
    setShowAsComponent(false);
    setRequirementType(type);
  };

  // const modalSetAsValidation = () => {
  //   setShowAsComponent(false);
  //   setShowAsValidation(true);
  // };

  // const modalSetAsSettings = () => {
  //   setShowAsComponent(false);
  //   setShowAsSettings(true);
  // };

  // const modalSetAsNotes = () => {
  //   setShowAsComponent(false);
  //   setShowAsNotes(true);
  // };

  const handleSaveComponent = async () => {
    if (componentName) {
      const jsonData = {
        year: year.value,
        componentName: componentName,
        notes: notes,
        settings: componentSettings,
        // requirements: componentRequirementList,
        subsections: subsections,
        folder: folder,
      };

      console.log('JSON: ', jsonData);

      const token = await getAccessToken();
      saveComponent({ jsonData: jsonData, token: token });

      if (saveComponentIsError) setError(saveComponentError.message);

      handleClose();
      dispatch(clearComponent());
    } else {
      alert('A section name is required');
    }
  };

  const handleUpdateComponent = async () => {
    if (componentName) {
      const jsonData = {
        year: year.value,
        componentName: componentName,
        notes: notes,
        settings: componentSettings,
        // requirements: componentRequirementList,
        subsections: subsections,
        folder: folder,
      };

      console.log('JSON: ', jsonData);

      const token = await getAccessToken();
      await updateComponent({
        id,
        jsonData,
        token,
      });

      if (updateComponentIsError) setError(updateComponentError.message);

      handleClose();
      dispatch(clearComponent());
    } else {
      alert('A section name is required');
    }
  };

  const handleCloseComponentForm = () => {
    handleClose();
    dispatch(clearComponent());
  };

  // click Edit button to open modal and edit the data
  const openEditModal = (index, type, details, id, subsectionId) => {
    const modalData = {
      index: index,
      type: type,
      details: details,
      id: id,
      subsectionId: subsectionId,
    };

    console.log('dataToModal: ', modalData);
    setDataToModal(modalData);
    modalSetAsRequirement(type);
  };

  //add index and req type to data. We need to know the index when data sent back from child component
  //and what type of the requirment it is.
  const createWrappedData = (index, type, details, id, subsectionId) => {
    const wrappedData = {
      index: index,
      type: type,
      details: details,
      id: id,
      subsectionId: subsectionId,
    };
    console.log('wrappedData: ', wrappedData);
    return wrappedData;
  };

  // Callback function to receive data from the modal
  const receiveDataFromModal = (data) => {
    console.log('Received data from modal:' + JSON.stringify(data));
    if (data?.subsectionId) console.log('subsectionId: ', data.subsectionId);

    let subsectionId = '0';

    if (data?.subsectionId && data.subsectionId !== '999') {
      // update existing subsections
      subsectionId = data.subsectionId;
    } else if (
      // create new subsections
      data?.subsectionId === '999' &&
      data?.details?.subsectionName !== '' &&
      data?.details?.subsectionName !== undefined
    ) {
      subsectionId = uuidv4();
    } else {
      // update main section
      subsectionId = '0'; // when a new section is created, the subsection id is 0 for requirements in main section, it is a temporary id. It will be updated to the section id after the section is created.
    }

    console.log('new subsectionId: ', subsectionId);

    if (data) {
      updateData(data.index, data.type, data.details, id, subsectionId);
    }
  };

  // When a new section is created, update the subsection id to be the section id if the temporary subsection id is 0 (main section)
  const updateSubsectionIdToSectionId = async (sectionId) => {
    console.log(
      'update subsectionid to be sectionid: ' + JSON.stringify(subsections)
    );

    const newSubsections = { ...(subsections || {}) };

    // Get the main subsection with temporary id 0
    const mainSubsection = newSubsections['0'];
    if (mainSubsection) {
      // Update the subsection id to be the section id
      newSubsections[sectionId] = mainSubsection;
      // Delete the main subsection with temporary id 0
      delete newSubsections['0'];
      dispatch(setSubsections(newSubsections));

      const jsonData = {
        year: year.value,
        componentName: componentName,
        notes: notes,
        settings: componentSettings,
        subsections: newSubsections,
        folder: folder,
      };

      console.log('JSON: ', jsonData);
      const token = await getAccessToken();
      await updateComponent({
        id,
        jsonData,
        token,
      });

      if (updateComponentError) setError(updateComponentError.message);
    } else {
      console.log('No subsection found with empty string key.');
    }
  };

  // this function is important !!! it update the template data with the new data from the modal
  // update subsections data for section in template
  const updateData = (index, type, newData, id, subsectionId) => {
    console.log('Updating data...');
    console.log(
      'Subsection ID: ' +
        subsectionId +
        ', ' +
        'Index: ' +
        index +
        ', Type: ' +
        type +
        ', New Data: ' +
        JSON.stringify(newData)
    );

    if (index === 9999 && subsections && subsections[subsectionId]) {
      console.log('THis is where it is messing up');
      index = subsections[subsectionId].requirements?.length || 0;
      console.log('index:', index);
    } else if (index === 9999) {
      index = 0;
    }

    const newSubsections = { ...(subsections || {}) };

    console.log('subsection: ', subsections[subsectionId]);
    const subsectionName = subsections[subsectionId]?.subsectionName;

    const newDataList = subsections[subsectionId]?.requirements
      ? [...subsections[subsectionId].requirements]
      : [];

    if (!newData || !isEqual(newDataList[index], newData)) {
      if (!newData) {
        // if newData is empty (delete data)
        newDataList.splice(index, 1);
      } else {
        // if new data is not empty, then update data
        newDataList[index] = newData;
      }
    }

    const newObject = {
      requirements: newDataList,
      subsectionName: subsectionName,
      hours: subsections[subsectionId]?.hours || 0,
      upperDivisionHours: subsections[subsectionId]?.upperDivisionHours || 0,
    };

    newSubsections[subsectionId] = newObject;

    console.log('new subsection data: ', newSubsections);

    dispatch(setSubsections(newSubsections));
  };

  // this function is to update the subsection name and hours in template
  const updateSubsection = (data, subsectionId, id, action) => {
    console.log('Updating subsectionname...');
    console.log('subsectionSettingData: ', data);

    const newSubsections = { ...(subsections || {}) };
    const subId = subsectionId !== '999' ? subsectionId : uuidv4();

    const newDataList = subsections[subId]?.requirements
      ? [...subsections[subId].requirements]
      : [];

    const newObject = {
      requirements: newDataList,
      subsectionName:
        action === 'subsectionName'
          ? data
          : subsections[subId]?.subsectionName || '',
      hours: action === 'hours' ? data : subsections[subId]?.hours || 0,

      upperDivisionHours:
        action === 'upperDivisionHours'
          ? data
          : subsections[subId]?.upperDivisionHours || 0,
    };

    newSubsections[subId] = newObject;

    console.log('new subsection data: ', newSubsections);

    dispatch(setSubsections(newSubsections));
  };

  useEffect(() => {
    if (!!id) {
      if (getComponentIsError) setError(getComponentError.message);

      if (getComponentIsSuccess) {
        console.log('componentData: ', componentData);

        const jsonDetails = JSON.parse(componentData['details']);
        const yearValue = StaticData.yearList.find(
          (obj) => obj.value === jsonDetails.year
        );

        dispatch(
          setAll({
            componentId: componentData['sk'],
            year: yearValue,
            componentName: jsonDetails.componentName,
            notes: jsonDetails.notes,
            componentSettings: jsonDetails.settings,
            // componentRequirementList: jsonDetails.requirements,
            subsections: jsonDetails.subsections,

            folder: jsonDetails.folder,
          })
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [componentData, getComponentIsSuccess, refetchComponent]);

  useEffect(() => {
    console.log(subsections);
  }, [subsections]);

  if (isPending && fetchStatus === 'fetching')
    return <div className="container">Loading...</div>;

  if (getComponentIsError || saveComponentIsError || updateComponentIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div style={{ width: '1224px' }}>
      {showAsComponent ? (
        <>
          <div className="m-6">
            <Component
              id={id}
              year={year?.value}
              edit={true}
              openEditModal={openEditModal}
              // modalSetAsRequirement={modalSetAsRequirement}
              updateData={updateData}
              updateSubsection={updateSubsection}
            />
          </div>
          <div className="bg-gray-1 px-6 pt-3 pb-6">
            {/* TODO: */}
            {/* <form className="uds-form">
              <div className="form-check mb-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id="applyToAllCatalogYearCheck"
                  data-ga-input="checkbox"
                  data-ga-input-name="onclick"
                  data-ga-input-event="select"
                  data-ga-input-action="click"
                  data-ga-input-region="main content"
                  data-ga-input-section="I like checkboxes"
                  value="option1"
                  disabled
                />
                <label
                  className="form-check-label fw-normal"
                  style={{ fontSize: '14px', paddingTop: '4px' }}
                  htmlFor="applyToAllCatalogYearCheck"
                >
                  Apply changes to this section in all templates with Catalog
                  Year {year.label}
                </label>
              </div>
            </form> */}
            {id && (
              <div className="d-flex gap-1 align-items-center mb-3">
                <FontAwesomeIcon icon={faInfoCircle} />
                <div>
                  Changes to this section will apply to all templates with
                  Catalog Year {year.label}
                </div>
              </div>
            )}
            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex gap-1 align-items-center">
                {!id ? (
                  <Button
                    onClick={handleSaveComponent}
                    label="Create new section"
                    color="maroon"
                  />
                ) : (
                  <Button
                    onClick={handleUpdateComponent}
                    label="Update in library"
                    color="maroon"
                  />
                )}
                <Button
                  onClick={handleCloseComponentForm}
                  label="Cancel"
                  color="gray"
                />
              </div>
              {/* TODO: */}
              {/* <div className="d-flex gap-1 align-items-center opacity-50">
                <FontAwesomeIcon icon={faTrash} className="text-gray-6" />
                <div className="button-link">Delete section from library</div>
              </div> */}
            </div>
          </div>
        </>
      ) : requirementType === 'req_multiple' ? (
        <Multiple
          componentId={id}
          year={year.value}
          componentName={componentName}
          dataFromParent={dataToModal}
          dataToParent={(newData) => receiveDataFromModal(newData)}
          createWrappedData={createWrappedData}
          exitRequirement={modalSetAsComponent}
        />
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default ComponentForm;
