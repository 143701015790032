import React, { useState, useEffect, Fragment } from 'react';
import Course from './RequirementCourse';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const Recommended = ({ recommended, setRecommended }) => {
  const [recommendedCourses, setRecommendedCourses] = useState(
    !!recommended?.courses?.length ? recommended.courses : [{}]
  );

  const handleInputChange = (index, field, value) => {
    setRecommendedCourses((prevCourses) => {
      const updatedCourses = [...prevCourses];
      updatedCourses[index] = { ...updatedCourses[index], [field]: value };
      return updatedCourses;
    });
  };

  const handleAddCourse = () => {
    setRecommendedCourses((prevCourses) => [...prevCourses, {}]);
  };

  const handleRemoveCourse = (index) => {
    setRecommendedCourses((prevCourses) => {
      const updatedCourses = [...prevCourses];
      updatedCourses.splice(index, 1);
      return updatedCourses;
    });
  };

  useEffect(() => {
    setRecommended({ courses: [...recommendedCourses] });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendedCourses]);

  return (
    <>
      <div className="ms-5 mb-2">
        <div className="d-flex gap-2 fw-bold mb-1">Recommended courses:</div>
        <div className="d-flex">
          <div id="componentsContainer">
            {recommendedCourses.map((course, index) => (
              <Course
                key={index}
                index={index}
                data={course}
                handleInputChange={handleInputChange}
                handleDeleteComponent={handleRemoveCourse}
                isRecommendedCourse={true}
              />
            ))}
          </div>
        </div>
        <div className="ms-5">
          <span
            className="text-maroon underline-hover"
            onClick={handleAddCourse}
            role="button"
          >
            <FontAwesomeIcon icon={faPlusCircle} className="text-maroon me-1" />
            <span>Add course</span>
          </span>
        </div>
      </div>
    </>
  );
};

export default Recommended;
