import { Button } from '@asu/components-core';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const ProgressUpdateForm = ({
  view,
  progress,
  updatedProgress,
  college,
  department,
  mainOwner,
  handleProgressUpdate,
  handleClose,
}) => {
  const [message, setMessage] = useState('');
  const [sendTo, setSendTo] = useState(updatedProgress);
  const [actionFinished, setActionFinished] = useState(false);

  const handleConfirm = () => {
    handleProgressUpdate(
      message,
      sendTo === 'COMPLETE' ? 'complete' : view,
      sendTo
    );

    setActionFinished(true);
  };

  return (
    <div className="p-6" style={{ width: '796px' }}>
      {!actionFinished ? (
        <div>
          {view === 'submit' ? (
            <div>
              <h2 className="mt-0 mb-3">Ready to submit?</h2>
              <h6 className="fw-bold">Message (optional)</h6>
              <p>
                Include a message to your reviewer to note any comments,
                questions or concerns.
              </p>
            </div>
          ) : view === 'return' ? (
            <div>
              <h2 className="mt-0 mb-3">Return checksheet</h2>
              {progress === 'COLLEGE' ? (
                <div className="mb-3">
                  <h6 className="fw-bold">Recipient</h6>
                  <div>Department: {mainOwner.departmentDescription}</div>
                </div>
              ) : progress === 'PROVOST' &&
                mainOwner.collegeAcadOrg === mainOwner.departmentAcadOrg ? (
                <div className="mb-3">
                  <h6 className="fw-bold">Recipient</h6>
                  <div>College/school: {mainOwner.collegeDescription}</div>
                </div>
              ) : progress === 'PROVOST' || progress === 'DARS' ? (
                <div className="uds-form">
                  <fieldset className="mt-0 mb-3">
                    <h6 className="fw-bold">Who are you returning this to?</h6>
                    {progress === 'DARS' && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="provostRadio"
                          id="provostRadio"
                          data-ga-input="radio button"
                          data-ga-input-name="onclick"
                          data-ga-input-event="select"
                          data-ga-input-action="click"
                          data-ga-input-region="main content"
                          data-ga-input-section="Provost"
                          value="PROVOST"
                          checked={sendTo === 'PROVOST'}
                          onChange={() => setSendTo('PROVOST')}
                        />
                        <label
                          className="form-check-label"
                          htmlFor="provostRadio"
                        >
                          Provost
                        </label>
                      </div>
                    )}
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="collegeRadio"
                        id="collegeRadio"
                        data-ga-input="radio button"
                        data-ga-input-name="onclick"
                        data-ga-input-event="select"
                        data-ga-input-action="click"
                        data-ga-input-region="main content"
                        data-ga-input-section="College"
                        value="COLLEGE"
                        checked={sendTo === 'COLLEGE'}
                        onChange={() => setSendTo('COLLEGE')}
                      />
                      <label
                        className="form-check-label fw-normal"
                        htmlFor="collegeRadio"
                      >
                        <span className="fw-bold">College/school:</span>{' '}
                        {mainOwner.collegeDescription}
                      </label>
                    </div>
                    {mainOwner.collegeAcadOrg !==
                      mainOwner.departmentAcadOrg && (
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="radio"
                          name="departmentRadio"
                          id="departmentRadio"
                          data-ga-input="radio button"
                          data-ga-input-name="onclick"
                          data-ga-input-event="select"
                          data-ga-input-action="click"
                          data-ga-input-region="main content"
                          data-ga-input-section="Department"
                          value="DEPARTMENT"
                          checked={sendTo === 'DEPARTMENT'}
                          onChange={() => setSendTo('DEPARTMENT')}
                        />
                        <label
                          className="form-check-label fw-normal"
                          htmlFor="departmentRadio"
                        >
                          <span className="fw-bold">Department: </span>{' '}
                          {mainOwner.departmentDescription}
                        </label>
                      </div>
                    )}
                  </fieldset>
                </div>
              ) : (
                <div>Status not found</div>
              )}
              <h6 className="fw-bold">Message (optional)</h6>
              <p>Describe why you are returning this checksheet.</p>
            </div>
          ) : view === 'recall' ? (
            <div>
              <h2 className="mt-0 mb-3">Request a recall</h2>
              <p>
                If you would like to make a change, you must request a recall
                since this checksheet is either in review or pending DARS
                encoding.
              </p>
              <h6 className="fw-bold">Message (required)</h6>
              <p>
                Describe why you are requesting a recall of this checksheet.
              </p>
            </div>
          ) : (
            <div>View not found</div>
          )}
          <textarea
            className="mb-6 w-100"
            rows={12}
            placeholder="Write your message here..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <div className="d-flex gap-3 justify-content-between align-items-center">
            <Button label="Cancel" onClick={handleClose} />
            <Button label="Submit" color="gold" onClick={handleConfirm} />
          </div>
        </div>
      ) : (
        <div>
          <div className="d-flex gap-2 align-items-center mb-3">
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="text-success"
              style={{ width: '24px', height: '24px' }}
            />
            <div>
              {view === 'submit'
                ? sendTo === 'COMPLETE'
                  ? 'Checksheet is completed!'
                  : `Checksheet submitted.`
                : view === 'return'
                  ? `Checksheet returned.`
                  : view === 'recall'
                    ? 'Recall request submitted.'
                    : ''}
            </div>
          </div>
          <Button label="Okay" color="maroon" onClick={handleClose} />
        </div>
      )}
    </div>
  );
};

export default ProgressUpdateForm;
