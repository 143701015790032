import React, { useState, useEffect, Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleCheck,
  faRedo,
  faTriangleExclamation,
  faUndo,
} from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  setAll,
  setTemplateName,
  setYear as setTemplateYear,
  setTemplateProgramType,
  undo,
  redo,
  setTemplateId,
} from 'state/slices/templateSlice';
import { setYear as setCatalogYear } from 'state/slices/settingsSlice';
import { templateSave, templateGet, templateUpdate } from 'apis/templateAPIs';

import StaticData from './StaticData';
import { classNames } from 'utils';

import { Button } from '@asu/components-core/dist/libCore.es';
import Component from './Component';
import AddComponentDropdown from './AddComponentDropdown';
import TabPanel from './TabPanel';

import { useMutation, useQuery } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import TemplateFormTextOptions from './TemplateFormTextOptions';
import TemplateFormValidationRules from './TemplateFormValidationRules';
import LoadingSkeleton from './LoadingSkeleton';
import TemplateFormMilestones from './TemplateFormMilestones';
import { useNavigate } from 'react-router-dom';

const ComponentLoadingSkeleton = () => {
  return (
    <div className="mt-5">
      <div className="d-flex justify-content-between mb-2">
        <LoadingSkeleton width={'500px'} height={'40px'} />
        <LoadingSkeleton width={'340px'} height={'40px'} />
      </div>
      <div className="d-flex gap-8 mb-2">
        <LoadingSkeleton width={'100px'} height={'40px'} />
        <LoadingSkeleton width={'170px'} height={'40px'} />
      </div>
      {[...Array(5)].map((item, index) => (
        <LoadingSkeleton key={index} width={'100%'} height={'40px'} />
      ))}
    </div>
  );
};

const SaveAlert = ({ templateName, succeeded, error = null, setShowAlert }) => {
  return (
    <>
      {succeeded ? (
        <div
          className="alert alert-success alert-dismissable position-fixed mb-0"
          role="alert"
          style={{ width: '80%', zIndex: 2 }}
        >
          <FontAwesomeIcon
            icon={faCircleCheck}
            style={{ width: '32px', height: '32px' }}
            className="me-4"
          />
          <div className="alert-content">
            Template '{templateName}' has been saved.
          </div>
          <div className="alert-close">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-black close"
              data-bs-dismiss="alert"
              aria-label="Close"
              data-ga="close cross"
              data-ga-name="onclick"
              data-ga-event="modal"
              data-ga-action="close"
              data-ga-type="click"
              data-ga-region="main content"
              data-ga-section="Success (green): This is a success alert to confirm or notify. It is
          built using the Bootstrap 4 .alert-success class"
              onClick={() => setShowAlert(false)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      ) : (
        <div
          className="alert alert-danger alert-dismissable position-fixed mb-0"
          role="alert"
          style={{ width: '80%', zIndex: 2 }}
        >
          <FontAwesomeIcon
            icon={faTriangleExclamation}
            style={{ width: '32px', height: '32px' }}
            className="me-4"
          />
          <div className="alert-content">
            Template was not saved.
            <br />
            Error: {error}
          </div>
          <div className="alert-close">
            <button
              type="button"
              className="btn btn-circle btn-circle-alt-black close"
              aria-label="Close"
              data-bs-dismiss="alert"
              data-ga="close cross"
              data-ga-name="onclick"
              data-ga-event="modal"
              data-ga-action="close"
              data-ga-type="click"
              data-ga-region="main content"
              data-ga-section="Error (red): This is a danger alert used specifically for errors. It is
          built using the Bootstrap 4 .alert-danger class"
              onClick={() => setShowAlert(false)}
            >
              <i className="fas fa-times"></i>
            </button>
          </div>
        </div>
      )}
    </>
  );
};

const TemplateForm = ({ tempId }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [temporaryName, setTemporaryName] = useState('');
  const [timesSaved, setTimesSaved] = useState(0);
  const [showEditTemplateName, setShowEditTemplateName] = useState({
    hover: false,
    edit: false,
  });
  const [error, setError] = useState('');
  const [lockSave, setLockSave] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [saveSucceeded, setSaveSucceeded] = useState(false);

  const templateId = useSelector((state) => state.template.templateId);
  const templateYear = useSelector((state) => state.template.year);
  const catalogYear = useSelector((state) => state.settings.year);
  const templateName = useSelector((state) => state.template.templateName);
  const templateProgramType = useSelector(
    (state) => state.template.templateProgramType
  );
  const templateSettings = useSelector(
    (state) => state.template.templateSettings
  );
  const templateComponentIds = useSelector(
    (state) => state.template.templateComponentIds
  );
  const templateValidationList = useSelector(
    (state) => state.template.templateValidationList
  );
  const templateTextOptions = useSelector(
    (state) => state.template.templateTextOptions
  );
  const templateMilestones = useSelector(
    (state) => state.template.templateMilestones
  );
  const templatePastState = useSelector((state) => state.template.past);
  const templateFutureState = useSelector((state) => state.template.future);
  const currentAction = useSelector((state) => state.template.currentAction);
  const futureAction = useSelector((state) => state.template.futureAction);

  const {
    mutate: saveTemplate,
    error: saveTemplateError,
    isError: saveTemplateIsError,
  } = useMutation({
    mutationFn: templateSave,
    onSuccess: async (data) => {
      const temp = data.split(' ')[2].trim();
      const id = temp.substring(9, temp.length);
      console.log('ID: ', id);
      dispatch(setTemplateId(id));
      dispatch(setTemplateYear(catalogYear));
      setSaveSucceeded(true);
      setShowAlert(true);
      setLockSave(false);
    },
    onError: (error, variables, context) => {
      console.log('Error: ', error);
      setError(error.message);
      setSaveSucceeded(false);
      setShowAlert(true);
      setLockSave(false);
    },
  });

  const {
    mutate: updateTemplate,
    error: updateTemplateError,
    isError: updateTemplateIsError,
  } = useMutation({
    mutationFn: templateUpdate,
    onSuccess: async () => {
      setSaveSucceeded(true);
      setShowAlert(true);
      setLockSave(false);
    },
    onError: (error, variables, context) => {
      console.log('Error: ', error);
      setError(error.message);
      setSaveSucceeded(false);
      setShowAlert(true);
      setLockSave(false);
    },
  });

  const {
    data: templateData,
    error: getTemplateError,
    isError: getTemplateIsError,
    isPending: getTemplateIsLoading,
    fetchStatus: getTemplateFetchStatus,
    isSuccess: getTemplateIsSuccess,
    refetch,
  } = useQuery({
    queryKey: ['template', tempId],
    queryFn: async () => {
      const token = await getAccessToken();
      return templateGet({ id: tempId, token });
    },
    enabled: !!tempId,
  });

  const handleSelectChangeYear = (e) => {
    dispatch(setCatalogYear(e.target.value));
    navigate('/templates');
  };

  const handleSaveTemplate = async () => {
    if (templateName && templateName.trim(' ') !== '') {
      const jsonData = {
        year: templateId === '' ? catalogYear : templateYear,
        templateName: templateName,
        programType: templateProgramType,
        settings: templateSettings,
        validationRules: templateValidationList,
        textOptions: templateTextOptions,
        milestones: templateMilestones,
        components: templateComponentIds,
      };
      console.log('JSON: ', jsonData);

      if (templateId !== '') {
        const token = await getAccessToken();
        setLockSave(true);
        updateTemplate({ id: templateId, jsonData, token });

        if (updateTemplateIsError) {
          setError(updateTemplateError.message);
          setLockSave(false);
        }
      } else {
        const token = await getAccessToken();
        setLockSave(true);
        saveTemplate({ jsonData, token });

        if (saveTemplateIsError) {
          setError(saveTemplateError.message);
          setLockSave(false);
        }
      }

      setTimesSaved(timesSaved + 1);
    } else {
      alert('A template name is required');
    }
  };

  useEffect(() => {
    console.log('This is running');
    if (tempId !== '' && tempId !== null) {
      console.log('Refetching...');
      refetch();

      if (getTemplateIsError) setError(getTemplateError.message);

      if (getTemplateIsSuccess) {
        console.log(templateData);

        const jsonDetails = JSON.parse(templateData['details']);

        dispatch(
          setAll({
            templateId: templateData['sk'],
            year: jsonDetails.year,
            templateName: jsonDetails.templateName,
            templateProgramType: jsonDetails.programType ?? '',
            templateSettings: jsonDetails.settings,
            templateComponentIds: jsonDetails.components,
            templateComponentObjects: templateData['componentObjects'],
            templateValidationList: jsonDetails.validationRules,
            templateTextOptions: jsonDetails.textOptions,
            templateMilestones: jsonDetails.milestones ?? {},
          })
        );
        setTemporaryName(jsonDetails.templateName);
      }
    }
  }, [
    dispatch,
    getTemplateError,
    getTemplateIsError,
    getTemplateIsSuccess,
    tempId,
    refetch,
    templateData,
  ]);

  useEffect(() => {
    setTemporaryName(templateName);
  }, [templateName]);

  if (getTemplateIsError || saveTemplateIsError || updateTemplateIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      {showAlert && (
        <SaveAlert
          templateName={templateName}
          succeeded={saveSucceeded}
          error={error}
          setShowAlert={setShowAlert}
        />
      )}
      <div
        className="template-header bg-gray-1 py-3 position-fixed"
        style={{ width: '80%', zIndex: 1 }}
      >
        <div className="container">
          <div className="row align-items-center">
            {getTemplateIsLoading && getTemplateFetchStatus === 'fetching' ? (
              <LoadingSkeleton width={'714px'} height={'40px'} />
            ) : (
              <div
                className="position-relative col-7"
                onMouseEnter={() => {
                  if (!showEditTemplateName.edit)
                    setTemporaryName(templateName);
                  setShowEditTemplateName({
                    ...showEditTemplateName,
                    hover: true,
                  });
                }}
                onMouseLeave={() =>
                  setShowEditTemplateName({
                    ...showEditTemplateName,
                    hover: false,
                  })
                }
              >
                <input
                  type="text"
                  className={classNames(
                    showEditTemplateName.hover ||
                      showEditTemplateName.edit ||
                      templateName.trim(' ') === ''
                      ? 'visible opacity-100'
                      : 'invisible opacity-0',
                    templateName.trim(' ') === ''
                      ? 'position-relative'
                      : 'template-name-input',
                    'w-100 fs-4 fw-bold px-2 py-1'
                  )}
                  value={temporaryName}
                  onChange={(e) => {
                    if (e.target.value !== temporaryName)
                      setTemporaryName(e.target.value);
                  }}
                  onFocus={() =>
                    setShowEditTemplateName({
                      ...showEditTemplateName,
                      edit: true,
                    })
                  }
                  onBlur={() => {
                    setShowEditTemplateName({
                      ...showEditTemplateName,
                      edit: false,
                    });
                    if (temporaryName !== templateName)
                      dispatch(setTemplateName(temporaryName));
                  }}
                />
                <div className="fs-4 fw-bold">{templateName}</div>
              </div>
            )}
            <div className="col-5 d-flex justify-content-end gap-2">
              <div>
                <div className="fw-bold">Program Type</div>
                <select
                  value={templateProgramType}
                  onChange={(e) =>
                    dispatch(setTemplateProgramType(e.target.value))
                  }
                  className="text-gray-7 p-1"
                >
                  <option value="" disabled>
                    Select a program type
                  </option>
                  <option value="undergrad">Undergraduate degree</option>
                  <option value="minor">Minor program</option>
                  <option value="cert">Certificate program</option>
                </select>
              </div>
              <div>
                <div className="fw-bold">Catalog Year</div>
                <select
                  value={catalogYear}
                  onChange={handleSelectChangeYear}
                  styles={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                >
                  {StaticData.yearList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex gap-2 align-items-center">
                <div className="d-flex">
                  <span
                    onClick={() => dispatch(undo())}
                    className="m-1"
                    title={
                      currentAction !== '' ? `Undo ${currentAction}` : 'Undo'
                    }
                    role="button"
                  >
                    <FontAwesomeIcon
                      icon={faUndo}
                      className={classNames(
                        !templatePastState.length && 'text-gray-4'
                      )}
                    />
                  </span>
                  <span
                    onClick={() => dispatch(redo())}
                    className="m-1"
                    title={
                      futureAction !== '' ? `Redo ${futureAction}` : 'Redo'
                    }
                    role="button"
                  >
                    <FontAwesomeIcon
                      icon={faRedo}
                      className={classNames(
                        !templateFutureState.length && 'text-gray-4'
                      )}
                    />
                  </span>
                </div>
                <Button
                  label="Save"
                  onClick={handleSaveTemplate}
                  color="maroon"
                  size="small"
                  disabled={
                    (!timesSaved && !templatePastState.length) || lockSave
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: '154px' }}>
        <div className="row">
          <div className="col">
            <TabPanel
              tabs={[
                'Sections',
                'Validation Rules',
                'Text Options',
                'Milestones',
              ]}
            >
              <div className="pt-2 mt-2">
                <div className="d-flex align-items-center gap-2">
                  <AddComponentDropdown />
                  {getTemplateIsLoading &&
                  getTemplateFetchStatus === 'fetching' ? (
                    <LoadingSkeleton width={'150px'} height={'20'} />
                  ) : (
                    <div>
                      {templateComponentIds.length}{' '}
                      {templateComponentIds.length === 1
                        ? 'section'
                        : 'sections'}{' '}
                      added
                    </div>
                  )}
                </div>
                {getTemplateIsLoading &&
                getTemplateFetchStatus === 'fetching' ? (
                  <div className="d-flex flex-column gap-3">
                    <ComponentLoadingSkeleton />
                    <ComponentLoadingSkeleton />
                  </div>
                ) : (
                  templateComponentIds.map((componentId) => (
                    <div key={componentId} className="mb-9">
                      <Component id={componentId} year={catalogYear} />
                    </div>
                  ))
                )}
              </div>
              <div className="pt-2 mt-2 validation">
                <TemplateFormValidationRules />
              </div>
              <div className="pt-2 mt-2">
                <TemplateFormTextOptions />
              </div>
              <div className="pt-2 mt-2">
                <TemplateFormMilestones />
              </div>
            </TabPanel>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default TemplateForm;
