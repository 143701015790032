import React, { useEffect, useState } from 'react';
import ChecksheetForm from 'components/ChecksheetForm';
import { useQuery } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import ChecksheetValidation from 'components/ChecksheetValidation';
import { fetchDplUndergradProgramsByAcadPlanCode } from 'apis/dplAPIs';
import NoChecksheetFound from 'components/NoChecksheetFound';
import { checksheetGet } from 'apis/checksheetAPIs';
import { useSelector } from 'react-redux';

const Checksheet = () => {
  const { getAccessToken } = useServiceAuth();

  const params = new URLSearchParams(window.location.search);
  const paramId = params.get('id');
  const paramAcadPlan = paramId.split('*')[2];

  const [isLocked, setIsLocked] = useState(false);
  const [holdsLock, setHoldsLock] = useState(false);

  const lockedBy = useSelector((state) => state.checksheet.lockedBy);
  const asurite = useSelector((state) => state.user.asurite);
  const role = useSelector((state) => state.user.role);

  const {
    data: fetchProgramData,
    // error: fetchProgramsError,
    // isError: fetchProgramsIsError,
    isSuccess: fetchProgramIsSuccess,
    isPending: fetchProgramIsLoading,
    // fetchStatus: fetchProgramsFetchStatus,
  } = useQuery({
    queryKey: ['program', paramAcadPlan],
    queryFn: () => {
      return fetchDplUndergradProgramsByAcadPlanCode(paramAcadPlan);
    },
    enabled: !!paramAcadPlan,
  });

  const {
    // data: checksheetData,
    // error: getChecksheetError,
    isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
    // isLoading: getChecksheetIsLoading,
    // refetch: refetchChecksheets,
  } = useQuery({
    queryKey: ['checksheet', paramId],
    queryFn: async () => {
      console.log('paramId', paramId);
      const token = await getAccessToken();
      return checksheetGet({ id: paramId, token });
    },
    enabled: !!paramId,
    retry: (failureCount, error) => {
      if (error.response.status === 404) return false; // Don't retry if it's a 404
      return failureCount < 3; // Retry up to 3 times for other errors
    },
  });

  useEffect(() => {
    setIsLocked(!!lockedBy && !!Object.keys(lockedBy).length);
    setHoldsLock(
      !!lockedBy &&
        !!Object.keys(lockedBy).length &&
        lockedBy.asurite === asurite
    );
  }, [lockedBy, asurite]);

  if (fetchProgramIsLoading) {
    return <div className="container">Loading...</div>;
  }

  return (
    <>
      {getChecksheetIsSuccess && fetchProgramIsSuccess ? (
        <div>
          <ChecksheetValidation
            checksheetId={paramId}
            isLocked={isLocked && !role.includes('DARS')}
            holdsLock={holdsLock}
          />
          <div className="d-flex justify-content-end">
            <div className="bg-white" style={{ width: '80%' }}>
              <div className="px-0 text-gray-7">
                <ChecksheetForm
                  checkId={paramId}
                  program={fetchProgramData}
                  isLocked={isLocked}
                  holdsLock={holdsLock}
                />
              </div>
            </div>
          </div>
        </div>
      ) : (
        getChecksheetIsError && (
          <NoChecksheetFound
            checksheetId={paramId}
            program={fetchProgramData}
          />
        )
      )}
    </>
  );
};

export default Checksheet;
