import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Button } from '@asu/components-core';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
  faArrowDown,
  faArrowUp,
  faBarsStaggered,
  faGripVertical,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownCustomToggle from './DropdownCustomToggle';
import { classNames } from 'utils';
import { setSubsections } from 'state/slices/componentSlice';

const Sortable = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const DraggableSubsection = ({ id, subsection, index, array, setItems }) => {
  const handleMoveUp = () => {
    setItems((items) => {
      return arrayMove(items, index, index - 1);
    });
  };

  const handleMoveDown = () => {
    setItems((items) => {
      return arrayMove(items, index, index + 1);
    });
  };

  return (
    <div className="d-flex gap-2 justify-content-between align-items-center border-top border-gray-4 py-1">
      <div key={id} role="button" className="d-flex gap-2 align-items-center">
        <FontAwesomeIcon icon={faGripVertical} className="text-gray-4" />
        <div className="fw-bold">{subsection.subsectionName}</div>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faArrowUp}
          className={classNames(
            index === 0 ? 'pe-none text-gray-4' : 'text-maroon'
          )}
          onClick={handleMoveUp}
        />
        <FontAwesomeIcon
          icon={faArrowDown}
          className={classNames(
            index === array.length - 1 ? 'pe-none text-gray-4' : 'text-maroon'
          )}
          onClick={handleMoveDown}
        />
      </div>
    </div>
  );
};

const ComponentOptions = ({ id, sectionId }) => {
  const dispatch = useDispatch();

  const subsections = useSelector((state) => state.component.subsections);
  const [items, setItems] = useState([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleReorderSubsections = () => {
    let mainId = 0;

    if (!!subsections[sectionId]) mainId = sectionId;

    const reorderedSubsections = {
      [mainId]: subsections[mainId],
      ...Object.fromEntries(items),
    };

    dispatch(setSubsections(reorderedSubsections));
    setShowDropdown(false);
  };

  const handleResetSubsectionOrder = () => {
    setItems(
      Object.entries(subsections).filter(
        ([subsectionId, subsection]) =>
          subsectionId !== sectionId &&
          subsectionId !== 0 &&
          subsectionId !== '0'
      )
    );
    setShowDropdown(false);
  };

  const handleRemoveSubsection = async () => {
    const { [id]: targetSubsection, ...otherSubsections } = subsections;
    dispatch(setSubsections(otherSubsections));
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setItems((items) => {
        const activeIndex = items.findIndex(([id]) => id === active.id);
        const overIndex = items.findIndex(([id]) => id === over.id);

        return arrayMove(items, activeIndex, overIndex);
      });
    }
  };

  useEffect(() => {
    setItems(
      Object.entries(subsections).filter(
        ([subsectionId, subsection]) =>
          subsectionId !== sectionId &&
          subsectionId !== 0 &&
          subsectionId !== '0'
      )
    );
  }, [sectionId, subsections]);

  return (
    <div className="d-flex align-items-center gap-3">
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
        align={'end'}
      >
        <Dropdown.Toggle
          as={DropdownCustomToggle}
          id="dropdown-custom-components"
        >
          <div
            className="text-maroon fw-semibold"
            onClick={handleResetSubsectionOrder}
          >
            <FontAwesomeIcon icon={faBarsStaggered} className="me-1" />
            Reorder
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ width: '345px' }}>
          <Dropdown.Header className="text-gray-7 text-wrap">
            Drag and drop or use the arrows to reorder and move subsections
          </Dropdown.Header>
          <Dropdown.ItemText>
            <div className="position-relative">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                <SortableContext
                  items={items.map(([subsectionId]) => subsectionId)}
                  strategy={verticalListSortingStrategy}
                >
                  {items.map(([subsectionId, subsection], index, array) => (
                    <Sortable key={subsectionId} id={subsectionId}>
                      <DraggableSubsection
                        id={subsectionId}
                        subsection={subsection}
                        index={index}
                        array={array}
                        setItems={setItems}
                      />
                    </Sortable>
                  ))}
                </SortableContext>
              </DndContext>
            </div>
          </Dropdown.ItemText>
          <Dropdown.ItemText>
            <div className="d-flex gap-1">
              <Button
                label="Apply changes"
                color="maroon"
                size="small"
                onClick={handleReorderSubsections}
              />
              <Button
                label="Cancel"
                color="gray"
                size="small"
                onClick={handleResetSubsectionOrder}
              />
            </div>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className="text-maroon fw-semibold"
        onClick={handleRemoveSubsection}
        role="button"
      >
        <FontAwesomeIcon icon={faTrash} className="me-1" />
        Remove
      </div>
    </div>
  );
};

export default ComponentOptions;
