import React, { forwardRef } from 'react';

const DropdownCustomToggle = forwardRef(({ children, onClick }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
    role="button"
  >
    {children}
  </div>
));

export default DropdownCustomToggle;
