import React from 'react';
import { useLocation } from 'react-router-dom';
import useServiceAuth from 'auth/useServiceAuth';

const Login = () => {
  const query = new URLSearchParams(useLocation().search);
  const message = query.get('message');
  const { redirectToServiceauth } = useServiceAuth();

  return (
    <div className="container">
      <div className="my-4">
        <div className="d-flex flex-row justify-content-center">
          <h4>{message}</h4>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <h6>Sign in using ASU SSO</h6>
        </div>
        <div className="d-flex flex-row justify-content-center">
          <button className="btn btn-gold" onClick={redirectToServiceauth}>
            Sign in
          </button>
        </div>
      </div>
    </div>
  );
};

export default Login;
