import React, { useCallback, useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
// import { useDispatch, useSelector } from 'react-redux';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faMagnifyingGlass,
  // faGripVertical,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
// import OutsideClickHandler from 'react-outside-click-handler';
import { subjectGetAll } from 'apis/classAPIs';
import { classNames } from 'utils';
import Course from './RequirementCourse';
import CourseList from './RequirementCourseList';
import Text from './RequirementText';
import GeneralStudy from './RequirementGeneralStudy';
import Recommended from './RequirementRecommended';
import Check from './RequirementCheck';

//pass the createWrappedData function to the child, pass dataFromParent to child, call back the dataToParent to parent
// modal=true, display modal, otherwise display as text
const Elective = ({
  index,
  data,
  handleInputChange,
  handleDeleteComponent,
  showCourseListOption,
  isCourseListItem = false,
  handleShowAsCourseList = null,
}) => {
  const [electiveType, setElectiveType] = useState(
    data?.subject ? 'subject' : 'general'
  );
  const [showSubjectDropdown, setShowSubjectDropdown] = useState(false);
  const [showRecommended, setShowRecommended] = useState(
    !!data.recommended?.courses?.length
  );

  const { data: getSubjectsData, isSuccess: getSubjectsIsSuccess } = useQuery({
    queryKey: ['subjects'],
    queryFn: async () => {
      return subjectGetAll({ term: '2251' });
    },
  });

  const handleConnectorChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'connector', value);
  };

  const handleRequirementTypeChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'subject', '');
    handleInputChange(index, 'division', '');
    handleInputChange(index, 'itemType', value);
  };

  const handleElectiveTypeChange = (e) => {
    const { value } = e.target;

    setElectiveType(value);

    if (value === 'general') {
      handleInputChange(index, 'subject', '');
    }
  };

  const handleSubjectInputChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'subject', value.toUpperCase());
    setShowSubjectDropdown(true);
  };

  const handleDivisionChange = (e) => {
    const { value } = e.target;

    handleInputChange(index, 'division', value);
  };

  const handleSelectCourseSubject = (subject) => {
    setShowSubjectDropdown(false);
    handleInputChange(index, 'subject', subject.CODE);
  };

  const handleAddRecommendedCourse = () => {
    setShowRecommended(true);
  };

  const handleRecommendedCoursesChange = useCallback(
    (data) => {
      handleInputChange(index, 'recommended', data);
    },
    [handleInputChange, index]
  );

  useEffect(() => {
    let filteredSubjects;

    if (data.subject && getSubjectsIsSuccess)
      filteredSubjects = getSubjectsData.filter((subject) =>
        subject.CODE.includes(data.subject.toUpperCase())
      );

    if (!data.subject || !filteredSubjects?.length)
      setShowSubjectDropdown(false);
  }, [data.subject, getSubjectsData, getSubjectsIsSuccess]);

  return (
    <>
      {data.itemType === 'req_elective' || !data.itemType ? (
        <>
          {isCourseListItem && data.connector && (
            <div style={{ width: '80px' }} className="mx-5 pb-2">
              <select
                className="text-gray-7 p-1 w-100"
                value={data.connector ?? ''}
                name="connector"
                onChange={(e) => handleConnectorChange(e)}
                disabled={
                  index === 0 &&
                  (!data.connector || data.connector.trim() === '')
                }
              >
                {StaticData.connectors.map((option) => (
                  <option
                    key={option.value}
                    value={option.value}
                    className={option.value}
                  >
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          )}
          <div className="d-flex gap-2 pb-2">
            {/* Remove until reordering is finished */}
            {/* {!isCourseListItem && (
              <FontAwesomeIcon
                icon={faGripVertical}
                style={{ width: '15px' }}
                className="pt-4"
              />
            )} */}
            <div className="mt-4 me-1">
              <FontAwesomeIcon
                icon={faTrash}
                onClick={() => handleDeleteComponent(index)}
                className={`deleteButton ${showCourseListOption ? 'fa-trash-active' : ''}`}
              />
            </div>

            {!isCourseListItem && (
              <div style={{ width: '132px' }}>
                <div className="fw-bold text-nowrap">Logical operator</div>
                <select
                  className="text-gray-7 p-1 w-100"
                  value={data.connector ?? ''}
                  name="connector"
                  onChange={(e) => handleConnectorChange(e)}
                  disabled={
                    index === 0 &&
                    (!data.connector || data.connector.trim() === '')
                  }
                >
                  {StaticData.connectors.map((option) => (
                    <option
                      key={option.value}
                      value={option.value}
                      className={option.value}
                    >
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            )}
            <div>
              <div className="fw-bold">Requirement type</div>
              <div>
                <select
                  style={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                  value={'req_elective'}
                  onChange={(e) => handleRequirementTypeChange(e)}
                >
                  {showCourseListOption
                    ? StaticData.reqList.map((option) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))
                    : isCourseListItem
                      ? StaticData.courseListReqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))
                      : StaticData.templateReqList.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                </select>
              </div>
            </div>
            <div>
              <div className="fw-bold">Elective type</div>
              <div>
                <select
                  style={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                  value={electiveType}
                  // onChange={(e) => modalSetAsRequirement(e.target.value)}
                  onChange={(e) => handleElectiveTypeChange(e)}
                >
                  {StaticData.electiveType.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            {electiveType === 'subject' && (
              <div>
                <div className="fw-bold">Subject</div>
                {/* <OutsideClickHandler
                  onOutsideClick={() => setShowSubjectDropdown(false)}
                > */}
                <div className="position-relative">
                  <input
                    type="text"
                    name="subject"
                    value={data.subject ?? ''}
                    placeholder="Subject"
                    onChange={(e) => handleSubjectInputChange(e)}
                    onBlur={() =>
                      setTimeout(() => setShowSubjectDropdown(false), 200)
                    }
                    className="p-1"
                    style={{ width: '110px' }}
                    autoComplete="off"
                    maxLength={3}
                  />
                  {data.subject?.length === 0 && (
                    <FontAwesomeIcon
                      icon={faMagnifyingGlass}
                      style={{
                        position: 'absolute',
                        color: 'gray',
                        left: '70px',
                        top: '50%',
                        transform: 'translateY(-50%)',
                      }}
                    />
                  )}
                  {showSubjectDropdown && (
                    <div
                      className="position-absolute border border-gray-5 overflow-y-auto"
                      style={{
                        maxHeight: '170px',
                        width: '350px',
                        zIndex: 1,
                      }}
                    >
                      {getSubjectsIsSuccess &&
                        getSubjectsData
                          .filter((subject) =>
                            subject.CODE.includes(data.subject.toUpperCase())
                          )
                          .map((subject, index, array) => (
                            <div
                              key={subject.CODE}
                              className={classNames(
                                index !== array.length - 1 && 'border-bottom',
                                'border-gray-5 p-2 bg-white d-flex gap-2'
                              )}
                              onClick={() => handleSelectCourseSubject(subject)}
                              role="button"
                            >
                              <div className="fs-6 fw-bold">{subject.CODE}</div>
                              <div>{subject.DESCR}</div>
                            </div>
                          ))}
                    </div>
                  )}
                </div>
                {/* </OutsideClickHandler> */}
              </div>
            )}
            <div className="d-flex gap-3">
              <div>
                <div className="fw-bold">Level (optional)</div>
                <select
                  style={{
                    width: '178px',
                  }}
                  className={classNames(
                    data.division === '' ? 'text-gray-5' : 'text-gray-7',
                    'p-1'
                  )}
                  value={data.division ?? ''}
                  onChange={(e) => handleDivisionChange(e)}
                >
                  <option value="" disabled>
                    Level
                  </option>
                  {StaticData.divisionList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
              <div className="d-flex align-items-end mb-1">
                <div
                  className="d-flex gap-1 align-items-center button-link"
                  onClick={() => handleAddRecommendedCourse()}
                  role="button"
                >
                  <FontAwesomeIcon icon={faPlus} />
                  <div>Recommended course</div>
                </div>
              </div>
            </div>
          </div>
          {(data.recommended?.courses?.length > 0 || showRecommended) && (
            <div className="d-flex gap-2">
              <Recommended
                recommended={data.recommended}
                setRecommended={handleRecommendedCoursesChange}
              />
            </div>
          )}
        </>
      ) : data.itemType === 'req_course' ? (
        <Course
          index={index}
          data={data}
          showCourseListOption={showCourseListOption}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
        />
      ) : data.itemType === 'req_course_list' ? (
        <CourseList
          index={index}
          data={data}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
          handleShowAsCourseList={handleShowAsCourseList}
        />
      ) : data.itemType === 'req_gs' ? (
        <GeneralStudy
          index={index}
          data={data}
          showCourseListOption={showCourseListOption}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
        />
      ) : data.itemType === 'req_text' ? (
        <Text
          index={index}
          data={data}
          showCourseListOption={showCourseListOption}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
        />
      ) : data.itemType === 'req_check' ? (
        <Check
          index={index}
          data={data}
          showCourseListOption={showCourseListOption}
          handleInputChange={handleInputChange}
          handleDeleteComponent={handleDeleteComponent}
        />
      ) : (
        <div>{/* Handle other cases if needed */}</div>
      )}
    </>
  );
};

export default Elective;
