// React
import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
  useLocation,
} from 'react-router-dom';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import {
  setRolloverStatus,
  setPolling,
  setIsInitialRun as setGlobalInitial,
  setError,
} from 'state/slices/rolloverSlice';
import { ToastContainer } from 'react-bootstrap';

// pages
import Login from 'pages/Login';
import PATHome from 'pages/PATHome';
import Component from 'pages/Component';
import Components from 'pages/Components';
import Template from 'pages/Template';
import Templates from 'pages/Templates';
import Programs from 'pages/Programs';
import Users from 'pages/Users';
import Checksheet from 'pages/Checksheet';
import CourseList from 'pages/CourseList';
import Settings from 'pages/Settings';
import RoutesWrapper from 'components/RoutesWrapper';
// import NavigationSidebar from 'components/NavigationSidebar';
import TemplateSidebar from 'components/TemplateSidebar';
// import ChecksheetValidation from 'components/ChecksheetValidation';
import UserRoute from 'components/UserRoute';
import SubplanActivation from 'pages/SubplanActivation';
// import PATHeader from 'components/PATHeader';
import ScrollToTop from 'components/ScrollToTop';
import Header from 'components/Header';
// import Footer from "components/Footer";
import RolloverToast from 'components/RolloverToast';

// authorization
import ServiceAuthRoute from 'auth/ServiceAuthRoute';
import ServiceAuthProvider from 'auth/ServiceAuthProvider';
import useServiceAuth from 'auth/useServiceAuth';

// style sheets
import './App.css';
import Rollover from 'pages/Rollover';
import RolloverSuccess from 'pages/RolloverSuccess';
import { checksheetCopyAllProgress } from 'apis/checksheetAPIs';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
  },
});

const RolloverProgressWrapper = ({ children }) => {
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const [rolloverFinished, setRolloverFinished] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [isInitialRun, setIsInitialRun] = useState(true);

  const isPolling = useSelector((state) => state.rollover.isPolling);
  const initiator = useSelector((state) => state.rollover.initiator);
  const asurite = useSelector((state) => state.user.asurite);

  const { data, isError, refetch } = useQuery({
    queryKey: ['checksheetCopyAllProgress'],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetCopyAllProgress({ token });
    },
    refetchInterval: ({ state }) => {
      console.log('Polling: ', isPolling);
      console.log('Current status: ', state.data);
      if ((!isPolling && state.data?.currentStatus === 'DONE') || !state.data) {
        console.log('Ending fetch');
        return false;
      }

      if (state.data?.currentStatus === 'IN PROGRESS') {
        console.log(state.data);
        setIsInitialRun(false);
      }

      console.log('Continuing fetch');
      return 10000;
    },
    enabled: isAuthenticated && isPolling,
  });

  useEffect(() => {
    if (isAuthenticated && isPolling) {
      console.log('Refetching');
      setRolloverFinished(false);
      setShowAlert(true);
      refetch();
    }
  }, [isAuthenticated, isPolling, refetch]);

  useEffect(() => {
    if (data) {
      dispatch(setRolloverStatus(data.currentStatus));

      if (data.currentStatus === 'DONE') {
        console.log('Setting polling to false');
        setRolloverFinished(true);
        dispatch(setPolling(false));
      }
    }
  }, [data, dispatch]);

  useEffect(() => {
    dispatch(setGlobalInitial(isInitialRun));
  }, [dispatch, isInitialRun]);

  useEffect(() => {
    if (isError) dispatch(setError(true));
  }, [dispatch, isError]);

  return (
    <>
      {children}
      {!isInitialRun &&
        showAlert &&
        asurite === initiator &&
        location.pathname !== '/rollover' &&
        location.pathname !== '/rollover-success' &&
        (rolloverFinished || data?.currentStatus === 'IN PROGRESS') && (
          <ToastContainer
            className="p-3"
            containerPosition="fixed"
            position={'bottom-end'}
            style={{ zIndex: 1 }}
          >
            <RolloverToast
              finished={rolloverFinished}
              handleClose={() => setShowAlert(false)}
            />
          </ToastContainer>
        )}
    </>
  );
};

const NavigationSidebarLayout = () => {
  return (
    <>
      {/* <NavigationSidebar /> */}
      <div
        className="container-xl"
        // style={{ maxWidth: '1500px' }}
      >
        <Outlet />
      </div>
    </>
  );
};

const ComponentLibraryLayout = () => {
  return (
    <>
      <TemplateSidebar />
      <div className="d-flex justify-content-end">
        <div className="bg-white" style={{ width: '80%' }}>
          <Outlet />
        </div>
      </div>
    </>
  );
};

// const ChecksheetValidationLayout = () => {
//   return (
//     <>
//       <ChecksheetValidation />
//       <div className="d-flex justify-content-end">
//         <div className="bg-white" style={{ width: '80%' }}>
//           <Outlet />
//         </div>
//       </div>
//     </>
//   );
// };

function App() {
  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Router>
          {/* Not sure this is the best way for scrolling to top but it works for now*/}
          <ScrollToTop />
          <ServiceAuthProvider>
            <RolloverProgressWrapper>
              <Header />
              <RoutesWrapper>
                {/* <PATHeader /> */}
                <Routes>
                  <Route
                    element={
                      <ServiceAuthRoute>
                        <NavigationSidebarLayout />
                      </ServiceAuthRoute>
                    }
                  >
                    <Route path="/" element={<PATHome />} />
                    <Route path="/programs" element={<Programs />} />
                    <Route
                      path="/templates"
                      element={
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Templates />
                        </UserRoute>
                      }
                    />
                  </Route>
                  <Route element={<ComponentLibraryLayout />}>
                    <Route
                      path="/template"
                      element={
                        <ServiceAuthRoute>
                          <UserRoute
                            allowProvost={true}
                            allowCollege={false}
                            allowDepartment={false}
                            allowDars={false}
                          >
                            <Template />
                          </UserRoute>
                        </ServiceAuthRoute>
                      }
                    />
                  </Route>
                  <Route
                    path="/courselist"
                    element={
                      <ServiceAuthRoute>
                        <CourseList />
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/component"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Component />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/components"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Components />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/users"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Users />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/subplanactivation"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <SubplanActivation />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/settings"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Settings />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/rollover"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <Rollover />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  <Route
                    path="/rollover-success"
                    element={
                      <ServiceAuthRoute>
                        <UserRoute
                          allowProvost={true}
                          allowCollege={false}
                          allowDepartment={false}
                          allowDars={false}
                        >
                          <RolloverSuccess />
                        </UserRoute>
                      </ServiceAuthRoute>
                    }
                  />
                  {/* <Route element={<ChecksheetValidationLayout />}> */}
                  <Route
                    path="/checksheet"
                    element={
                      <ServiceAuthRoute>
                        <Checksheet />
                      </ServiceAuthRoute>
                    }
                  />
                  {/* </Route> */}
                  <Route path="/login" element={<Login />} />
                </Routes>
              </RoutesWrapper>
              {/* <Footer /> */}
            </RolloverProgressWrapper>
          </ServiceAuthProvider>
        </Router>
      </QueryClientProvider>
    </div>
  );
}

export default App;
