import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  fetchDplMinorsAndCertificates,
  fetchDplUndergradPrograms,
} from 'apis/dplAPIs';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCertificateList,
  setMinorList,
  setUndergradDegreeList,
} from 'state/slices/dataListsSlice';
import {
  faCalendar,
  faCircle,
  faCircleInfo,
  faFilter,
  faSearch,
  faSort,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ProgramRow from './ProgramRow';
import { setChecksheetList } from 'state/slices/dataListsSlice';
import { setYear } from 'state/slices/settingsSlice';
import {
  checksheetDelete,
  checksheetGetAllByYear,
  checksheetGetAllByYearAndCollege,
  checksheetUpdateProgress,
} from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import StaticData from './StaticData';
import TabPanel from './TabPanel';
import { Button } from '@asu/components-core';
import { Dropdown, Modal } from 'react-bootstrap';
import DropdownCustomToggle from './DropdownCustomToggle';
import LoadingSkeleton from './LoadingSkeleton';
import StatusDefinitions from './StatusDefinitions';
import { subplanActivationGetAll } from 'apis/adminAPIs';
import { Link } from 'react-router-dom';
import { classNames } from 'utils';

const programTypes = {
  undergrad: 0,
  minor: 1,
  cert: 2,
};

const statusCircle = {
  DEPARTMENT: {
    color: 'text-gray-4',
  },
  COLLEGE: {
    color: 'text-gray-7',
  },
  PROVOST: {
    color: 'text-warning',
  },
  DARS: {
    color: 'text-info',
  },
  COMPLETE: {
    color: 'text-success',
  },
};

const ProgramRows = ({
  degreeType,
  collegeCode = null,
  collegeName = null,
  programList,
  fetchPrograms,
  checksheetList,
  refetchChecksheets,
  getChecksheetIsLoading,
  setError,
  handleOpenDefinitions,
  user,
  year,
}) => {
  const dispatch = useDispatch();
  const { isAuthenticated, getAccessToken } = useServiceAuth();

  const [searchString, setSearchString] = useState('');
  // const [colleges, setColleges] = useState([]);
  const [departments, setDepartments] = useState([]);
  const [locationFilters, setLocationFilters] = useState([]);
  // const [collegeFilters, setCollegeFilters] = useState([]);
  const [departmentFilters, setDepartmentFilters] = useState([]);
  const [statusFilters, setStatusFilters] = useState([]);
  const [locationLabel, setLocationLabel] = useState('Location');
  // const [collegeLabel, setCollegeLabel] = useState('College/school');
  const [departmentLabel, setDepartmentLabel] = useState('Department');
  const [statusLabel, setStatusLabel] = useState('Status');
  const [totalProgramsShown, setTotalProgramsShown] = useState(0);
  const [sortBy, setSortBy] = useState(() => sortByNameAToZ);
  const [selectedChecksheets, setSelectedChecksheets] = useState([]);
  const [allChecksheetsSelected, setAllChecksheetsSelected] = useState(false);
  const [programChecksheetList, setProgramChecksheetList] = useState([]);
  const [showDiv, setShowDiv] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const {
    data: fetchSubplanActivationListData,
    error: fetchSubplanActivationListError,
    isError: fetchSubplanActivationListIsError,
    isSuccess: fetchSubplanActivationListIsSuccess,
    // isPending: fetchSubplanActivationListIsPending,
    // fetchStatus: fetchSubplanActivationListFetchStatus,
  } = useQuery({
    queryKey: ['subplanActivationList', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return subplanActivationGetAll({ year: year, token });
    },
    enabled: isAuthenticated,
  });

  const {
    mutate: updateChecksheetProgress,
    // error: updateChecksheetProgressError,
  } = useMutation({
    mutationFn: checksheetUpdateProgress,
    onSuccess: async (data, { token }) => {
      refetchChecksheets({ year, token });
      console.log('updateChecksheetProgress success');
    },
  });

  const { mutate: deleteChecksheets } = useMutation({
    mutationFn: async ({ token }) => {
      const total = selectedChecksheets.length;
      const results = [];

      for (let i = 0; i < total; i++) {
        const result = await checksheetDelete({
          id: selectedChecksheets[i],
          token,
        });
        results.push(result);
        // You can even report progress if needed
        // setProgress((i + 1) / total * 100);
      }
      return results;
    },
    onSuccess: () => {
      setSelectedChecksheets([]);
      refetchChecksheets();
      setShowModal(() => false);
    },
    onError: () => {
      refetchChecksheets();
      setShowModal(() => false);
    },
  });

  const handleClearChecksheets = async () => {
    const confirmDelete = window.confirm(
      `Are you sure you want to clear the checksheet for ${selectedChecksheets.length} programs?`
    );

    if (confirmDelete) {
      const token = await getAccessToken();

      setShowModal(() => true);
      await deleteChecksheets({ token });
    }
  };

  const handleSelectChecksheet = (checksheetId) => {
    if (!selectedChecksheets.includes(checksheetId))
      setSelectedChecksheets((prev) => [...prev, checksheetId]);
    else
      setSelectedChecksheets((prev) => prev.filter((p) => p !== checksheetId));
  };

  const handleSelectAllChecksheets = () => {
    if (selectedChecksheets.length === programChecksheetList.length) {
      setSelectedChecksheets([]);
      setAllChecksheetsSelected(false);
    } else {
      console.log(programChecksheetList);
      setSelectedChecksheets((clist) =>
        programChecksheetList.map((checksheet) => checksheet.id)
      );
      setAllChecksheetsSelected(true);
    }
  };

  const handleSetProgress = async (progress) => {
    const jsonData = {
      ids: [...selectedChecksheets],
      progress,
      action: 'changed',
    };
    const token = await getAccessToken();

    await updateChecksheetProgress({
      jsonData: jsonData,
      token: token,
    });
  };

  function sortByNameAToZ(a, b) {
    if (a.acadPlanDescription < b.acadPlanDescription) {
      return -1;
    }

    if (a.acadPlanDescription > b.acadPlanDescription) {
      return 1;
    }

    if (a.degreeDescriptionShort < b.degreeDescriptionShort) {
      return -1;
    }

    if (a.degreeDescriptionShort > b.degreeDescriptionShort) {
      return 1;
    }

    if (!!a.subplan && !!b.subplan) {
      if (a.subplan.description < b.subplan.description) {
        return -1;
      }

      if (a.subplan.description > b.subplan.description) {
        return 1;
      }
    }

    return 0;
  }

  function sortByNameZToA(a, b) {
    if (a.acadPlanDescription < b.acadPlanDescription) {
      return 1;
    }

    if (a.acadPlanDescription > b.acadPlanDescription) {
      return -1;
    }

    if (a.degreeDescriptionShort < b.degreeDescriptionShort) {
      return 1;
    }

    if (a.degreeDescriptionShort > b.degreeDescriptionShort) {
      return -1;
    }

    if (!!a.subplan && !!b.subplan) {
      if (a.subplan.description < b.subplan.description) {
        return 1;
      }

      if (a.subplan.description > b.subplan.description) {
        return -1;
      }
    }

    return 0;
  }

  const handleSortBy = (e) => {
    const value = e.target.value;
    setSortBy(
      value === 'nameZ-A' ? () => sortByNameZToA : () => sortByNameAToZ
    ); // Currently only sorts by name, need to know other options
  };

  const handleSearch = (e) => {
    setSearchString(e.target.value);
  };

  const filterByLocation = (program) => {
    if (locationFilters.length === 0) return true;

    let included = false;
    if (program.campusesOffered) {
      program.campusesOffered.forEach((campus) => {
        if (locationFilters.includes(campus.campusCode)) included = true;
      });
    }

    return included;
  };

  const handleFilterLocation = (e) => {
    const value = e.target.value;

    const currentLocations = [...locationFilters];

    if (locationFilters.includes(value)) {
      currentLocations.splice(locationFilters.indexOf(value), 1);
    } else {
      currentLocations.push(value);
    }

    if (currentLocations.length === 0) setLocationLabel('Location');
    else if (currentLocations.length === 1) {
      const label = StaticData.campusUndergradList.find(
        (campus) => campus.value === currentLocations[0]
      ).label;
      setLocationLabel(label);
    } else if (currentLocations.length > 1)
      setLocationLabel(`Location (${currentLocations.length})`);

    setLocationFilters(currentLocations);
  };

  const handleUncheckAllLocations = () => {
    setLocationLabel('Location');
    setLocationFilters([]);
  };

  const filterByCollege = (program) => {
    let included = false;

    let mainOwner = {};
    if (program.owners.length > 0) {
      let highestPercent = 0;
      program.owners.forEach((programOwner) => {
        if (programOwner.percentOwned > highestPercent) {
          highestPercent = programOwner.percentOwned;
          mainOwner = programOwner;
        }
      });
    }

    if (!collegeCode || collegeCode === mainOwner.collegeAcadOrg)
      included = true;

    // if (collegeFilters.length === 0) return true;

    // if (program.owners) {
    //   program.owners.forEach((college) => {
    //     if (collegeFilters.includes(college.collegeAcadOrg)) included = true;
    //   });
    // }

    return included;
  };

  // const handleFilterCollege = (e) => {
  //   const value = e.target.value;

  //   const currentColleges = [...collegeFilters];

  //   if (collegeFilters.includes(value)) {
  //     currentColleges.splice(collegeFilters.indexOf(value), 1);
  //   } else {
  //     currentColleges.push(value);
  //   }

  //   if (currentColleges.length === 0) setCollegeLabel('College/school');
  //   else if (currentColleges.length === 1) {
  //     const label = colleges.find(
  //       (college) => college[0] === currentColleges[0]
  //     )[1];
  //     setCollegeLabel(label);
  //   } else if (currentColleges.length > 1)
  //     setCollegeLabel(`College/school (${currentColleges.length})`);

  //   setCollegeFilters(currentColleges);
  // };

  // const handleUncheckAllColleges = () => {
  //   setCollegeLabel('College/school');
  //   setCollegeFilters([]);
  // };

  // const sortByCollege = (a, b) => {
  //   if (a[1] > b[1]) return 1;
  //   if (a[1] < b[1]) return -1;
  //   return 0;
  // };

  const filterByDepartment = (program) => {
    let included = false;

    // if (user.role.includes('DEPARTMENT')) {
    //   program.owners.forEach((college) => {
    //     if (user.department === college.departmentAcadOrg) included = true;
    //   });

    //   return included;
    // }

    if (departmentFilters.length === 0) return true;

    if (program.owners) {
      program.owners.forEach((college) => {
        if (departmentFilters.includes(college.departmentAcadOrg))
          included = true;
      });
    }

    return included;
  };

  const handleFilterDepartment = (e) => {
    const value = e.target.value;

    const currentDepartments = [...departmentFilters];

    if (departmentFilters.includes(value)) {
      currentDepartments.splice(departmentFilters.indexOf(value), 1);
    } else {
      currentDepartments.push(value);
    }

    if (currentDepartments.length === 0) setDepartmentLabel('Department');
    else if (currentDepartments.length === 1) {
      const label = departments.find(
        (department) => department[0] === currentDepartments[0]
      )[1];
      setDepartmentLabel(label);
    } else if (currentDepartments.length > 1)
      setDepartmentLabel(`Department (${currentDepartments.length})`);

    setDepartmentFilters(currentDepartments);
  };

  const handleUncheckAllDepartments = () => {
    setDepartmentLabel('Department');
    setDepartmentFilters([]);
  };

  const sortByDepartment = (a, b) => {
    if (a[1] > b[1]) return 1;
    if (a[1] < b[1]) return -1;
    return 0;
  };

  const filterByStatus = (program) => {
    if (statusFilters.length === 0) return true;

    const checksheetFound = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    let included = false;

    if (
      (checksheetFound?.status &&
        statusFilters.includes(checksheetFound.status)) ||
      (!checksheetFound && statusFilters.includes('NONE'))
    )
      included = true;

    if (fetchSubplanActivationListData?.[program.acadPlanCode]) {
      Object.entries(
        fetchSubplanActivationListData[program.acadPlanCode]
      ).forEach(([subplanCode]) => {
        const subplanChecksheetFound = checksheetList.find(
          (checksheet) =>
            checksheet.id.split('*')[2] === program.acadPlanCode &&
            checksheet.id.split('*')[3] === subplanCode
        );

        if (
          (subplanChecksheetFound?.status &&
            statusFilters.includes(subplanChecksheetFound.status)) ||
          (!subplanChecksheetFound && statusFilters.includes('NONE'))
        )
          included = true;
      });
    }

    return included;
  };

  const filterByStatusForTotal = (program) => {
    if (statusFilters.length === 0) return true;

    const checksheetFound = checksheetList.find(
      (checksheet) =>
        checksheet.id.split('*')[2] === program.acadPlanCode &&
        (!!program.subplan
          ? checksheet.id.split('*')[3] === program.subplan.acadSubPlanCode
          : checksheet.id.split('*')[3] === 'null')
    );

    let included = false;

    if (
      (checksheetFound?.status &&
        statusFilters.includes(checksheetFound.status)) ||
      (!checksheetFound && statusFilters.includes('NONE'))
    )
      included = true;

    return included;
  };

  const handleFilterStatus = (e) => {
    const value = e.target.value;

    const currentStatuses = [...statusFilters];

    if (statusFilters.includes(value)) {
      currentStatuses.splice(statusFilters.indexOf(value), 1);
    } else {
      currentStatuses.push(value);
    }

    if (currentStatuses.length === 0) setStatusLabel('Status');
    else if (currentStatuses.length === 1) {
      let label = null;
      if (currentStatuses[0] !== 'NONE') {
        label = StaticData.statusList.find(
          (status) => status.value === currentStatuses[0]
        ).label;
      } else label = 'No checksheet exists';
      setStatusLabel(label);
    } else if (currentStatuses.length > 1)
      setStatusLabel(`Status (${currentStatuses.length})`);

    setStatusFilters(currentStatuses);
  };

  const handleUncheckAllStatuses = () => {
    setStatusLabel('Status');
    setStatusFilters([]);
  };

  const handleClearAllFilters = () => {
    setLocationLabel('Location');
    setLocationFilters([]);
    // setCollegeLabel('College/school');
    // setCollegeFilters([]);
    setDepartmentLabel('Department');
    setDepartmentFilters([]);
    setStatusLabel('Status');
    setStatusFilters([]);
  };

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };
  let frameId = useRef(null);

  const handleScroll = useCallback(() => {
    frameId.current = requestAnimationFrame(() => {
      const scrollPosition = window.scrollY;
      setShowDiv(scrollPosition > 185);
    });
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      if (frameId.current) cancelAnimationFrame(frameId.current);
    };
  }, [handleScroll]);

  useEffect(() => {
    if (fetchPrograms.error) setError(fetchPrograms.error.message);
    if (fetchPrograms.isSuccess && fetchPrograms.fetchStatus === 'idle') {
      const next = [];

      let programsData = [];

      if (degreeType === 'undergrad') programsData = fetchPrograms.data;
      else if (degreeType === 'minor')
        programsData = fetchPrograms.data.filter(
          (program) => program.acadPlanType === 'MIN'
        );
      else if (degreeType === 'cert')
        programsData = fetchPrograms.data.filter(
          (program) =>
            program.acadPlanType === 'CER' || program.acadPlanType === 'SAC'
        );

      programsData.forEach((program) => {
        const obj = {
          acadPlanCode: program.acadPlanCode,
          acadPlanDescription: program.acadPlanMarketingDescription,
          degreeDescriptionShort: program.degreeDescriptionShort,
          campusesOffered: program.campusesOffered,
          acadPlanType: program.acadPlanType,
          owners: program.owners,
          subplan: null,
        };
        next.push(obj);

        if (fetchSubplanActivationListIsError)
          setError(fetchSubplanActivationListError.message);

        if (program.subplans && fetchSubplanActivationListIsSuccess)
          program.subplans.forEach((subplan) => {
            if (
              fetchSubplanActivationListData?.[program.acadPlanCode]?.[
                subplan.acadSubPlanCode
              ]
            ) {
              const subplanObj = {
                acadPlanCode: program.acadPlanCode,
                acadPlanDescription: program.acadPlanDescription,
                degreeDescriptionShort: program.degreeDescriptionShort,
                acadPlanType: program.acadPlanType,
                campusesOffered: program.campusesOffered,
                owners: program.owners,
                subplan: subplan,
              };

              next.push(subplanObj);
            }
          });
      });

      if (degreeType === 'undergrad')
        dispatch(setUndergradDegreeList([...next]));
      else if (degreeType === 'minor') dispatch(setMinorList([...next]));
      else if (degreeType === 'cert') dispatch(setCertificateList([...next]));
    }
  }, [
    degreeType,
    dispatch,
    fetchPrograms.data,
    fetchPrograms.error,
    fetchPrograms.fetchStatus,
    fetchPrograms.isSuccess,
    fetchSubplanActivationListData,
    fetchSubplanActivationListError,
    fetchSubplanActivationListIsError,
    fetchSubplanActivationListIsSuccess,
    setError,
  ]);

  // useEffect(() => {
  //   const currentColleges = [...colleges];
  //   programList.forEach((program) => {
  //     program.owners.forEach((owner) => {
  //       if (
  //         !currentColleges.some(([key, value]) => key === owner.collegeAcadOrg)
  //       ) {
  //         currentColleges.push([
  //           owner.collegeAcadOrg,
  //           owner.collegeAlphaSortDescription,
  //         ]);
  //       }
  //     });
  //   });
  //   setColleges(currentColleges);
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [programList]);

  useEffect(() => {
    const currentDepartments = [];
    programList.forEach((program) => {
      program.owners.forEach((owner) => {
        if (
          !currentDepartments.some(
            ([key, value]) => key === owner.departmentAcadOrg
          ) &&
          (!collegeCode || collegeCode === owner.collegeAcadOrg)
        ) {
          currentDepartments.push([
            owner.departmentAcadOrg,
            owner.departmentDescription,
          ]);
        }
      });
    });

    setDepartments(currentDepartments);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [programList, collegeCode]);

  useEffect(() => {
    setTotalProgramsShown(
      programList
        .filter(filterByLocation)
        .filter(filterByCollege)
        .filter(filterByDepartment)
        .filter(filterByStatusForTotal)
        .filter(
          (program) =>
            `${program.acadPlanDescription}${degreeType === 'undergrad' ? ', ' + program.degreeDescriptionShort : ''}`
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            program.subplan?.description
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            program.acadPlanCode
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            program.subplan?.acadSubPlanCode
              .toLowerCase()
              .includes(searchString.toLowerCase())
        ).length
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    locationFilters,
    // collegeFilters,
    departmentFilters,
    statusFilters,
    searchString,
    programList,
    collegeCode,
  ]);

  useEffect(() => {
    if (
      selectedChecksheets.length === programChecksheetList.length &&
      programChecksheetList.length !== 0
    )
      setAllChecksheetsSelected(true);
    else setAllChecksheetsSelected(false);
  }, [programChecksheetList, selectedChecksheets]);

  useEffect(() => {
    let filteredChecksheetList = [];

    if (degreeType === 'undergrad') {
      filteredChecksheetList = checksheetList.filter(
        (checksheet) =>
          checksheet.programType !== 'MIN' && checksheet.programType !== 'CERT'
      );
    } else if (degreeType === 'minor') {
      filteredChecksheetList = checksheetList.filter(
        (checksheet) => checksheet.programType === 'MIN'
      );
    } else if (degreeType === 'cert') {
      filteredChecksheetList = checksheetList.filter(
        (checksheet) => checksheet.programType === 'CERT'
      );
    }

    setProgramChecksheetList(filteredChecksheetList);
  }, [checksheetList, degreeType]);

  if (fetchSubplanActivationListIsError)
    return (
      <div>
        <div>
          An error has occurred: {fetchSubplanActivationListError.message}
        </div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div className="pt-1 mt-2 position-relative">
        <div
          className={classNames(
            showDiv ? 'visible opacity-100' : 'invisible opacity-0',
            'position-fixed bg-white pt-4 program-list-header'
          )}
          style={{ width: '63%', top: '89px', zIndex: 1 }}
        >
          <div className="mb-3">
            <Link to={'/'} className="text-maroon">
              Home
            </Link>
            <span className="text-gray-5 mx-1">/</span>
            {collegeCode ? collegeName : 'All colleges/schools'}
          </div>
          <div className="d-flex justify-content-between mb-2">
            {!!selectedChecksheets.length ? (
              <div className="d-flex gap-3 align-items-center">
                <div className="fw-bold">
                  {selectedChecksheets.length} selected
                </div>
                <div
                  className="button-link"
                  onClick={() => setSelectedChecksheets([])}
                  role="button"
                >
                  Deselect all
                </div>
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label="Set status"
                      icon={['fa', 'chevron-down']}
                      color="maroon"
                    />
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="py-0" style={{ width: '248px' }}>
                    {StaticData.statusList.map((status) => (
                      <Dropdown.Item
                        key={status.value}
                        className="d-flex gap-1 px-3 py-2 text-wrap"
                        role="button"
                        onClick={() => handleSetProgress(status.value)}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          className={statusCircle[status.value].color}
                          width={'12px'}
                          style={{
                            marginTop: '4px',
                          }}
                        />
                        <div>{status.label}</div>
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Button
                  label="Clear checksheets"
                  color="maroon"
                  onClick={handleClearChecksheets}
                />
              </div>
            ) : (
              <div />
            )}
            {fetchPrograms.isPending ? (
              <LoadingSkeleton width={'140px'} height={'25px'} />
            ) : (
              <span>
                {totalProgramsShown} results{' '}
                {!!searchString && (
                  <span>
                    for <span className="fw-bold">{searchString}</span>
                  </span>
                )}
              </span>
            )}
          </div>
          <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
            <div className="col-auto" style={{ width: '5%' }}>
              {user.role.includes('PROVOST') && (
                <input
                  type="checkbox"
                  id="checkboxChecksheet-all"
                  data-ga-input="checkbox"
                  data-ga-input-name="onclick"
                  data-ga-input-event="select"
                  data-ga-input-action="click"
                  data-ga-input-region="main content"
                  data-ga-input-section="Select all checksheets"
                  checked={allChecksheetsSelected}
                  onChange={handleSelectAllChecksheets}
                  disabled={programChecksheetList?.length === 0}
                />
              )}
            </div>
            <div className="col-auto fw-bold ps-0" style={{ width: '23%' }}>
              Name
            </div>
            <div className="col-auto fw-bold" style={{ width: '12%' }}>
              Plan code
            </div>
            <div className="col-auto fw-bold" style={{ width: '15%' }}>
              Department
            </div>
            <div className="col-auto fw-bold" style={{ width: '17%' }}>
              Locations
            </div>
            <div className="col-auto fw-bold" style={{ width: '21%' }}>
              Status
              <FontAwesomeIcon
                icon={faCircleInfo}
                className="ms-1"
                onClick={handleOpenDefinitions}
                role="button"
              />
            </div>
            <div
              className="col-auto d-flex justify-content-end gap-1 align-items-center"
              style={{ width: '6%' }}
            />
          </div>
        </div>
        <div className="d-flex gap-6 justify-content-between mb-6">
          <div className="d-flex gap-3">
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faSearch} />
                <div className="fw-bold">Search</div>
              </div>
              <input
                type="text"
                placeholder={`Search ${
                  degreeType === 'undergrad'
                    ? 'undergraduate degrees'
                    : degreeType === 'minor'
                      ? 'minor programs'
                      : degreeType === 'cert'
                        ? 'certificate programs'
                        : 'programs'
                }`}
                className="border-0 border-bottom border-gray-6 bg-transparent px-3 py-1"
                style={{ width: '275px' }}
                onChange={(e) => handleSearch(e)}
              />
            </div>
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faFilter} />
                <div className="fw-bold">Filters</div>
              </div>
              <div className="filters d-flex flex-wrap gap-1 align-items-center">
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={locationLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        locationFilters.length > 0 ? 'btn-maroon' : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '340px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllLocations}
                    >
                      Uncheck all
                    </Dropdown.Item>
                    {StaticData.campusUndergradList.map((campus) => (
                      <Dropdown.ItemText
                        key={campus.value}
                        className="d-flex gap-2 ps-3"
                        role="button"
                      >
                        <input
                          type="checkbox"
                          id={'checkbox-' + campus.value}
                          data-ga-input="checkbox"
                          data-ga-input-name="onclick"
                          data-ga-input-event="select"
                          data-ga-input-action="click"
                          data-ga-input-region="main content"
                          data-ga-input-section={campus.value}
                          value={campus.value}
                          checked={locationFilters.includes(campus.value)}
                          onChange={(e) => handleFilterLocation(e)}
                        />
                        <span>{campus.label}</span>
                      </Dropdown.ItemText>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {/* {user.role.includes('PROVOST') && (
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={collegeLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        collegeFilters.length > 0 ? 'btn-maroon' : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '540px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllColleges}
                    >
                      Uncheck all
                    </Dropdown.Item>
                          {colleges
                            .toSorted(sortByCollege)
                            .map(([key, value]) => (
                              <Dropdown.ItemText key={key}
                                className="d-flex gap-2 ps-3"
                                role="button">
                                <input
                                  type="checkbox"
                                  id={'checkbox-' + key}
                                  data-ga-input="checkbox"
                                  data-ga-input-name="onclick"
                                  data-ga-input-event="select"
                                  data-ga-input-action="click"
                                  data-ga-input-region="main content"
                                  data-ga-input-section={key}
                                  value={key}
                                  checked={collegeFilters.includes(key)}
                                  onChange={(e) => handleFilterCollege(e)}
                                />
                                <span
                                >
                                  {value}
                                </span>
                              </Dropdown.ItemText>
                            ))}
                  </Dropdown.Menu>
                </Dropdown>
              )} */}
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={departmentLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        departmentFilters.length > 0
                          ? 'btn-maroon'
                          : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '540px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllDepartments}
                    >
                      Uncheck all
                    </Dropdown.Item>
                    {departments
                      .toSorted(sortByDepartment)
                      .map(([key, value]) => (
                        <Dropdown.ItemText
                          key={key}
                          className="d-flex gap-2 ps-3"
                          role="button"
                        >
                          <input
                            type="checkbox"
                            id={'checkbox-' + key}
                            data-ga-input="checkbox"
                            data-ga-input-name="onclick"
                            data-ga-input-event="select"
                            data-ga-input-action="click"
                            data-ga-input-region="main content"
                            data-ga-input-section={key}
                            value={key}
                            checked={departmentFilters.includes(key)}
                            onChange={(e) => handleFilterDepartment(e)}
                          />
                          <span>{value}</span>
                        </Dropdown.ItemText>
                      ))}
                  </Dropdown.Menu>
                </Dropdown>
                <Dropdown>
                  <Dropdown.Toggle
                    as={DropdownCustomToggle}
                    id="dropdown-custom-components"
                  >
                    <Button
                      label={statusLabel}
                      icon={['fa', 'chevron-down']}
                      classes={[
                        'btn',
                        statusFilters.length > 0 ? 'btn-maroon' : 'btn-white',
                        'btn-md',
                      ]}
                    />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="py-0" style={{ width: '340px' }}>
                    <Dropdown.Item
                      className="button-link px-3 mt-2"
                      role="button"
                      onClick={handleUncheckAllStatuses}
                    >
                      Uncheck all
                    </Dropdown.Item>
                    <Dropdown.ItemText
                      className="d-flex gap-2 ps-3"
                      role="button"
                    >
                      <input
                        type="checkbox"
                        id="checkbox-NONE"
                        data-ga-input="checkbox"
                        data-ga-input-name="onclick"
                        data-ga-input-event="select"
                        data-ga-input-action="click"
                        data-ga-input-region="main content"
                        data-ga-input-section="NONE"
                        value="NONE"
                        checked={statusFilters.includes('NONE')}
                        onChange={(e) => handleFilterStatus(e)}
                      />
                      <span>No checksheet exists</span>
                    </Dropdown.ItemText>
                    {StaticData.statusList.map((status) => (
                      <Dropdown.ItemText
                        key={status.value}
                        className="d-flex gap-2 ps-3"
                        role="button"
                      >
                        <input
                          type="checkbox"
                          id={'checkbox-' + status.value}
                          data-ga-input="checkbox"
                          data-ga-input-name="onclick"
                          data-ga-input-event="select"
                          data-ga-input-action="click"
                          data-ga-input-region="main content"
                          data-ga-input-section={status.value}
                          value={status.value}
                          checked={statusFilters.includes(status.value)}
                          onChange={(e) => handleFilterStatus(e)}
                        />
                        <span>{status.label}</span>
                      </Dropdown.ItemText>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
                {(locationFilters.length > 0 ||
                  // collegeFilters.length > 0 ||
                  departmentFilters.length > 0 ||
                  statusFilters.length > 0) && (
                  <div
                    className="button-link"
                    onClick={handleClearAllFilters}
                    role="button"
                  >
                    Clear all filters
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex gap-2">
            <div>
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faCalendar} />
                <div className="fw-bold text-nowrap">Catalog year</div>
              </div>
              <select value={year} onChange={(e) => handleSetYear(e)}>
                {StaticData.yearList.map((year) => (
                  <option key={year.value} value={year.value}>
                    {year.label}
                  </option>
                ))}
              </select>
            </div>
            <div>
              {/* Temporary, remove className when sortBy is fully implemented */}
              <div className="d-flex gap-1 align-items-center mb-1">
                <FontAwesomeIcon icon={faSort} />
                <div className="fw-bold">Sort by</div>
              </div>
              <select onChange={(e) => handleSortBy(e)}>
                <option value="nameA-Z">Name (A-Z)</option>
                <option value="nameZ-A">Name (Z-A)</option>
              </select>
            </div>
          </div>
        </div>

        <div className="d-flex justify-content-between mb-2">
          {!!selectedChecksheets.length ? (
            <div className="d-flex gap-3 align-items-center">
              <div className="fw-bold">
                {selectedChecksheets.length} selected
              </div>
              <div
                className="button-link"
                onClick={() => setSelectedChecksheets([])}
                role="button"
              >
                Deselect all
              </div>
              <Dropdown>
                <Dropdown.Toggle
                  as={DropdownCustomToggle}
                  id="dropdown-custom-components"
                >
                  <Button
                    label="Set status"
                    icon={['fa', 'chevron-down']}
                    color="maroon"
                  />
                </Dropdown.Toggle>
                <Dropdown.Menu className="py-0" style={{ width: '248px' }}>
                  {StaticData.statusList.map((status) => (
                    <Dropdown.Item
                      key={status.value}
                      className="d-flex gap-1 px-3 py-2 text-wrap"
                      role="button"
                      onClick={() => handleSetProgress(status.value)}
                    >
                      <FontAwesomeIcon
                        icon={faCircle}
                        className={statusCircle[status.value].color}
                        width={'12px'}
                        style={{
                          marginTop: '4px',
                        }}
                      />
                      <div>{status.label}</div>
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
              <Button
                label="Clear checksheets"
                color="maroon"
                onClick={handleClearChecksheets}
              />
            </div>
          ) : (
            <div />
          )}
          {fetchPrograms.isPending ? (
            <LoadingSkeleton width={'140px'} height={'25px'} />
          ) : (
            <span>
              {totalProgramsShown} results{' '}
              {!!searchString && (
                <span>
                  for <span className="fw-bold">{searchString}</span>
                </span>
              )}
            </span>
          )}
        </div>
        <div className="row border-bottom border-2 border-gray-7 py-1 mx-0">
          <div className="col-auto" style={{ width: '5%' }}>
            {user.role.includes('PROVOST') && (
              <input
                type="checkbox"
                id="checkboxChecksheet-all"
                data-ga-input="checkbox"
                data-ga-input-name="onclick"
                data-ga-input-event="select"
                data-ga-input-action="click"
                data-ga-input-region="main content"
                data-ga-input-section="Select all checksheets"
                checked={allChecksheetsSelected}
                onChange={handleSelectAllChecksheets}
                disabled={programChecksheetList?.length === 0}
              />
            )}
          </div>
          <div className="col-auto fw-bold ps-0" style={{ width: '23%' }}>
            Name
          </div>
          <div className="col-auto fw-bold" style={{ width: '12%' }}>
            Plan code
          </div>
          <div className="col-auto fw-bold" style={{ width: '15%' }}>
            Department
          </div>
          <div className="col-auto fw-bold" style={{ width: '17%' }}>
            Locations
          </div>
          <div className="col-auto fw-bold" style={{ width: '21%' }}>
            Status
            <FontAwesomeIcon
              icon={faCircleInfo}
              className="ms-1"
              onClick={handleOpenDefinitions}
              role="button"
            />
          </div>
          <div
            className="col-auto d-flex justify-content-end gap-1 align-items-center"
            style={{ width: '6%' }}
          />
        </div>
        {fetchPrograms.isPending && getChecksheetIsLoading ? (
          <div className="mt-1">
            {[...Array(8)].map((item, index) => (
              <LoadingSkeleton key={index} width={'100%'} height={'65px'} />
            ))}
          </div>
        ) : (
          fetchPrograms.isSuccess &&
          (totalProgramsShown > 0 ? (
            programList
              .filter(filterByLocation)
              .filter(filterByCollege)
              .filter(filterByDepartment)
              .filter(filterByStatus)
              .filter(
                (program) =>
                  `${program.acadPlanDescription}${degreeType === 'undergrad' ? ', ' + program.degreeDescriptionShort : ''}`
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                  program.subplan?.description
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                  program.acadPlanCode
                    .toLowerCase()
                    .includes(searchString.toLowerCase()) ||
                  program.subplan?.acadSubPlanCode
                    .toLowerCase()
                    .includes(searchString.toLowerCase())
              )
              .toSorted(sortBy)
              .map((program, index) => (
                <ProgramRow
                  key={
                    program.subplan
                      ? program.acadPlanCode +
                        '*' +
                        program.subplan.acadSubPlanCode
                      : program.acadPlanCode
                  }
                  program={program}
                  activatedSubplans={
                    fetchSubplanActivationListData?.[program.acadPlanCode]
                  }
                  programList={programList}
                  checksheetList={checksheetList}
                  filters={statusFilters}
                  index={index}
                  refetch={refetchChecksheets}
                  isLoading={getChecksheetIsLoading}
                  handleOpenDefinitions={handleOpenDefinitions}
                  selectedChecksheets={selectedChecksheets}
                  handleSelectChecksheet={handleSelectChecksheet}
                  user={user}
                />
              ))
          ) : (
            <div>
              No{' '}
              {degreeType === 'undergrad'
                ? 'undergraduate degrees'
                : degreeType === 'minor'
                  ? 'minor programs'
                  : degreeType === 'cert'
                    ? 'certificate programs'
                    : 'programs'}{' '}
              found
            </div>
          ))
        )}
      </div>
      <Modal show={showModal} className="pt-8">
        <div className="d-flex gap-2 align-items-center p-3">
          <FontAwesomeIcon icon={faSpinner} spin />
          <div className="text-nowrap">Deleting checksheets...</div>
        </div>
      </Modal>
    </>
  );
};

const ProgramList = ({ collegeCode, collegeName, programType }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [error, setError] = useState('');
  const [showDefinitions, setShowDefinitions] = useState(false);

  const year = useSelector((state) => state.settings.year);
  const undergradDegreeList = useSelector(
    (state) => state.dataLists.undergradDegreeList
  );
  const minorList = useSelector((state) => state.dataLists.minorList);
  const certificateList = useSelector(
    (state) => state.dataLists.certificateList
  );
  const checksheetList = useSelector((state) => state.dataLists.checksheetList);
  const role = useSelector((state) => state.user.role);
  const asurite = useSelector((state) => state.user.asurite);
  const userCollege = useSelector((state) => state.user.college);
  const userDepartment = useSelector((state) => state.user.department);

  const fetchUndergradDegrees = useQuery({
    queryKey: ['undergrad'],
    queryFn: fetchDplUndergradPrograms,
  });

  const fetchMinorsAndCertificates = useQuery({
    queryKey: ['minors'],
    queryFn: fetchDplMinorsAndCertificates,
  });

  const {
    data: checksheetData,
    error: getChecksheetError,
    isError: getChecksheetIsError,
    isSuccess: getChecksheetIsSuccess,
    isLoading: getChecksheetIsLoading,
    refetch: refetchChecksheets,
  } = useQuery({
    queryKey: ['checksheets', year, collegeCode],
    queryFn: async () => {
      const token = await getAccessToken();

      if (!!collegeCode)
        return checksheetGetAllByYearAndCollege({
          year,
          college: collegeCode,
          token,
        });
      else return checksheetGetAllByYear({ year, token });
    },
    enabled: !!year,
  });

  const handleOpenDefinitions = () => setShowDefinitions(true);
  const handleCloseDefinitions = () => setShowDefinitions(false);

  useEffect(() => {
    if (getChecksheetIsError) setError(getChecksheetError.message);

    if (getChecksheetIsSuccess) {
      const next = [];

      checksheetData.forEach((checksheet) => {
        const idYear = checksheet['sk'].S.split('*')[0];
        let lockedBy = {};

        if (!!checksheet['lockedBy']?.S) {
          lockedBy = JSON.parse(checksheet['lockedBy'].S);
        }

        const obj = {
          id: checksheet['sk'].S,
          programType: checksheet['programType'].S,
          checksheetObj: checksheet,
          catalogYear: idYear,
          status: checksheet['progress']?.S ?? null,
          updatedBy: checksheet['updatedBy'].S,
          updatedOn: checksheet['updatedOn'].S,
          lockedBy: lockedBy,
        };

        next.push(obj);
      });

      dispatch(setChecksheetList([...next]));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    checksheetData,
    getChecksheetError,
    getChecksheetIsError,
    getChecksheetIsSuccess,
  ]);

  if (
    getChecksheetIsError ||
    fetchUndergradDegrees.isError ||
    fetchMinorsAndCertificates.isError
  )
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div>
        <TabPanel
          tabs={['Undergraduate Degrees', 'Minors', 'Certificates']}
          largeText={true}
          initialTab={programTypes[programType]}
        >
          <ProgramRows
            degreeType={'undergrad'}
            collegeCode={collegeCode}
            collegeName={collegeName}
            programList={undergradDegreeList}
            fetchPrograms={fetchUndergradDegrees}
            checksheetList={checksheetList}
            refetchChecksheets={refetchChecksheets}
            getChecksheetIsLoading={getChecksheetIsLoading}
            setError={setError}
            handleOpenDefinitions={handleOpenDefinitions}
            user={{
              role,
              asurite,
              college: userCollege,
              department: userDepartment,
            }}
            year={year}
          />
          <ProgramRows
            degreeType={'minor'}
            collegeCode={collegeCode}
            collegeName={collegeName}
            programList={minorList}
            fetchPrograms={fetchMinorsAndCertificates}
            checksheetList={checksheetList}
            refetchChecksheets={refetchChecksheets}
            getChecksheetIsLoading={getChecksheetIsLoading}
            setError={setError}
            handleOpenDefinitions={handleOpenDefinitions}
            user={{
              role,
              asurite,
              college: userCollege,
              department: userDepartment,
            }}
            year={year}
          />
          <ProgramRows
            degreeType={'cert'}
            collegeCode={collegeCode}
            collegeName={collegeName}
            programList={certificateList}
            fetchPrograms={fetchMinorsAndCertificates}
            checksheetList={checksheetList}
            refetchChecksheets={refetchChecksheets}
            getChecksheetIsLoading={getChecksheetIsLoading}
            setError={setError}
            handleOpenDefinitions={handleOpenDefinitions}
            user={{
              role,
              asurite,
              college: userCollege,
              department: userDepartment,
            }}
            year={year}
          />
        </TabPanel>
      </div>
      <Modal show={showDefinitions} onHide={handleCloseDefinitions}>
        <StatusDefinitions
          showNoChecksheet={true}
          handleClose={handleCloseDefinitions}
        />
      </Modal>
    </>
  );
};

export default ProgramList;
