import axios from 'axios';

export async function templateSave({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/save`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function templateGetAll({ token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/get-all`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function templateGetAllByYear({ year, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/get-all/${year}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function templateGet({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/get/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function templateDelete({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/delete/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.delete(api, headers).then((response) => {
    console.log(response.data);
  });
}

export async function templateUpdate({ id, jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/template/update/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.patch(api, jsonData, headers).then((response) => {
    console.log(response.data);
    res = response.data;
  });
  // .catch((error) => {
  //   console.log('Error response status:', error.response.status);
  //   console.log('Error response data:', error.response.data);
  // });
  return res;
}
