import axios from 'axios';

const cc_url = 'https://eadvs-cscc-catalog-api.apps.asu.edu';
const token = sessionStorage.getItem('serviceauth.jwt.token');

export async function subjectGetAll({ term }) {
  const api = `${cc_url}/catalog-microservices/api/v1/search/subjects?sl=Y&term=${term}`;
  console.log('api: ' + api);
  // console.log('token: ' + token);
  const headers = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}
