import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  asurite: '',
  role: '',
  college: '',
  department: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserAll: (state, action) => {
      state.asurite = action.payload.asurite;
      state.role = action.payload.role;
      state.college = action.payload.college;
      state.department = action.payload.department;

      return state;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setUserAll } = userSlice.actions;

export default userSlice.reducer;
