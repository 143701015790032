import {
  bufferToBase64UrlEncoded,
  createRandomString,
  encode,
  sha256,
} from './ServiceAuthUtils';
import axios from 'axios';
import qs from 'qs';
import { decode } from 'jsonwebtoken';

class ServiceAuthClient {
  constructor() {
    this.serviceauthRedirectUri =
      process.env.REACT_APP_SERVICEAUTH_REDIRECT_URI;
    this.serviceauthOauthUrl = process.env.REACT_APP_SERVICEAUTH_OAUTH_URL;
    this.serviceauthTokenUrl = process.env.REACT_APP_SERVICEAUTH_TOKEN_URL;
    this.serviceauthTokenRefreshUrl =
      process.env.REACT_APP_SERVICEAUTH_TOKEN_REFRESH_URL;

    this.scopes = [
      'https://api.myasuplat-dpl.asu.edu/scopes/principal/read:self',
      'https://api.myasuplat-dpl.asu.edu/scopes/person/read:self',
      process.env.REACT_APP_SERVICEAUTH_APP_SCOPE,
    ];

    this.serviceauthId =
      process.env.REACT_APP_SERVICEAUTH_OAUTH_PUBLIC_CLIENT_ID;
    this.serviceauthSecret = 'serviceauth-public-agent';

    this.SS_SA_CODE_VERIFIER = 'pat.serviceauth.codeVerifier';
    this.SS_SA_STATE = 'pat.serviceauth.state';
    this.SS_RETURN = 'pat.serviceauth.return';
    this.SS_JWT_TOKEN = 'pat.jwt.token';
    this.SA_JWT_TOKEN = 'serviceauth.jwt.token';
    //this.PAT_JWT_TOKEN = "pat.app.jwt.token";
    this.SS_JWT_REFRESH_TOKEN = 'pat.jwt.refresh.token';
    this.SS_JWT_EXPIRATION = 'pat.jwt.expiration';
    this.TOKEN_EXPIRES_IN_SECONDS = 540;
    this.USER_EMPLID = 'emplid';
    this.USER_ROLE = 'role';
    this.USER_COLLEGE = 'college';
    this.USER_DEPARTMENT = 'department';
    this.USER_PERMISSIONS = 'permissions';

    // this.DPL_BASE_URL = process.env.REACT_APP_DPL_BASE_URL;
  }

  async redirectToServiceauth() {
    const url = await this.redirectToServiceauthUrl();
    window.location['assign'](url);
  }

  async redirectToServiceauthUrl(options) {
    const state = encode(createRandomString());
    const codeVerifier = createRandomString();
    const codeChallengeBuffer = await sha256(codeVerifier);
    const codeChallenge = bufferToBase64UrlEncoded(codeChallengeBuffer);

    var scopeParam = '';
    for (var i = 0; i < this.scopes.length; i++) {
      if (scopeParam.length > 0) {
        scopeParam += ' ';
      }
      scopeParam += this.scopes[i];
    }

    sessionStorage.setItem(this.SS_SA_CODE_VERIFIER, codeVerifier);
    sessionStorage.setItem(this.SS_SA_STATE, state);
    sessionStorage.setItem(
      this.SS_RETURN,
      `${window.location.pathname}${window.location.search}`
    );

    var url = this.serviceauthOauthUrl;
    url += '?response_type=code';
    url += '&client_id=' + encodeURIComponent(this.serviceauthId);
    url += '&redirect_uri=' + encodeURIComponent(this.serviceauthRedirectUri);
    url += '&state=' + encodeURIComponent(state);
    url += '&code_challenge_method=S256';
    url += '&code_challenge=' + codeChallenge;
    url += '&scope=' + encodeURIComponent(scopeParam);

    // console.log('Redirecting to auth server at' + url + '...');

    return url;
  }

  async handleRedirectCallback(url = window.location.href) {
    const queryStringFragments = url.split('?').slice(1);

    if (queryStringFragments.length === 0) {
      throw new Error('There are no query params available for parsing.');
    }

    const { state, code } = qs.parse(queryStringFragments.join(''));

    const storedState = sessionStorage.getItem(this.SS_SA_STATE);
    const codeVerifier = sessionStorage.getItem(this.SS_SA_CODE_VERIFIER);
    let returnTo = sessionStorage.getItem(this.SS_RETURN);

    if (state !== storedState) throw new Error('State values do not match.');
    // console.log('code: ', code);
    // console.log('query state: ', state);
    // console.log('session state: ', storedState);
    // console.log('code verifier: ', codeVerifier);

    // ASU version
    const response = await axios.post(
      this.serviceauthTokenUrl,
      qs.stringify({
        grant_type: 'authorization_code',
        code: code,
        redirect_uri: this.serviceauthRedirectUri,
        client_id: this.serviceauthId,
        client_secret: this.serviceauthSecret,
        code_verifier: codeVerifier,
      }),
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    const { data } = response;

    // console.log('response from IdP', response);

    // console.log("Saving token to session storage...");

    // console.table(decode(data.access_token));
    sessionStorage.setItem(this.SA_JWT_TOKEN, data.access_token);
    sessionStorage.setItem(this.SS_JWT_REFRESH_TOKEN, data.refresh_token);
    var t = new Date(
      new Date().getTime() + this.TOKEN_EXPIRES_IN_SECONDS * 1000
    );
    sessionStorage.setItem(this.SS_JWT_EXPIRATION, t);

    //get app token
    const appTokenAndRoles = await this.getAppToken(data.access_token);
    sessionStorage.setItem(this.SS_JWT_TOKEN, appTokenAndRoles.access_token);

    // console.log("Token has been set: " + sessionStorage.getItem(this.SS_JWT_TOKEN));
    // console.log("Refresh token has been set: " + sessionStorage.getItem(this.SS_JWT_REFRESH_TOKEN));

    // If user is logging in from login page, return to redirect URL
    if (returnTo.startsWith('/login')) returnTo = '/';

    return { returnTo };
  }

  async getAppToken(token) {
    const headers = {
      headers: { Authorization: `Bearer ${token}` },
    };
    const api = `${process.env.REACT_APP_API_ENDPOINT}/pat-app-token`;

    const response = await axios.get(api, headers);
    const { data } = response;

    return data;
  }

  async checkSession() {
    // console.log('Checking session...');
    if (!sessionStorage.getItem(this.SS_JWT_TOKEN)) {
      console.log('No JWT was found in session storage');
      return;
    }

    try {
      await this.getAccessToken();
    } catch (error) {
      console.log('Error when checking session: ', error);
    }
  }

  async getAccessToken() {
    console.log('Getting access token...');

    const getRefreshedAccessToken = async () => {
      console.log('Refreshing token for ' + this.serviceauthId + '...');

      var refreshToken = sessionStorage.getItem(this.SS_JWT_REFRESH_TOKEN);

      var scopeParam = '';
      for (var i = 0; i < this.scopes.length; i++) {
        if (scopeParam.length > 0) {
          scopeParam += ' ';
        }
        scopeParam += this.scopes[i];
      }

      // Request to refresh access token
      try {
        const response = await axios.post(
          this.serviceauthTokenRefreshUrl,
          qs.stringify({
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            client_id: this.serviceauthId,
            client_secret: this.serviceauthSecret,
            scope: scopeParam,
          }),
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        const { data } = response;

        // console.log("Saving token to session storage...");
        //sessionStorage.setItem(this.SS_JWT_TOKEN, data.access_token);

        var t = new Date(
          new Date().getTime() + this.TOKEN_EXPIRES_IN_SECONDS * 1000
        );

        sessionStorage.setItem(this.SS_JWT_EXPIRATION, t);

        //get app token
        const appTokenAndRoles = await this.getAppToken(data.access_token);
        sessionStorage.setItem(this.SA_JWT_TOKEN, data.access_token);
        sessionStorage.setItem(
          this.SS_JWT_TOKEN,
          appTokenAndRoles.access_token
        );

        return appTokenAndRoles.access_token;
      } catch {
        console.log('Error refreshing token');
        this.redirectToServiceauth();

        return;
      }
    };

    try {
      let token = sessionStorage.getItem(this.SS_JWT_TOKEN);

      if (token) {
        const currentDate = new Date();
        const tokenExpirationDate = new Date(
          sessionStorage.getItem(this.SS_JWT_EXPIRATION)
        );

        if (currentDate > tokenExpirationDate) {
          console.log('Need to refresh token');

          token = await getRefreshedAccessToken();
        }

        return token;
      } else this.redirectToServiceauth();
    } catch (e) {
      console.log('Error getting access token: ' + e);
    }
  }

  logout() {
    // console.log("Logging out...");

    sessionStorage.removeItem(this.SS_JWT_TOKEN);
    sessionStorage.removeItem(this.SA_JWT_TOKEN);
    sessionStorage.removeItem(this.USER_EMPLID);
    sessionStorage.removeItem(this.USER_ROLE);
    sessionStorage.removeItem(this.USER_COLLEGE);
    sessionStorage.removeItem(this.USER_DEPARTMENT);
    sessionStorage.removeItem(this.USER_PERMISSIONS);
  }

  async getUser() {
    // console.log('Getting user info...');

    const token = sessionStorage.getItem(this.SS_JWT_TOKEN);
    var decodedToken = decode(token);
    // sessionStorage.setItem(this.USER_EMPLID, decodedToken.emplid);
    if (decodedToken) {
      sessionStorage.setItem(this.USER_ROLE, decodedToken.roles);
      sessionStorage.setItem(this.USER_COLLEGE, decodedToken.college);
      sessionStorage.setItem(this.USER_DEPARTMENT, decodedToken.department);
      sessionStorage.setItem(this.USER_PERMISSIONS, decodedToken.scope);
    }

    return (
      (decodedToken && decodedToken.email) || (decodedToken && decodedToken.sub)
    );
  }
}

export default ServiceAuthClient;
