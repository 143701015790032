import React, { useCallback, useEffect, useState } from 'react';
import useServiceAuth from 'auth/useServiceAuth';
import { fetchDplColleges } from 'apis/dplAPIs';
import { useMutation, useQuery } from '@tanstack/react-query';
import StaticData from '../components/StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle, faTrash } from '@fortawesome/free-solid-svg-icons';
import { userDelete, userGetAll, userSave } from 'apis/adminAPIs';

const Users = () => {
  const { getAccessToken } = useServiceAuth();

  const [asurite, setAsurite] = useState('');
  const [selectedRole, setSelectedRole] = useState('');
  const [selectedCollege, setSelectedCollege] = useState({});
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  const [users, setUsers] = useState([]);
  const [allColleges, setAllColleges] = useState([]);
  const [allDepartments, setAllDepartments] = useState([]);
  const [collegeDepartments, setCollegeDepartments] = useState([]);

  const [showColleges, setShowColleges] = useState(false);
  const [showDepartments, setShowDepartments] = useState(false);
  const [numberOfDepartments, setNumberOfDepartments] = useState(1);
  const [lockAddUser, setLockAddUser] = useState(false);
  const [error, setError] = useState('');

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
    isPending: fetchCollegesIsPending,
    fetchStatus: fetchCollegesFetchStatus,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const {
    data: getAllUsersData,
    error: getAllUsersError,
    isError: getAllUsersIsError,
    isSuccess: getAllUsersIsSuccess,
    refetch,
  } = useQuery({
    queryKey: ['users'],
    queryFn: async () => {
      const token = await getAccessToken();
      return userGetAll({ token });
    },
  });

  const { mutate: saveUser } = useMutation({
    mutationFn: userSave,
    onSuccess: () => {
      refetch();
      alert('User saved successfully');
      setLockAddUser(false);
    },
  });

  const findChildAcadOrgs = useCallback((parent) => {
    if (!!parent?.childAcadOrgs?.length) {
      const childDepartmentList = [];

      parent.childAcadOrgs.forEach((child) => {
        childDepartmentList.push(child);
        const children = findChildAcadOrgs(child);

        if (children) childDepartmentList.push(...children);
      });

      return childDepartmentList;
    } else return;
  }, []);

  const handleRoleChange = (e) => {
    setSelectedRole(e.target.value);
    setSelectedCollege({});
    setNumberOfDepartments(1);
    setSelectedDepartments([]);
    setCollegeDepartments([]);

    if (e.target.value === 'COLLEGE' || e.target.value === 'DEPARTMENT')
      setShowColleges(true);
    else setShowColleges(false);

    if (e.target.value === 'DEPARTMENT') setShowDepartments(true);
    else {
      setShowDepartments(false);
    }
  };

  const handleCollegeChange = (e) => {
    const foundCollege = allColleges.find(
      (c) => c.acadOrgCode === e.target.value
    );

    setSelectedCollege(foundCollege);
    setNumberOfDepartments(1);
    setSelectedDepartments([]);

    if (selectedRole === 'DEPARTMENT') {
      const childAcadOrgs = findChildAcadOrgs(foundCollege);

      if (childAcadOrgs) setCollegeDepartments(childAcadOrgs);
      else setCollegeDepartments([]);
      setShowDepartments(true);
    } else setShowDepartments(false);
  };

  const handleDepartmentChange = (e, index) => {
    const { value } = e.target;
    const updatedUserDepartments = [...selectedDepartments];

    updatedUserDepartments[index] = value;

    setSelectedDepartments(updatedUserDepartments);
  };

  const handleAddDepartment = () => {
    setNumberOfDepartments(numberOfDepartments + 1);
    setSelectedDepartments([...selectedDepartments, '']);
  };

  const handleRemoveDepartment = (index) => {
    const updatedUserDepartments = [...selectedDepartments];

    updatedUserDepartments.splice(index, 1);

    setSelectedDepartments(updatedUserDepartments);
    setNumberOfDepartments(numberOfDepartments - 1);
  };

  const handleSaveUser = async (e) => {
    e.preventDefault();

    const token = await getAccessToken();

    const data = {
      asurite: asurite,
      roles: JSON.stringify([selectedRole]),
      college: selectedCollege.acadOrgCode,
      department: JSON.stringify(selectedDepartments.filter((n) => n !== '')),
    };

    if (asurite && selectedRole) {
      setLockAddUser(true);
      saveUser({ jsonData: data, token });
    } else alert('Please fill out all fields');
  };

  useEffect(() => {
    if (getAllUsersIsError) setError(getAllUsersError.message);
    if (getAllUsersIsSuccess) setUsers(getAllUsersData);
  }, [
    getAllUsersData,
    getAllUsersError,
    getAllUsersIsError,
    getAllUsersIsSuccess,
  ]);

  useEffect(() => {
    if (fetchCollegesIsError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      setAllColleges(fetchCollegesData);

      const departments = [];

      fetchCollegesData.forEach((coll) => {
        const childAcadOrgs = findChildAcadOrgs(coll);
        if (childAcadOrgs) departments.push(...childAcadOrgs);
      });

      setAllDepartments(departments);
    }
  }, [
    findChildAcadOrgs,
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsError,
    fetchCollegesIsSuccess,
  ]);

  // useEffect(() => {
  //   if (role === 'COLLEGE' || role === 'DEPARTMENT') setShowColleges(true);
  //   else setShowColleges(false);

  //   if (role === 'DEPARTMENT') setShowDepartments(true);
  //   else {setShowDepartments(false);
  //     setNumberOfDepartments(1);
  //     setUserDepartments([]);
  //   }
  // }, [role]);

  if (fetchCollegesIsPending && fetchCollegesFetchStatus === 'fetching')
    return <div className="container">Loading...</div>;

  if (getAllUsersIsError || fetchCollegesIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <div className="container">
      <h3>Users</h3>
      <form
        onSubmit={(e) => {
          handleSaveUser(e);
        }}
      >
        <div className="row mb-2">
          <div className="col">
            <label htmlFor="asurite">Asurite</label>
            <input
              type="text"
              id="asurite"
              className="form-control"
              value={asurite}
              onChange={(e) => setAsurite(e.target.value)}
              required
            />
          </div>
          <div className="col">
            <label htmlFor="role">Role</label>
            <select
              id="role"
              className="form-control"
              value={selectedRole}
              onChange={handleRoleChange}
              required
            >
              <option value="" disabled>
                Select a Role
              </option>
              {StaticData.userRoles.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="col">
            {showColleges && (
              <div>
                <label htmlFor="college">College</label>
                <select
                  id="college"
                  className="form-control"
                  value={selectedCollege?.acadOrgCode ?? {}}
                  onChange={handleCollegeChange}
                >
                  <option value="">Select a College</option>
                  {!!allColleges.length &&
                    allColleges
                      .toSorted((a, b) =>
                        a.alphaSortDescription.localeCompare(
                          b.alphaSortDescription
                        )
                      )
                      .map((option) => (
                        <option
                          key={option.acadOrgCode}
                          value={option.acadOrgCode}
                        >
                          {option.alphaSortDescription}
                        </option>
                      ))}
                </select>
              </div>
            )}
          </div>

          <div className="col">
            {showDepartments && (
              <div>
                <label htmlFor="department">Department</label>
                {[...Array(numberOfDepartments)].map((selectable, index) => (
                  <div
                    key={index}
                    className="d-flex gap-1 align-items-center mb-1"
                  >
                    <select
                      id="department"
                      className="form-control"
                      value={selectedDepartments[index]}
                      onChange={(e) => handleDepartmentChange(e, index)}
                    >
                      <option value="">Select a Department</option>
                      {collegeDepartments
                        .toSorted((a, b) =>
                          a.alphaSortDescription.localeCompare(
                            b.alphaSortDescription
                          )
                        )
                        .map((department) => (
                          <option
                            key={department.acadOrgCode}
                            value={department.acadOrgCode}
                          >
                            {department.alphaSortDescription}
                          </option>
                        ))}
                    </select>
                    {numberOfDepartments > 1 && (
                      <FontAwesomeIcon
                        icon={faTrash}
                        onClick={() => handleRemoveDepartment(index)}
                      />
                    )}
                  </div>
                ))}
                <div
                  className="d-flex gap-1 align-items-center text-maroon underline-hover"
                  onClick={handleAddDepartment}
                >
                  <FontAwesomeIcon icon={faPlusCircle} />
                  Add additional department
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="row mb-5">
          <div className="col">
            <button
              className="btn btn-primary"
              disabled={
                lockAddUser ||
                !asurite ||
                !selectedRole ||
                (selectedRole === 'COLLEGE' &&
                  (!selectedCollege || !Object.keys(selectedCollege).length)) ||
                (selectedRole === 'DEPARTMENT' &&
                  (!selectedCollege ||
                    !Object.keys(selectedCollege).length ||
                    selectedDepartments.length === 0 ||
                    !selectedDepartments[0]))
              }
            >
              Add User
            </button>
          </div>
        </div>
      </form>
      {users && (
        <div className="row">
          <div className="col">
            <h4>User List</h4>
            <table className="table">
              <thead>
                <tr>
                  <th>ASURITE</th>
                  <th>ROLE</th>
                  <th>COLLEGE</th>
                  <th>DEPARTMENT</th>
                  <th className="text-end">ACTION</th>
                </tr>
              </thead>
              <tbody>
                {users.map((item, i) => (
                  <UserRow
                    key={i}
                    item={item}
                    collegeList={allColleges}
                    departmentList={allDepartments}
                    refetch={refetch}
                  />
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

const UserRow = ({ item, collegeList, departmentList, refetch }) => {
  const { getAccessToken } = useServiceAuth();

  const [userRoles, setUserRoles] = useState([]);
  const [userColleges, setUserColleges] = useState('');
  const [userDepartments, setUserDepartments] = useState([]);

  const { mutate: deleteUser } = useMutation({
    mutationFn: userDelete,
    onSuccess: () => {
      refetch();
      alert('User deleted successfully');
    },
  });

  const handleDeleteUser = async (e) => {
    const token = await getAccessToken();

    if (window.confirm(`Are you sure you want to delete user ${item.sk.S}?`))
      deleteUser({ id: item.sk.S, token });
  };

  useEffect(() => {
    setUserRoles(JSON.parse(item.roles.S));
  }, [item]);

  useEffect(() => {
    const foundCollege = collegeList.find(
      (college) => college.acadOrgCode === item.college?.S
    );

    if (!!foundCollege) setUserColleges(foundCollege);
    else setUserColleges('');
  }, [collegeList, item]);

  useEffect(() => {
    let foundDepartments = [];
    const { department: { S: departmentCode } = {} } = item;

    if (!!departmentCode) {
      if (departmentCode.includes('[')) {
        const departmentCodes = JSON.parse(departmentCode);
        foundDepartments = departmentCodes.map((code) => {
          return departmentList.find(
            (department) => department.acadOrgCode === code
          );
        });
      } else {
        foundDepartments = [
          departmentList.find(
            (department) => department.acadOrgCode === departmentCode
          ),
        ];
      }
    }

    if (!!foundDepartments.length) setUserDepartments([...foundDepartments]);
    else setUserDepartments([]);
  }, [departmentList, item]);

  return (
    <tr>
      <td>{item.sk.S}</td>
      <td>
        {userRoles.map((role) => (
          <div key={role}>{role}</div>
        ))}
      </td>

      <td>
        {!!userColleges ? (
          <div>
            {userColleges.description} ({userColleges.acadOrgCode})
          </div>
        ) : (
          '\u2014'
        )}
      </td>
      <td>
        {!!userDepartments?.length
          ? userDepartments.map(
              (dept, index) =>
                !!dept && (
                  <div key={index}>
                    {dept.alphaSortDescription} ({dept.acadOrgCode})
                  </div>
                )
            )
          : '\u2014'}
      </td>
      <td>
        <div className="text-end">
          {item.sk.S !== 'lcabre' && (
            <button
              className="btn btn-sm btn-gray"
              onClick={() => {
                handleDeleteUser();
              }}
            >
              Delete
            </button>
          )}
        </div>
      </td>
    </tr>
  );
};

export default Users;
