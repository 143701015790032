import React from 'react';
import SettingsForm from 'components/SettingsForm';

const Settings = () => {
  return (
    <div className="container pt-3">
      <h2>Settings</h2>
      <SettingsForm />
    </div>
  );
};

export default Settings;
