import React, { useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';
import { useQuery } from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import { settingGet } from 'apis/adminAPIs';
import { setYear } from 'state/slices/settingsSlice';
import { classNames } from 'utils';
import { useLocation } from 'react-router-dom';

const RoutesWrapper = ({ children }) => {
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();
  const location = useLocation();

  const scrollRef = useRef(null);

  const { data: yearData, isSuccess: getYearIsSuccess } = useQuery({
    queryKey: ['year'],
    queryFn: async () => {
      const token = await getAccessToken();
      return settingGet({ setting: 'year', token });
    },
    enabled: isAuthenticated,
  });

  const handleWindowScroll = () => {
    const curPos = window.scrollY;

    if (curPos !== 0) {
      scrollRef.current.classList.add('scroll-down');
    } else {
      scrollRef.current.classList.remove('scroll-down');
    }
  };

  useEffect(() => {
    window?.addEventListener('scroll', handleWindowScroll);
    return () => window.removeEventListener('scroll', handleWindowScroll);
  }, []);

  useEffect(() => {
    if (getYearIsSuccess) {
      dispatch(setYear(yearData.value));
    }
  }, [dispatch, getYearIsSuccess, yearData]);

  return (
    <div
      ref={scrollRef}
      className={classNames(
        location.pathname === '/' && 'bg-gray-1',
        'position-relative routes-wrap'
      )}
    >
      {children}
    </div>
  );
};

export default RoutesWrapper;
