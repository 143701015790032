import React, { useEffect, useState } from 'react';
import { useMutation, useQuery } from '@tanstack/react-query';
import { settingGet, settingSave } from 'apis/adminAPIs';
import useServiceAuth from 'auth/useServiceAuth';
import { Button } from '@asu/components-core';
import { useDispatch } from 'react-redux';
import { setYear } from 'state/slices/settingsSlice';

const SettingsForm = () => {
  const { isAuthenticated, getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [years, setYears] = useState({});
  const [isEditing, setIsEditing] = useState(false);

  const { data: yearData, isSuccess: getYearIsSuccess } = useQuery({
    queryKey: ['year'],
    queryFn: async () => {
      const token = await getAccessToken();
      return settingGet({ setting: 'year', token });
    },
    enabled: isAuthenticated,
  });

  const { mutate: updateYear } = useMutation({
    mutationFn: async () => {
      const token = await getAccessToken();
      const jsonData = { id: 'year', value: String(years.start) };

      return settingSave({ jsonData, token });
    },
    onSuccess: (data) => {
      dispatch(setYear(String(years.start)));
    },
  });

  const handleSaveCatalogYear = () => {
    updateYear();
    setIsEditing(false);
  };

  const handleCancelCatalogYear = () => {
    const start = Number(yearData.value);
    const end = start + 1;

    setYears({ start, end });
    setIsEditing(false);
  };

  const handleNextYear = () => {
    setYears({ start: years.start + 1, end: years.end + 1 });
  };

  const handlePrevYear = () => {
    setYears({ start: years.start - 1, end: years.end - 1 });
  };

  useEffect(() => {
    if (getYearIsSuccess) {
      const start = Number(yearData.value);
      const end = start + 1;

      setYears({ start, end });
    }
  }, [getYearIsSuccess, yearData]);

  return (
    <div>
      <div className="d-flex gap-8">
        <div>
          <div className="fw-bold">Default catalog year</div>
          <div>This is the default catalog year for all users.</div>
        </div>
        {!isEditing ? (
          <div className="d-flex gap-3 justify-content-between align-items-center">
            {getYearIsSuccess ? (
              <div>
                {years.start} - {years.end}
              </div>
            ) : (
              <div>Catalog year not found</div>
            )}
            <Button
              color="maroon"
              label="Edit"
              onClick={() => setIsEditing(true)}
            />
          </div>
        ) : (
          <div className="d-flex flex-column gap-3 align-items-center">
            <div className="d-flex gap-1 align-items-center">
              <Button
                size="xsmall"
                icon={['fas', 'caret-left']}
                // classes={[
                //   `btn bg-white btn-sm border border-gray-4 text-gray-6 ${years.start === 2023 && 'disabled'}`,
                // ]}
                onClick={handlePrevYear}
                disabled={
                  process.env.REACT_APP_SERVICEAUTH_OAUTH_PUBLIC_CLIENT_ID.split(
                    '-'
                  ).includes('nonprod')
                    ? years.start === 2023
                    : years.start === 2025
                }
              />
              <div>
                {years.start} - {years.end}
              </div>
              <Button
                size="xsmall"
                icon={['fas', 'caret-right']}
                color="maroon"
                onClick={handleNextYear}
                disabled={years.start === 2028}
              />
            </div>
            <div className="d-flex gap-1">
              <Button label="Cancel" onClick={handleCancelCatalogYear} />
              <Button
                label="Save"
                color="maroon"
                onClick={handleSaveCatalogYear}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default SettingsForm;
