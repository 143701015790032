import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateMilestones } from 'state/slices/templateSlice';
import { Button } from '@asu/components-core';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGripVertical, faTrash } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid';
import { classNames } from 'utils';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';

const Sortable = ({ id, disabled, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id, disabled });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const MilestoneRow = ({
  ms = '',
  id = null,
  milestones,
  showBorder,
  newMilestone = false,
  setNewMilestone = null,
  isOpen = false,
  onOpenEditMilestone = null,
}) => {
  const dispatch = useDispatch();

  const [milestone, setMilestone] = useState(ms);
  const [showModal, setShowModal] = useState(false);
  // const [editingMilestone, setEditingMilestone] = useState(false);

  const handleOpenModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);

  const handleSaveMilestone = () => {
    const next = { ...milestones };

    if (newMilestone) {
      next[uuidv4()] = milestone;

      dispatch(setTemplateMilestones({ ...next }));
      setMilestone('');
      setNewMilestone(false);
    } else {
      next[id] = milestone;

      dispatch(setTemplateMilestones({ ...next }));
      onOpenEditMilestone(null);
    }
  };

  const handleCancelEdit = () => {
    if (newMilestone) {
      setMilestone('');
      setNewMilestone(false);
    } else {
      setMilestone(ms);
      onOpenEditMilestone(null);
    }
  };

  const handleDeleteMilestone = () => {
    let next = { ...milestones };
    const { [id]: targetMilestone, ...otherMilestones } = next;
    next = otherMilestones;

    dispatch(setTemplateMilestones({ ...next }));
    setShowModal(false);
  };

  useEffect(() => {
    setMilestone(ms);
  }, [ms, isOpen]);

  return (
    <>
      <div className="text-option-row">
        <div
          className={classNames(
            showBorder && 'border-bottom',
            newMilestone && 'border-top',
            'mx-2 py-2 border-gray-3'
          )}
        >
          {isOpen || newMilestone ? (
            <div className="d-flex gap-2">
              <textarea
                className="p-1"
                style={{ width: '70%' }}
                rows={3}
                value={milestone}
                onChange={(e) => setMilestone(e.target.value)}
              />
              <div className="d-flex gap-2" style={{ height: 'min-content' }}>
                <Button
                  label={newMilestone ? 'Add milestone' : 'Update milestone'}
                  color="maroon"
                  size="small"
                  onClick={handleSaveMilestone}
                />
                <Button
                  label="Cancel"
                  size="small"
                  onClick={handleCancelEdit}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex gap-2 justify-content-between">
              <div className="d-flex gap-2">
                <FontAwesomeIcon icon={faGripVertical} />
                <div>{milestone}</div>
              </div>
              <div className="d-flex gap-3">
                <div
                  className="button-link"
                  onClick={() => onOpenEditMilestone(id)}
                  role="button"
                >
                  Edit
                </div>
                <div onClick={handleOpenModal} role="button">
                  <FontAwesomeIcon icon={faTrash} className="text-gray-6" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <div className="p-6" style={{ width: '828px' }}>
          <div className="fs-4 fw-bold mb-4">
            Are you sure you want to delete this milestone?
          </div>
          <div></div>
          <div className="d-flex gap-1 justify-content-between align-items-center">
            <Button label="Cancel" color="gray" onClick={handleCloseModal} />
            <Button
              label="Yes, delete this milestone"
              color="maroon"
              onClick={handleDeleteMilestone}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

const TemplateFormMilestones = () => {
  const dispatch = useDispatch();
  const [newMilestone, setNewMilestone] = useState(false);
  // const [disableDrag, setDisableDrag] = useState(false);
  const [editMilestone, setEditMilestone] = useState(null);

  const templateMilestones = useSelector(
    (state) => state.template.templateMilestones
  );

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleOpenEditMilestone = (id) => {
    setNewMilestone(false);
    setEditMilestone(editMilestone === id ? null : id);
  };

  const handleNewMilestone = () => {
    setNewMilestone(true);
    setEditMilestone(null);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const activeIndex = Object.entries(templateMilestones).findIndex(
        ([id]) => id === active.id
      );
      const overIndex = Object.entries(templateMilestones).findIndex(
        ([id]) => id === over.id
      );

      const next = arrayMove(
        Object.entries(templateMilestones),
        activeIndex,
        overIndex
      );

      dispatch(setTemplateMilestones(Object.fromEntries(next)));
    }
  };

  return (
    <>
      <div>
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            Configure milestone options below. These will be available for all
            users of this template.
          </div>
        </div>
        <div>
          <div className="mb-3">
            <div className="mb-2">
              <div className="row border-bottom border-gray-7 border-2 py-1 m-0">
                <div className="col-auto ps-0">
                  <div className="fw-bold">Milestones</div>
                </div>
              </div>
              <div className="border border-top-0 border-gray-3">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                >
                  <SortableContext
                    items={Object.entries(templateMilestones).map(
                      ([milestoneId]) => milestoneId
                    )}
                    strategy={verticalListSortingStrategy}
                  >
                    {!!Object.keys(templateMilestones).length &&
                      Object.entries(templateMilestones).map(
                        ([milestoneId, milestone], index, array) => (
                          <Sortable
                            key={milestoneId}
                            id={milestoneId}
                            disabled={editMilestone !== null || newMilestone}
                          >
                            <MilestoneRow
                              key={index}
                              index={index}
                              ms={milestone}
                              id={milestoneId}
                              milestones={templateMilestones}
                              showBorder={index < array.length - 1}
                              isOpen={editMilestone === milestoneId}
                              onOpenEditMilestone={handleOpenEditMilestone}
                            />
                          </Sortable>
                        )
                      )}
                  </SortableContext>
                </DndContext>
                <div
                  className={classNames(
                    newMilestone
                      ? 'visible opacity-100'
                      : 'invisible opacity-0',
                    'new-text-option'
                  )}
                  style={{ height: newMilestone ? '105px' : 0 }}
                >
                  <MilestoneRow
                    milestones={templateMilestones}
                    showBorder={false}
                    newMilestone={true}
                    setNewMilestone={setNewMilestone}
                  />
                </div>
                {!Object.keys(templateMilestones)?.length && !newMilestone && (
                  <div className="text-gray-6 p-3">No milestones added</div>
                )}
              </div>
            </div>
            <Button
              label="Create new milestone"
              color="maroon"
              size="small"
              onClick={handleNewMilestone}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default TemplateFormMilestones;
