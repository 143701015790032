import RolloverCatalog from 'components/RolloverCatalog';
import React from 'react';

const Rollover = () => {
  return (
    <div className="px-0 mt-5 text-gray-7 container-xl">
      <div style={{ width: '60%' }}>
        <h1 className="mb-6">Ready to roll to the next catalog year?</h1>
        <p>
          Upon rollover, the following contents from the source catalog year
          will be copied to the destination catalog year:
        </p>
        <ul>
          <li>All templates</li>
          <li>All checksheets, excluding proposed checksheets</li>
          <li>All subplan configurations</li>
        </ul>
        <hr
          style={{ height: '8px', width: '22%', backgroundColor: '#FFC627' }}
        />
        <RolloverCatalog />
      </div>
    </div>
  );
};

export default Rollover;
