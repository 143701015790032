import React, { useEffect, useState } from 'react';
import { classNames, addJsonPropertyWithDefault } from 'utils';
import StaticData from './StaticData';
import { useDispatch, useSelector } from 'react-redux';
import { setTemplateValidationList } from 'state/slices/templateSlice';

const TemplateValidationRuleRow = ({ gs, index }) => {
  const templateValidationList = useSelector(
    (state) => state.template.templateValidationList
  );

  const templateValidationRule =
    templateValidationList[
      templateValidationList.findIndex(
        (rule) => rule.category.value === gs.value
      )
    ];

  const [temporaryValidationList, setTemporaryValidationList] = useState([
    ...templateValidationList,
  ]);

  const temporaryValidationRule =
    temporaryValidationList[
      temporaryValidationList.findIndex(
        (rule) => rule.category.value === gs.value
      )
    ];

  const dispatch = useDispatch();

  const handleChangeValidationRule = (property, gs, event) => {
    const validationRules = [...templateValidationList];
    const value = +event.target.value;

    const index = validationRules.findIndex(
      (rule) => rule.category.value === gs.value
    );

    if (index >= 0) {
      const obj = { ...validationRules[index] };
      obj[property] = value;
      validationRules[index] = obj;
    } else {
      validationRules.push({
        category: gs,
        [property]: value,
      });
    }
    //set gpa, hours and upperdivisionHours =0 if user not enter the value.
    const rules = addJsonPropertyWithDefault(validationRules, [
      'gpa',
      'hours',
      'upperDivisionHours',
    ]);
    if (property === 'gpa') dispatch(setTemplateValidationList([...rules]));
    else setTemporaryValidationList([...rules]);
  };

  const handleBlurValidationRule = (property) => {
    if (
      !templateValidationRule ||
      !templateValidationRule[property] ||
      temporaryValidationRule[property] !== templateValidationRule[property]
    )
      dispatch(setTemplateValidationList([...temporaryValidationList]));
  };

  useEffect(() => {
    setTemporaryValidationList([...templateValidationList]);
  }, [templateValidationList]);

  return (
    <tr
      key={gs.value}
      className={classNames(
        index !== StaticData.gsList.length - 1 && 'border-bottom',
        'row align-items-center py-2 mx-2'
      )}
    >
      <td className="col-6 fw-bold ps-0">
        {gs.label} ({gs.value})
      </td>
      <td className="col text-center">
        <input
          className="text-end"
          type="number"
          min="0"
          step="1"
          value={temporaryValidationRule?.hours ?? 0}
          onChange={(e) => handleChangeValidationRule('hours', gs, e)}
          onBlur={() => handleBlurValidationRule('hours')}
        />
      </td>
      <td className="col text-center">
        <input
          className="text-end"
          type="number"
          min="0"
          step="1"
          value={temporaryValidationRule?.upperDivisionHours ?? 0}
          onChange={(e) =>
            handleChangeValidationRule('upperDivisionHours', gs, e)
          }
          onBlur={() => handleBlurValidationRule('upperDivisionHours')}
        />
      </td>
      <td className="col text-end pe-0">
        <select
          value={templateValidationRule?.gpa ?? 'undefined'}
          onChange={(e) => handleChangeValidationRule('gpa', gs, e)}
          className={classNames(
            templateValidationRule?.gpa && 'fw-bold',
            'text-gray-7'
          )}
          style={{ padding: '0.25rem' }}
        >
          {StaticData.gpaList.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
      </td>
    </tr>
  );
};

export default TemplateValidationRuleRow;
