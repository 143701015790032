import React from 'react';
import ComponentList from 'components/ComponentList';

const Components = () => {
  return (
    <div>
      <ComponentList />
    </div>
  );
};

export default Components;
