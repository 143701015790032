import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ServiceAuthClient from './ServiceAuthClient';
import ServiceAuthContext from './ServiceAuthContext';
import { initialAuthState } from './initialAuthState';
import { hasAuthParams } from './ServiceAuthUtils';

const SS_SA_CODE_VERIFIER = 'pat.serviceauth.codeVerifier';
const SS_SA_STATE = 'pat.serviceauth.state';
const SS_RETURN = 'pat.serviceauth.return';

const reducer = (state, action) => {
  // console.log('In reducer...');
  // console.log('State: ', state);
  // console.log('Action: ', action);

  switch (action.type) {
    case 'INITIALISED':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
        error: undefined,
        isLoading: false,
      };
    case 'HANDLE_REDIRECT_COMPLETE':
    case 'GET_ACCESS_TOKEN_COMPLETE':
      return {
        ...state,
        isAuthenticated: !!action.user,
        user: action.user,
      };
    case 'LOGOUT':
      return {
        ...state,
        isAuthenticated: false,
        user: undefined,
      };
    case 'ERROR':
    default:
      return {
        ...state,
        error: action.error,
        isLoading: false,
      };
  }
};

const ServiceAuthProvider = ({ children }) => {
  const navigate = useNavigate();
  const [client] = useState(() => new ServiceAuthClient());
  // console.log(client);
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  // console.log(state);

  useEffect(() => {
    (async () => {
      // console.log('checking for auth params');
      try {
        if (hasAuthParams()) {
          // console.log('found auth params');
          const { returnTo } = await client.handleRedirectCallback();

          // console.log('Returning to ' + returnTo);
          navigate(returnTo);
        } else {
          await client.checkSession();
        }
        const user = await client.getUser();
        // @ts-ignore
        sessionStorage.removeItem(SS_SA_STATE);
        sessionStorage.removeItem(SS_SA_CODE_VERIFIER);
        sessionStorage.removeItem(SS_RETURN);
        dispatch({ type: 'INITIALISED', user });
      } catch (error) {
        // @ts-ignore
        dispatch({ type: 'ERROR', error: error });
      }
    })();
  }, [navigate, client]);

  const redirectToServiceauth = useCallback(
    (opts) => client.redirectToServiceauth(),
    [client]
  );

  const logout = useCallback(
    (opts) => {
      client.logout();

      navigate(
        `/login?severity=success&message=${encodeURIComponent(
          'You have been signed out.'
        )}`
      );

      // @ts-ignore
      dispatch({ type: 'LOGOUT' });
    },
    [navigate, client]
  );

  const getAccessToken = useCallback(
    async (opts) => {
      // console.log("Getting access token...");

      let token;

      try {
        token = await client.getAccessToken();
        // console.log("Got token: " + token);
      } catch (error) {
        console.log('Error when getting token: ' + error);
      } finally {
        // @ts-ignore
        dispatch({
          type: 'GET_ACCESS_TOKEN_COMPLETE',
          user: await client.getUser(),
        });
      }

      return token;
    },
    [client]
  );

  return (
    <ServiceAuthContext.Provider
      value={{ ...state, redirectToServiceauth, getAccessToken, logout }}
    >
      {children}
    </ServiceAuthContext.Provider>
  );
};

export default ServiceAuthProvider;
