import axios from 'axios';

export async function componentSave({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/component/save`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function componentGetAll({ token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/component/get-all`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function componentGet({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/component/get/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    res = response.data;
  });

  return res;
}

export async function componentDelete({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/component/delete/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.delete(api, headers).then((response) => {
    console.log(response.data);
  });
}

export async function componentUpdate({ id, jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/component/update/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .patch(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}
