import React, { useEffect, useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { checksheetCopy, checksheetGetAllByYear } from 'apis/checksheetAPIs';
import { setChecksheetList } from 'state/slices/dataListsSlice';
import { Button } from '@asu/components-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faXmark } from '@fortawesome/free-solid-svg-icons';
import StaticData from './StaticData';
import { classNames } from 'utils';
import useServiceAuth from 'auth/useServiceAuth';

const SearchProgram = ({ program }) => {
  const degree = program.degreeDescriptionShort
    ? program.degreeDescriptionShort === 'CERT'
      ? ', Certificate'
      : `, ${program.degreeDescriptionShort}`
    : program.acadPlanType === 'MIN'
      ? ', Minor'
      : null;

  const owners = [];
  if (program.owners.length > 0) {
    program.owners.forEach((programOwner) => {
      if (
        owners.filter(
          (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
        ).length === 0
      ) {
        owners.push({
          collegeAcadOrg: programOwner.collegeAcadOrg,
          collegeDescription: programOwner.collegeDescription,
        });
      }
    });
  }

  return (
    <>
      <div className="fs-6 fw-bold">
        {program.acadPlanDescription}
        {/* {program.subplan && ` - ${program.subplan.description}`} */}
        {degree}
      </div>
      {program.subplan && (
        <div className="fw-bold">
          {program.subplan.description}
          <span className="subplan-tag ms-1">Subplan</span>
        </div>
      )}
      <div className="text-gray-6" style={{ fontSize: '14px' }}>
        {owners.map((owner, index) => (
          <div
            key={owner.collegeAcadOrg}
            className={classNames(index !== owners.length - 1 && 'mb-1')}
          >
            {owner.collegeDescription}
          </div>
        ))}
      </div>
      <div className="text-gray-6" style={{ fontSize: '14px' }}>
        {program.acadPlanCode}
        {program.subplan && <span> / {program.subplan.acadSubPlanCode}</span>}
      </div>
    </>
  );
};

const CopyChecksheet = ({ programList, checksheetList, handleClose }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [searchString, setSearchString] = useState('');
  const [currentChecksheetDegree, setCurrentChecksheetDegree] = useState(null);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedProgramDegree, setSelectedProgramDegree] = useState(null);
  const [copyFromYear, setCopyFromYear] = useState('2023');
  const [toProgramYear, setToProgramYear] = useState('2023');

  const year = useSelector((state) => state.settings.year);
  const currentChecksheet = useSelector(
    (state) => state.currentSelection.currentChecksheet
  );

  let owners = [];
  let mainOwner = {};
  if (selectedProgram) {
    if (selectedProgram.owners.length > 0) {
      let highestPercent = 0;
      selectedProgram.owners.forEach((programOwner) => {
        if (
          owners.filter(
            (owner) => owner.collegeAcadOrg === programOwner.collegeAcadOrg
          ).length === 0
        ) {
          owners.push({
            collegeAcadOrg: programOwner.collegeAcadOrg,
            collegeDescription: programOwner.collegeDescription,
          });
        }

        if (programOwner.percentOwned > highestPercent) {
          highestPercent = programOwner.percentOwned;
          mainOwner = programOwner;
        }
      });
    }
  }

  const { mutate: getAllChecksheets } = useMutation({
    mutationFn: checksheetGetAllByYear,
    onSuccess: async (data) => {
      const next = [];

      data.forEach((checksheet) => {
        const idYear = checksheet['sk'].S.split('*')[0];
        let lockedBy = {};

        if (!!checksheet['lockedBy']?.S) {
          lockedBy = JSON.parse(checksheet['lockedBy'].S);
        }

        const obj = {
          id: checksheet['sk'].S,
          checksheetObj: checksheet,
          catalogYear: idYear,
          status: checksheet['progress']?.S ?? null,
          updatedBy: checksheet['updatedBy'].S,
          updatedOn: checksheet['updatedOn'].S,
          lockedBy: lockedBy,
        };

        next.push(obj);
      });

      dispatch(setChecksheetList([...next]));
    },
  });

  // Save new checksheet to database
  const { mutate: copyChecksheet } = useMutation({
    mutationFn: checksheetCopy,
    onSuccess: async (data, { token }) => {
      getAllChecksheets({ year, token });
    },
  });

  const handleCopyChecksheet = async () => {
    if (selectedProgram) {
      const jsonData = {
        checksheetfrom: currentChecksheet.checksheet.id,
        acadplanto: selectedProgram.acadPlanCode,
        acadsubplanto: selectedProgram.subplan
          ? selectedProgram.subplan.acadSubPlanCode
          : null,
        collegeto: mainOwner.collegeAcadOrg,
        departmentto: mainOwner.departmentAcadOrg,
        nameto: selectedProgram.acadPlanDescription + selectedProgramDegree,
        programtypeto:
          selectedProgram.degreeDescriptionShort ??
          selectedProgram.acadPlanType,
        yearto: toProgramYear,
      };

      console.log('JSON: ', jsonData);

      const token = await getAccessToken();
      copyChecksheet({ jsonData: jsonData, token: token });

      handleClose();
    } else {
      alert('Program not selected');
    }
  };

  const handleSelectCopyFromYear = (e) => {
    setCopyFromYear(e.target.value);
  };

  const handleSelectToProgramYear = (e) => {
    setToProgramYear(e.target.value);
  };

  useEffect(() => {
    if (currentChecksheet && currentChecksheet.program) {
      const dds = currentChecksheet.program.degreeDescriptionShort
        ? currentChecksheet.program.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${currentChecksheet.program.degreeDescriptionShort}`
        : currentChecksheet.program.acadPlanType === 'MIN'
          ? ', Minor'
          : null;

      setCurrentChecksheetDegree(dds);
    }
  }, [currentChecksheet]);

  useEffect(() => {
    if (selectedProgram) {
      const dds = selectedProgram.degreeDescriptionShort
        ? selectedProgram.degreeDescriptionShort === 'CERT'
          ? ', Certificate'
          : `, ${selectedProgram.degreeDescriptionShort}`
        : selectedProgram.acadPlanType === 'MIN'
          ? ', Minor'
          : null;

      setSelectedProgramDegree(dds);
    }
  }, [selectedProgram]);

  if (!currentChecksheet || !currentChecksheet.program)
    return <div>Loading...</div>;

  return (
    <div className="p-6" style={{ width: '1182px' }}>
      <div className="fs-4 fw-bold mb-4">Copy checksheet</div>
      <div className="row justify-content-between mb-9">
        <div className="col-5">
          <div className="fs-5 fw-bold mb-2">Copy from</div>
          <div className="border border-gray-5 w-100 mb-3">
            <div className="border-start border-8 border-maroon ps-2 pe-3 py-3">
              <div className="fs-4 fw-bold">
                {currentChecksheet.program.acadPlanDescription}
                {/* {currentChecksheet.program.subplan && ` - ${currentChecksheet.program.subplan.description}`} */}
                {currentChecksheetDegree}
              </div>

              {currentChecksheet.program.subplan && (
                <div className="fw-bold">
                  {currentChecksheet.program.subplan.description}
                  <span className="subplan-tag ms-1">Subplan</span>
                </div>
              )}
              <div>
                {currentChecksheet.owners.map((owner, index) => (
                  <div
                    key={owner.collegeAcadOrg}
                    className={classNames(
                      index !== currentChecksheet.owners.length - 1 && 'mb-1'
                    )}
                  >
                    {owner.collegeDescription}
                  </div>
                ))}
              </div>
              <div>
                {currentChecksheet.program.acadPlanCode}
                {currentChecksheet.program.subplan && (
                  <span>
                    {' '}
                    / {currentChecksheet.program.subplan.acadSubPlanCode}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="fw-bold mb-1">Catalog year</div>
            <select
              value={copyFromYear}
              onChange={handleSelectCopyFromYear}
              styles={{
                width: '150px',
              }}
              className="text-gray-7 p-1"
            >
              {StaticData.yearList.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center">
          <div
            className="bg-gray-2 lh-1 mt-10 rounded-circle"
            style={{
              height: 'fit-content',
              width: 'fit-content',
              padding: '16px 17px',
            }}
          >
            <FontAwesomeIcon
              icon={faArrowRight}
              style={{ width: '26px', height: '27px' }}
            />
          </div>
        </div>
        <div className="col-5">
          <div className="fs-5 fw-bold mb-2">To program</div>
          {!selectedProgram ? (
            <>
              <div className="fw-bold lh-1 mb-1">Search programs</div>
              <div className="position-relative">
                <input
                  placeholder="Search by program title or plan code"
                  value={searchString}
                  onChange={(e) => setSearchString(e.target.value)}
                  className="border border-gray-5 ps-2 pe-6 py-1 w-100"
                ></input>
                {searchString && (
                  <div
                    className="position-absolute border border-top-0 border-gray-5 w-100 overflow-y-auto"
                    style={{ maxHeight: '198px' }}
                  >
                    {programList
                      .filter(
                        (program) =>
                          !checksheetList.some(
                            (checksheet) =>
                              checksheet.id.split('*')[2] ===
                                program.acadPlanCode &&
                              ((!program.subplan &&
                                checksheet.id.split('*')[3] === 'null') ||
                                program.subplan?.acadSubPlanCode ===
                                  checksheet.id.split('*')[3])
                          )
                      )
                      .filter(
                        (program) =>
                          program.acadPlanCode
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          program.acadPlanDescription
                            .toLowerCase()
                            .includes(searchString.toLowerCase()) ||
                          (program.subplan &&
                            (program.subplan.acadSubPlanCode
                              .toLowerCase()
                              .includes(searchString.toLowerCase()) ||
                              program.subplan.description
                                .toLowerCase()
                                .includes(searchString.toLowerCase())))
                      )
                      .map((program, index, array) => (
                        <div
                          key={program.acadPlanCode + index}
                          className={classNames(
                            index !== array.length - 1 && 'border-bottom',
                            'border-gray-5 p-2'
                          )}
                          onClick={() => setSelectedProgram(program)}
                          role="button"
                        >
                          <SearchProgram
                            program={program}
                            checksheet={currentChecksheet}
                          />
                        </div>
                      ))}
                  </div>
                )}
              </div>
            </>
          ) : (
            <>
              <div className="border border-gray-5 w-100 mb-3">
                <div className="border-start border-8 border-gold ps-2 pe-3 py-3">
                  <div className="d-flex justify-content-between">
                    <div className="fs-4 fw-bold">
                      {selectedProgram.acadPlanDescription}
                      {/* {selectedProgram.subplan && ` - ${selectedProgram.subplan.description}`} */}
                      {selectedProgramDegree}
                    </div>
                    <div
                      className="bg-gray-2 lh-1 rounded-circle ms-1 mb-1"
                      style={{ height: 'min-content', padding: '3px 6px 5px' }}
                      onClick={() => setSelectedProgram(null)}
                      role="button"
                    >
                      <FontAwesomeIcon
                        icon={faXmark}
                        style={{ height: '12px', width: '12px' }}
                      />
                    </div>
                  </div>
                  {selectedProgram.subplan && (
                    <div className="fw-bold">
                      {selectedProgram.subplan.description}
                      <span className="subplan-tag ms-1">Subplan</span>
                    </div>
                  )}
                  <div>
                    {owners.map((owner, index) => (
                      <div
                        key={owner.collegeAcadOrg}
                        className={classNames(
                          index !== owners.length - 1 && 'mb-1'
                        )}
                      >
                        {owner.collegeDescription}
                      </div>
                    ))}
                  </div>
                  <div>
                    {selectedProgram.acadPlanCode}
                    {selectedProgram.subplan && (
                      <span> / {selectedProgram.subplan.acadSubPlanCode}</span>
                    )}
                  </div>
                </div>
              </div>
              <div>
                <div className="fw-bold mb-1">Catalog year</div>
                <select
                  value={toProgramYear}
                  onChange={handleSelectToProgramYear}
                  styles={{
                    width: '150px',
                  }}
                  className="text-gray-7 p-1"
                >
                  {StaticData.yearList.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
              </div>
            </>
          )}
        </div>
      </div>

      <div className="d-flex justify-content-between align-items-center">
        <Button
          color="dark"
          label="Cancel"
          size="medium"
          onClick={handleClose}
        />
        <Button
          color="gold"
          label="Copy checksheet"
          size="medium"
          onClick={handleCopyChecksheet}
          disabled={!selectedProgram}
        />
      </div>
    </div>
  );
};

export default CopyChecksheet;
