import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const UserRoute = ({
  allowProvost,
  allowCollege,
  allowDepartment,
  allowDars,
  children,
}) => {
  const role = useSelector((state) => state.user.role);

  const isAllowed =
    !!role &&
    ((role.includes('PROVOST') && allowProvost) ||
      (role.includes('COLLEGE') && allowCollege) ||
      (role.includes('DEPARTMENT') && allowDepartment) ||
      (role.includes('DARS') && allowDars));

  return isAllowed ? children : <Navigate to="/" replace />;
};

export default UserRoute;
