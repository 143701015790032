import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Button } from '@asu/components-core';
import {
  closestCenter,
  DndContext,
  // DragOverlay,
  KeyboardSensor,
  PointerSensor,
  useDroppable,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
  faArrowDown,
  faArrowUp,
  faBarsStaggered,
  faGripVertical,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownCustomToggle from './DropdownCustomToggle';
import { classNames } from 'utils';
import { setChecksheetRequirementList } from 'state/slices/checksheetSlice';
import { useMutation } from '@tanstack/react-query';
import { checksheetUpdate } from 'apis/checksheetAPIs';
import useServiceAuth from 'auth/useServiceAuth';
// import { createPortal } from 'react-dom';

const Droppable = ({ id, children }) => {
  const { setNodeRef } = useDroppable({
    id: id,
  });

  return (
    <div
      ref={setNodeRef}
      // className={classNames(isOver && over !== id && 'bg-gray-2 ')}
    >
      {children}
    </div>
  );
};

const Sortable = ({ id, children }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    backgroundColor: 'white',
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {children}
    </div>
  );
};

const DraggableSubsection = ({
  id,
  subsection,
  sectionId,
  index,
  array,
  componentList,
  setComponentList,
}) => {
  const componentsKeyValue = Object.entries(componentList);
  const sectionPosition = componentsKeyValue.findIndex(
    ([id, _]) => id === sectionId
  );
  let previousSectionId = null,
    nextSectionId = null;
  if (sectionPosition > 0)
    previousSectionId = componentsKeyValue[sectionPosition - 1][0];
  if (sectionPosition < componentsKeyValue.length - 1)
    nextSectionId = componentsKeyValue[sectionPosition + 1][0];

  const handleMoveUp = () => {
    if (index > 0) {
      setComponentList((sections) => ({
        ...sections,
        [sectionId]: arrayMove(sections[sectionId], index, index - 1),
      }));
    } else if (sectionPosition > 0) {
      const next = { ...componentList };
      next[sectionId].splice(index, 1);
      next[previousSectionId].push([id, subsection]);
      setComponentList({ ...next });
    }
  };

  const handleMoveDown = () => {
    if (index < array.length - 1) {
      setComponentList((sections) => ({
        ...sections,
        [sectionId]: arrayMove(sections[sectionId], index, index + 1),
      }));
    } else if (sectionPosition < componentsKeyValue.length - 1) {
      const next = { ...componentList };
      next[sectionId].splice(index, 1);
      next[nextSectionId].unshift([id, subsection]);
      setComponentList({ ...next });
    }
  };

  return (
    <div className="d-flex gap-2 justify-content-between align-items-center border-top border-gray-4 ms-3 py-1">
      <div key={id} role="button" className="d-flex gap-2 align-items-center">
        <FontAwesomeIcon icon={faGripVertical} className="text-gray-4" />
        <div className="fw-bold">{subsection.subsectionName}</div>
      </div>
      <div className="d-flex gap-1 align-items-center">
        <FontAwesomeIcon
          icon={faArrowUp}
          className={classNames(
            index === 0 && sectionPosition === 0
              ? 'pe-none text-gray-4'
              : 'text-maroon'
          )}
          onClick={handleMoveUp}
        />
        <FontAwesomeIcon
          icon={faArrowDown}
          className={classNames(
            index === array.length - 1 &&
              sectionPosition === componentsKeyValue.length - 1
              ? 'pe-none text-gray-4'
              : 'text-maroon'
          )}
          onClick={handleMoveDown}
        />
      </div>
    </div>
  );
};

const ComponentHead = ({
  id,
  subsections,
  componentList,
  setComponentList,
  showDivider,
}) => {
  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );

  let componentName = '';

  if (templateComponentObjects[id]) {
    componentName = JSON.parse(
      templateComponentObjects[id].details
    ).componentName;
  }

  return (
    <>
      {/* <Droppable id={id}> */}
      <div className={classNames(showDivider && 'border-bottom border-gray-4')}>
        <SortableContext
          items={subsections.map(([item]) => item)}
          strategy={verticalListSortingStrategy}
        >
          <Droppable id={id}>
            <div className="fw-bold py-1">{componentName}</div>
          </Droppable>
          {subsections.map(([subId, subsection], index, array) => (
            <Sortable key={subId} id={subId}>
              <DraggableSubsection
                id={subId}
                subsection={subsection}
                sectionId={id}
                index={index}
                array={array}
                componentList={componentList}
                setComponentList={setComponentList}
              />
            </Sortable>
          ))}
          <Droppable id={id} />
        </SortableContext>
      </div>
      {/* </Droppable> */}
    </>
  );
};

const ChecksheetComponentOptions = ({ id }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const templateComponentIds = useSelector(
    (state) => state.template.templateComponentIds
  );
  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );
  const checksheetRequirements = useSelector(
    (state) => state.checksheet.checksheetRequirementList
  );
  const checksheetState = useSelector((state) => state.checksheet);

  // const [activeId, setActiveId] = useState(null);
  // const [activeSubsectionName, setActiveSubsectionName] = useState(null);
  const [componentList, setComponentList] = useState({});
  const [showDropdown, setShowDropdown] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const { mutate: updateChecksheet } = useMutation({
    mutationFn: checksheetUpdate,
  });

  const handleReorderSubsections = async () => {
    const next = { ...checksheetRequirements };

    for (var property in next) {
      const templateSection = JSON.parse(
        templateComponentObjects[property].details
      );
      const checksheetSection = checksheetRequirements[property];
      const reorderedSubsections = Object.fromEntries(componentList[property]);
      const templateSubsections = {};

      if (!!checksheetSection?.subsections?.[property])
        templateSubsections[property] = {
          ...checksheetSection.subsections[property],
        };

      for (var key of Object.keys(templateSection.subsections))
        templateSubsections[key] = {
          ...checksheetSection.subsections[key],
        };

      const nonemptyTemplateSubsections = Object.fromEntries(
        Object.entries(templateSubsections).filter(
          ([_, value]) => !!Object.keys(value).length
        )
      );

      next[property] = {
        ...checksheetSection,
        subsections: {
          ...nonemptyTemplateSubsections,
          ...reorderedSubsections,
        },
      };
    }

    dispatch(setChecksheetRequirementList(next));
    setShowDropdown(false);

    const jsonData = {
      year: checksheetState.year.value,
      templateId: checksheetState.templateId,
      checksheetName: checksheetState.checksheetName,
      programType: checksheetState.programType,
      acadplan: checksheetState.acadplan,
      acadsubplan: checksheetState.acadsubplan,
      college: checksheetState.college,
      department: checksheetState.department,
      componentRequirements: next,
      courseLists: checksheetState.courseLists,
    };

    const token = await getAccessToken();
    updateChecksheet({ id: checksheetState.checksheetId, jsonData, token });
  };

  const handleResetSubsectionOrder = () => {
    const next = {};

    if (checksheetRequirements)
      templateComponentIds.forEach((compId) => {
        const component = checksheetRequirements[compId];

        if (component) {
          const subsectionList = [];

          Object.entries(component.subsections).forEach(([id, subsection]) => {
            if (subsection.subsectionName !== '')
              subsectionList.push([id, subsection]);
          });

          next[compId] = subsectionList;
        }
      });

    setComponentList(next);
    setShowDropdown(false);
  };

  const handleRemoveSubsection = async () => {
    let foundSectionId;
    Object.entries(componentList).forEach(([compId, subsections]) => {
      subsections.forEach(([subId, _]) => {
        if (subId === id) foundSectionId = compId;
      });
    });

    const next = { ...checksheetRequirements };
    const nextSection = { ...next[foundSectionId] };
    console.log('Section: ', nextSection);
    const { [id]: targetSubsection, ...otherSubsections } =
      nextSection.subsections;
    nextSection.subsections = otherSubsections;
    next[foundSectionId] = nextSection;
    dispatch(setChecksheetRequirementList(next));

    const jsonData = {
      year: checksheetState.year.value,
      templateId: checksheetState.templateId,
      checksheetName: checksheetState.checksheetName,
      programType: checksheetState.programType,
      acadplan: checksheetState.acadplan,
      acadsubplan: checksheetState.acadsubplan,
      college: checksheetState.college,
      department: checksheetState.department,
      componentRequirements: next,
      courseLists: checksheetState.courseLists,
    };

    console.log(jsonData);

    const token = await getAccessToken();

    updateChecksheet({ id: checksheetState.checksheetId, jsonData, token });
  };

  // function handleDragStart(event) {
  //   let subsection = '';

  //   Object.entries(componentList).forEach(([compId, subsections]) => {
  //     subsections.forEach(([subId, sub]) => {
  //       if (subId === event.active.id) {
  //         subsection = sub;
  //       }
  //     });
  //   });

  //   setActiveSubsectionName(subsection.subsectionName);
  //   setActiveId(event.active.id);
  // }

  const handleDragOver = (event) => {
    const { active, over } = event;
    //   const componentsKeyValue = Object.entries(componentList);
    // const sectionPosition = componentsKeyValue.findIndex(
    //   ([id, _]) => id === sectionId
    // );
    // let previousSectionId = null,
    //   nextSectionId = null;
    // if (sectionPosition > 0)
    //   previousSectionId = componentsKeyValue[sectionPosition - 1][0];
    // if (sectionPosition < componentsKeyValue.length - 1)
    //   nextSectionId = componentsKeyValue[sectionPosition + 1][0];

    if (active.id !== over.id) {
      let activeSectionId, activeSubIndex;
      let overSectionId, overSubIndex;

      Object.entries(componentList).forEach(([sectionId, subsections]) => {
        subsections.forEach(([subId], index) => {
          if (subId === active.id) {
            activeSectionId = sectionId;
            activeSubIndex = index;
          }
          if (subId === over.id) {
            overSectionId = sectionId;
            overSubIndex = index;
          }
        });
      });

      if (!overSectionId) {
        overSectionId = over.id;
        // overSubIndex = 0; // Place it as the first item in the section
        overSubIndex = componentList[overSectionId].length;
      }

      if (activeSectionId !== overSectionId) {
        const next = { ...componentList };
        const activeSubsection = next[activeSectionId].splice(
          activeSubIndex,
          1
        )[0];
        next[overSectionId].splice(overSubIndex, 0, activeSubsection);
        setComponentList({ ...next });
      }
    }
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      let activeSectionId, activeSubIndex;
      let overSectionId, overSubIndex;

      Object.entries(componentList).forEach(([sectionId, subsections]) => {
        subsections.forEach(([subId], index) => {
          if (subId === active.id) {
            activeSectionId = sectionId;
            activeSubIndex = index;
          }
          if (subId === over.id) {
            overSectionId = sectionId;
            overSubIndex = index;
          }
        });
      });

      console.log('Active section ID: ', activeSectionId);
      console.log('Over section ID: ', overSectionId);
      console.log('Active subsection index: ', activeSubIndex);
      console.log('Over subsection index: ', overSubIndex);

      if (!overSectionId) {
        overSectionId = over.id;
        // overSubIndex = 0; // Place it as the first item in the section
        overSubIndex = componentList[overSectionId].length;
      }

      if (activeSectionId === overSectionId) {
        setComponentList((sections) => ({
          ...sections,
          [activeSectionId]: arrayMove(
            sections[activeSectionId],
            activeSubIndex,
            overSubIndex
          ),
        }));
      }
      //  else {
      //   const next = { ...componentList };
      //   const activeSubsection = next[activeSectionId].splice(
      //     activeSubIndex,
      //     1
      //   )[0];
      //   next[overSectionId].splice(overSubIndex, 0, activeSubsection);
      //   setComponentList({ ...next });
      // }
    }
  };

  useEffect(() => {
    const next = {};

    if (checksheetRequirements)
      templateComponentIds.forEach((compId) => {
        const component = checksheetRequirements[compId];

        if (component) {
          const subsectionList = [];

          Object.entries(component.subsections).forEach(([id, subsection]) => {
            if (!!subsection.subsectionName && subsection.subsectionName !== '')
              subsectionList.push([id, subsection]);
          });

          next[compId] = subsectionList;
        }
      });

    setComponentList(next);
  }, [checksheetRequirements, templateComponentIds]);

  return (
    <div className="d-flex align-items-center gap-3">
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <Dropdown.Toggle
          as={DropdownCustomToggle}
          id="dropdown-custom-components"
        >
          <div
            className="text-maroon fw-semibold"
            onClick={handleResetSubsectionOrder}
          >
            <FontAwesomeIcon icon={faBarsStaggered} className="me-1" />
            Reorder
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ width: '345px' }}>
          <Dropdown.Header className="text-gray-7 text-wrap">
            Drag and drop or use the arrows to reorder and move subsections
          </Dropdown.Header>
          <Dropdown.ItemText>
            <div className="position-relative">
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                // onDragStart={handleDragStart}
                onDragOver={handleDragOver}
                onDragEnd={handleDragEnd}
                modifiers={[restrictToVerticalAxis]}
              >
                {!!componentList &&
                  Object.entries(componentList).map(
                    ([id, subsections], index, array) => (
                      <ComponentHead
                        key={id}
                        id={id}
                        subsections={subsections}
                        componentList={componentList}
                        setComponentList={setComponentList}
                        showDivider={index < array.length - 1}
                      />
                    )
                  )}
                {/* <DragOverlay>
                  {activeId ? (
                    <div className="d-flex gap-2 justify-content-between align-items-center border-top border-gray-4 ms-3 py-1">
                      <div
                        key={id}
                        role="button"
                        className="d-flex gap-2 align-items-center"
                      >
                        <FontAwesomeIcon
                          icon={faGripVertical}
                          className="text-gray-4"
                        />
                        <div className="fw-bold">{activeSubsectionName}</div>
                      </div>
                    </div>
                  ) : null}
                </DragOverlay> */}
              </DndContext>
            </div>
          </Dropdown.ItemText>
          <Dropdown.ItemText>
            <div className="d-flex gap-1">
              <Button
                label="Apply changes"
                color="maroon"
                size="small"
                onClick={handleReorderSubsections}
              />
              <Button
                label="Cancel"
                color="gray"
                size="small"
                onClick={handleResetSubsectionOrder}
              />
            </div>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className="text-maroon fw-semibold"
        onClick={handleRemoveSubsection}
        role="button"
      >
        <FontAwesomeIcon icon={faTrash} className="me-1" />
        Remove
      </div>
    </div>
  );
};

export default ChecksheetComponentOptions;
