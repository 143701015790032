import React from 'react';
import ComponentForm from 'components/ComponentForm';

const Component = () => {
  let paramId = null;

  if (window.location.pathname === '/component') {
    const params = new URLSearchParams(window.location.search);
    paramId = params.get('id');
  }

  return (
    <div className="container">
      <ComponentForm id={paramId} />
    </div>
  );
};

export default Component;
