import React, { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { checksheetGetProgressByYear } from 'apis/checksheetAPIs';
import { fetchDplColleges } from 'apis/dplAPIs';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowRight,
  faChevronDown,
  faCircle,
} from '@fortawesome/free-solid-svg-icons';
import useServiceAuth from 'auth/useServiceAuth';
import { setYear } from 'state/slices/settingsSlice';
import { classNames } from 'utils';
import StaticData from './StaticData';
import LoadingSkeleton from './LoadingSkeleton';
import { Modal } from 'react-bootstrap';
import StatusDefinitions from './StatusDefinitions';
import { useNavigate } from 'react-router-dom';

// const PROVOST = 'PROVOST';
// const COLLEGE = 'COLLEGE';
// const DEPARTMENT = 'DEPARTMENT';
// const DARS = 'DARS';

const ProgramSummaryRow = ({
  college,
  values,
  // role,
}) => {
  const navigate = useNavigate();
  const [isSummaryRowOpen, setSummaryRowOpen] = useState(false);

  return (
    <div className="py-1 border-top border-gray-4">
      <div
        className={classNames(
          isSummaryRowOpen && 'fw-bold',
          'summary-row-head row mx-0'
        )}
        onClick={() => setSummaryRowOpen(!isSummaryRowOpen)}
        role="button"
      >
        <div
          className="col-auto fw-bold d-flex gap-1 ps-0"
          style={{ width: '25%' }}
        >
          <FontAwesomeIcon
            icon={faChevronDown}
            className={classNames(isSummaryRowOpen && 'rotate-180')}
            style={{ margin: '4px 0' }}
          />
          <div>{college.alphaSortDescription}</div>
        </div>
        <div className="col-auto text-end" style={{ width: '16%' }}>
          {values?.DEPARTMENT ?? '\u2014'}
        </div>
        <div className="col-auto text-end" style={{ width: '16%' }}>
          {values?.COLLEGE ?? '\u2014'}
        </div>
        <div className="col-auto text-end" style={{ width: '16%' }}>
          {values?.PROVOST ?? '\u2014'}
        </div>
        <div className="col-auto text-end" style={{ width: '13%' }}>
          {values?.DARS ?? '\u2014'}
        </div>
        <div className="col-auto text-end pe-0" style={{ width: '14%' }}>
          {values?.COMPLETE ?? '\u2014'}
        </div>
      </div>
      <div
        className={classNames(
          isSummaryRowOpen && 'expansion-open',
          'summary-row-expansion'
        )}
      >
        <div
          className="row mx-0"
          onClick={() =>
            navigate(
              `/programs?college=${college.acadOrgCode}&program=undergrad`
            )
          }
          role="button"
        >
          <div className="col-auto ps-0" style={{ width: '25%' }}>
            <div className="ms-3 text-gray-6" style={{ width: 'fit-content' }}>
              Undergraduate degrees
            </div>
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.majors?.DEPARTMENT ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.majors?.COLLEGE ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.majors?.PROVOST ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '13%' }}>
            {values?.majors?.DARS ?? '\u2014'}
          </div>
          <div className="col-auto text-end pe-0" style={{ width: '14%' }}>
            {values?.majors?.COMPLETE ?? '\u2014'}
          </div>
        </div>
        <div
          className="row mx-0"
          onClick={() =>
            navigate(`/programs?college=${college.acadOrgCode}&program=minor`)
          }
          role="button"
        >
          <div className="col-auto ps-0" style={{ width: '25%' }}>
            <div className="ms-3 text-gray-6" style={{ width: 'fit-content' }}>
              Minors
            </div>
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.minors?.DEPARTMENT ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.minors?.COLLEGE ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.minors?.PROVOST ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '13%' }}>
            {values?.minors?.DARS ?? '\u2014'}
          </div>
          <div className="col-auto text-end pe-0" style={{ width: '14%' }}>
            {values?.minors?.COMPLETE ?? '\u2014'}
          </div>
        </div>
        <div
          className="row mx-0"
          onClick={() =>
            navigate(`/programs?college=${college.acadOrgCode}&program=cert`)
          }
          role="button"
        >
          <div className="col-auto ps-0" style={{ width: '25%' }}>
            <div className="ms-3 text-gray-6" style={{ width: 'fit-content' }}>
              Certificates
            </div>
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.certs?.DEPARTMENT ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.certs?.COLLEGE ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '16%' }}>
            {values?.certs?.PROVOST ?? '\u2014'}
          </div>
          <div className="col-auto text-end" style={{ width: '13%' }}>
            {values?.certs?.DARS ?? '\u2014'}
          </div>
          <div className="col-auto text-end pe-0" style={{ width: '14%' }}>
            {values?.certs?.COMPLETE ?? '\u2014'}
          </div>
        </div>
      </div>
    </div>
  );
};

const ProgramsSummary = () => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const year = useSelector((state) => state.settings.year);
  const [collegeList, setCollegeList] = useState([]);
  // const [departmentList, setDepartmentList] = useState([]);
  const [progresses, setProgresses] = useState({});
  const [progressTotals, setProgressTotals] = useState({});
  // const [collegeName, setCollegeName] = useState(null);
  // const [departmentName, setDepartmentName] = useState(null);
  const [error, setError] = useState('');
  const [showDefinitions, setShowDefinition] = useState(false);

  // const role = useSelector((state) => state.user.role);
  // const userCollege = useSelector((state) => state.user.college);
  // const userDepartment = useSelector((state) => state.user.department);

  const {
    data: checksheetProgressData,
    error: getChecksheetProgressError,
    isError: getChecksheetProgressIsError,
    isPending: getChecksheetIsLoading,
    isSuccess: getChecksheetProgressIsSuccess,
  } = useQuery({
    queryKey: ['checksheet-progress', year],
    queryFn: async () => {
      const token = await getAccessToken();
      return checksheetGetProgressByYear({ year, token });
    },
    enabled: !!year,
  });

  const {
    data: fetchCollegesData,
    error: fetchCollegesError,
    isError: fetchCollegesIsError,
    isSuccess: fetchCollegesIsSuccess,
  } = useQuery({
    queryKey: ['colleges'],
    queryFn: fetchDplColleges,
  });

  const handleSetYear = (e) => {
    dispatch(setYear(e.target.value));
  };

  const handleOpenDefinitions = () => setShowDefinition(true);
  const handleCloseDefinitions = () => setShowDefinition(false);

  useEffect(() => {
    if (fetchCollegesIsError) setError(fetchCollegesError.message);
    if (fetchCollegesIsSuccess) {
      const sorted = fetchCollegesData.toSorted((a, b) =>
        a.alphaSortDescription.localeCompare(b.alphaSortDescription)
      );

      setCollegeList(sorted);
    }
  }, [
    fetchCollegesData,
    fetchCollegesError,
    fetchCollegesIsError,
    fetchCollegesIsSuccess,
  ]);

  useEffect(() => {
    if (getChecksheetProgressIsError)
      setError(getChecksheetProgressError.message);
    if (getChecksheetProgressIsSuccess) {
      // if (role.includes(PROVOST)) {
      const { totals, ...colleges } = checksheetProgressData;

      setProgresses(colleges);
      setProgressTotals(totals);

      // } else if (
      //   role.includes(COLLEGE) &&
      //   !!checksheetProgressData[userCollege]
      // ) {
      //   const { departments, ...totals } = checksheetProgressData[userCollege];
      //   setProgresses(departments);
      //   setProgressTotals(totals);
      // } else if (
      //   role.includes(DEPARTMENT) &&
      //   checksheetProgressData[userCollege]?.[userDepartment]
      // ) {
      //   const { majors, minors, certs, ...totals } =
      //     checksheetProgressData[userCollege][userDepartment];
      //   setProgresses({ [userDepartment]: { majors, minors, certs } });
      //   setProgressTotals(totals);
      // }
      // else if (role.includes('DARS')) {}
      // else {
      //   const { totals, ...other } = checksheetProgressData;
      //   setProgresses(other);
      //   setProgressTotals(totals);
      // }
    }
  }, [
    checksheetProgressData,
    collegeList,
    getChecksheetProgressError,
    getChecksheetProgressIsError,
    getChecksheetProgressIsSuccess,
    // role,
    // userCollege,
    // userDepartment,
  ]);

  // useEffect(() => {
  //   if (role.includes(COLLEGE) || role.includes(DEPARTMENT)) {
  //     const userCollegeFound = collegeList.find(
  //       (college) => college.acadOrgCode === userCollege
  //     );

  //     if (userCollegeFound) {
  //       const next = [];

  //       userCollegeFound.childAcadOrgs.forEach((department) => {
  //         next.push(department);

  //         department.childAcadOrgs.forEach((childDepartment) => {
  //           next.push(childDepartment);
  //         });
  //       });

  //       setDepartmentList(next);
  //     }
  //   }
  // }, [collegeList, role, userCollege]);

  // useEffect(() => {
  //   if (!!collegeList) {
  //     const collegeFound = collegeList.find(
  //       (college) => college.acadOrgCode === userCollege
  //     );

  //     if (!!collegeFound) setCollegeName(collegeFound.description);
  //   }
  // }, [userCollege, collegeList]);

  // useEffect(() => {
  //   if (!!departmentList) {
  //     const departmentFound = departmentList.find(
  //       (department) => department.acadOrgCode === userDepartment
  //     );

  //     if (!!departmentFound) setDepartmentName(departmentFound.description);
  //   }
  // }, [userDepartment, departmentList]);

  if (getChecksheetProgressIsError || fetchCollegesIsError)
    return (
      <div>
        <div>An error has occurred: {error}</div>
        <div>
          Please refresh the page. If the error persists, please try again
          later.
        </div>
      </div>
    );

  return (
    <>
      <div className="bg-white border border-gray-3 p-6">
        <div className="d-flex gap-1 justify-content-between align-items-center mb-3">
          <div>
            <div className="d-flex">
              {/* {(role.includes(COLLEGE) || role.includes(DEPARTMENT)) && (
                <div className="fw-bold fs-5 lh-1 mb-1">
                  {role.includes(DEPARTMENT) && departmentName + ' | '}
                  {collegeName}
                </div>
              )} */}
            </div>
            <div className="fw-bold fs-1 lh-1">Programs summary</div>
          </div>
          <div className="d-flex gap-2">
            <div className="fw-bold">Catalog year</div>
            <select value={year} onChange={(e) => handleSetYear(e)}>
              {StaticData.yearList.map((year) => (
                <option key={year.value} value={year.value}>
                  {year.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <div
            className="button-link"
            onClick={handleOpenDefinitions}
            role="button"
          >
            What do these statuses mean?
          </div>
        </div>
        <div>
          <div className="row py-2 mx-0">
            <div
              className="col-auto d-flex align-items-end fw-bold ps-0"
              style={{ width: '25%' }}
            >
              <div>College / school</div>
            </div>
            <div
              className="col-auto fw-bold d-flex gap-1 justify-content-end"
              style={{ width: '16%' }}
            >
              <FontAwesomeIcon
                icon={faCircle}
                className="text-gray-4"
                width={'12px'}
                style={{ margin: '4px 0' }}
              />
              <div className="text-end" style={{ maxWidth: '160px' }}>
                Pending department submission
              </div>
            </div>
            <div
              className="col-auto fw-bold d-flex gap-3 justify-content-between"
              style={{ width: '16%' }}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-gray-4"
                width={'12px'}
              />
              <div className="d-flex gap-1">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-gray-7"
                  width={'12px'}
                  style={{ margin: '4px 0' }}
                />
                <div className="text-end" style={{ maxWidth: '150px' }}>
                  Pending college / school submission
                </div>
              </div>
            </div>
            <div
              className="col-auto fw-bold d-flex gap-3 justify-content-between"
              style={{ width: '16%' }}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-gray-4"
                width={'12px'}
              />
              <div className="d-flex gap-1">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-warning"
                  width={'12px'}
                  style={{ margin: '4px 0' }}
                />
                <div className="text-end" style={{ maxWidth: '120px' }}>
                  Pending provost review
                </div>
              </div>
            </div>
            <div
              className="col-auto fw-bold d-flex gap-3 justify-content-between"
              style={{ width: '13%' }}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-gray-4"
                width={'12px'}
              />
              <div className="d-flex gap-1">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-info"
                  width={'12px'}
                  style={{ margin: '4px 0' }}
                />
                <div className="text-end" style={{ maxWidth: '120px' }}>
                  Pending DARS encoding
                </div>
              </div>
            </div>
            <div
              className="col-auto fw-bold d-flex gap-3 justify-content-between pe-0"
              style={{ width: '14%' }}
            >
              <FontAwesomeIcon
                icon={faArrowRight}
                className="text-gray-4"
                width={'12px'}
              />
              <div className="d-flex gap-1">
                <FontAwesomeIcon
                  icon={faCircle}
                  className="text-success"
                  width={'12px'}
                  style={{ margin: '4px 0' }}
                />
                Completed
              </div>
            </div>
          </div>
          {getChecksheetIsLoading ? (
            <>
              {[...Array(5)].map((item, index) => (
                <LoadingSkeleton key={index} width={'100%'} height={'37px'} />
              ))}
            </>
          ) : (
            getChecksheetProgressIsSuccess && (
              <div>
                {/*  Filter method is temporary until I figure out a better way */}
                {collegeList
                  .filter(
                    (college) =>
                      college.acadOrgCode !== 'COE' &&
                      college.acadOrgCode !== 'CGC' &&
                      college.acadOrgCode !== 'CLW' &&
                      college.acadOrgCode !== 'CTPH' &&
                      college.acadOrgCode !== 'CPROVOST'
                  )
                  .map((college, index) => (
                    <ProgramSummaryRow
                      key={index}
                      college={college}
                      values={progresses[college.acadOrgCode]}
                      // role={role}
                    />
                  ))}
              </div>
            )
          )}
          <div className="row pt-1 mt-1 mx-0 border-top border-gray-7 border-2">
            {getChecksheetIsLoading ? (
              <LoadingSkeleton width={'100%'} height={'39px'} />
            ) : (
              <>
                <div
                  className="col-auto fw-bold d-flex gap-1 ps-0"
                  style={{ width: '25%' }}
                >
                  Total
                </div>
                <div className="col-auto text-end" style={{ width: '16%' }}>
                  {progressTotals?.DEPARTMENT ?? '\u2014'}
                </div>
                <div className="col-auto text-end" style={{ width: '16%' }}>
                  {progressTotals?.COLLEGE ?? '\u2014'}
                </div>
                <div className="col-auto text-end" style={{ width: '16%' }}>
                  {progressTotals?.PROVOST ?? '\u2014'}
                </div>
                <div className="col-auto text-end" style={{ width: '13%' }}>
                  {progressTotals?.DARS ?? '\u2014'}
                </div>
                <div
                  className="col-auto text-end pe-0"
                  style={{ width: '14%' }}
                >
                  {progressTotals?.COMPLETE ?? '\u2014'}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      <Modal show={showDefinitions} onHide={handleCloseDefinitions}>
        <StatusDefinitions handleClose={handleCloseDefinitions} />
      </Modal>
    </>
  );
};

export default ProgramsSummary;
