import React, { Fragment, useEffect, useState } from 'react';
import {
  // useDispatch,
  useSelector,
} from 'react-redux';
import { Doughnut } from 'react-chartjs-2';
import {
  faCheck,
  // faClipboardCheck,
  // faList,
  faPlus,
  faTriangleExclamation,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import TabPanel from './TabPanel';
import { classNames, createDoughnutData } from 'utils';
import { Modal } from 'react-bootstrap';
import CourseListForm from 'components/CourseListForm';
import ChecksheetHistory from './ChecksheetHistory';

// import GeneralStudy from 'components/RequirementGeneralStudy';
// import {
//   setCourses,
//   setCourseListId,
//   setCourseListName,
// } from 'state/slices/courseListSlice';

const ChecksheetValidation = ({ checksheetId, isLocked, holdsLock }) => {
  const [showModal, setShowModal] = useState(false);

  const courseLists = useSelector((state) => state.checksheet.courseLists);

  console.log('courseLists on validation menu: ', courseLists);
  const templateSettings = useSelector(
    (state) => state.template.templateSettings
  );
  const totalHours = useSelector((state) => state.validation.totalHours);
  const totalUpperDivisionHours = useSelector(
    (state) => state.validation.totalUpperDivisionHours
  );
  const generalStudiesList = useSelector(
    (state) => state.validation.generalStudiesList
  );
  const numberOfRulesValid = useSelector(
    (state) => state.validation.numberOfRulesValid
  );

  console.log('general studies Array: ', generalStudiesList);
  console.log('creditHours: ', totalHours);
  console.log('checksheetId: ', checksheetId);
  console.log('numberOfRulesValid: ', numberOfRulesValid);

  const [showCourseListModal, setShowCourseListModal] = useState();
  // const [showGeneralStudyModal, setShowGeneralStudyModal] = useState();
  const [selectedCourseListId, setSelectedCourseListId] = useState();

  // const dispatch = useDispatch();

  const handleCloseModal = () => setShowModal(false);

  // const handleCreateNewCourseList = () => {
  //   dispatch(setCourseListId(null));
  //   dispatch(setCourses([]));
  //   dispatch(setCourseListName(''));
  //   setShowModal(true);
  //   setShowCourseListModal(true);
  // };

  // const handleAddNewGeneralStudy = () => {
  //   setShowModal(true);
  //   setShowCourseListModal(false);
  //   setShowGeneralStudyModal(true);
  // };

  const handleEditCourseList = (key) => {
    // console.log('Editing Course List with key:', key);
    // dispatch(setCourseListId(key));
    // dispatch(setCourseListName(courseLists[key]?.courseListName || ''));
    // dispatch(setCourses(courseLists[key]?.courses || []));
    setSelectedCourseListId(key);
    setShowCourseListModal(true);
    setShowModal(true);
  };

  useEffect(() => {
    // to handle unsaved course list data. either it is a new course list or an existing one
    const unsavedData = sessionStorage.getItem('unsavedCourses');
    console.log('Unsaved data in validation tab:', unsavedData);
    if (unsavedData) {
      const newCourseListName = JSON.parse(unsavedData).courseListName;

      let foundCourseList = false;
      for (const courseListId in courseLists) {
        if (courseLists[courseListId].courseListName === newCourseListName) {
          foundCourseList = true;
          setSelectedCourseListId(courseListId);
        }
      }
      if (!foundCourseList) {
        handleEditCourseList(null);
      }
    }
  }, [courseLists]);

  useEffect(() => {
    console.log('general studies', generalStudiesList);
  }, [generalStudiesList]);

  return (
    <>
      {checksheetId && (
        <div
          className="sidebar-menu position-fixed d-flex flex-column border-end border-bottom border-gray-4 bg-gray-1"
          style={{ width: '20%' }}
        >
          <TabPanel
            tabs={['Rules', 'Course Lists', 'History']}
            // icons={[faClipboardCheck, faList]}
            showExtraBorder={false}
          >
            <div className="d-flex flex-column gap-1 mt-1 overflow-y-hidden">
              <h4 className="px-3">Validation Rules</h4>
              {/* <div className="text-gray-6 pt-2" style={{ fontSize: '14px' }}>
                Ensure that this checksheet satisfies all template rules prior
                to sequencing.
              </div> */}
              {/* <div className="px-4">
                <div
                  style={{
                    width: '200px',
                    position: 'relative',
                    display: 'flex',
                    textAlign: 'center',
                  }}
                >
                  <div
                    style={{
                      position: 'absolute',
                      top: 80,
                      left: 0,
                      right: 0,
                      bottom: 0,
                    }}
                  >
                    {numberOfRulesValid}/{generalStudiesList.length ?? 0}
                    <br />
                    Rules satisfied
                  </div>
                  <Doughnut
                    data={createDoughnutData(
                      numberOfRulesValid,
                      generalStudiesList.length ?? 0
                    )}
                    options={{
                      cutout: '80%', // Adjust this value to change the thickness of the Doughnut
                    }}
                  />
                </div>
              </div> */}

              <div className="scrollbox">
                <div className="scrollbox-content">
                  <div>
                    <div
                      className={classNames(
                        !!templateSettings.maxHours &&
                          templateSettings.maxHours >= templateSettings.hours &&
                          totalHours > templateSettings.maxHours &&
                          totalHours > templateSettings.hours &&
                          'bg-danger-custom',
                        'd-flex gap-1 px-3 py-1'
                      )}
                    >
                      {!templateSettings.hours && !templateSettings.maxHours ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                          style={{ width: '24px', height: '24px' }}
                        />
                      ) : (
                        <div style={{ paddingTop: '2px' }}>
                          <Doughnut
                            data={createDoughnutData(
                              totalHours,
                              !!templateSettings.maxHours &&
                                templateSettings.maxHours >
                                  templateSettings.hours
                                ? templateSettings.maxHours
                                : !!templateSettings.hours
                                  ? templateSettings.hours
                                  : 0,
                              !!templateSettings.maxHours &&
                                templateSettings.maxHours >=
                                  templateSettings.hours &&
                                totalHours > templateSettings.maxHours &&
                                totalHours > templateSettings.hours
                            )}
                            style={{ width: '24px' }}
                          />
                        </div>
                      )}
                      <div>
                        <div className="fw-bold">
                          <span>Total Credit Hours</span>
                          {!!templateSettings.hours &&
                            !!templateSettings.maxHours &&
                            templateSettings.maxHours >
                              templateSettings.hours &&
                            templateSettings.hours !==
                              templateSettings.maxHours && (
                              <span> ({templateSettings.hours} minimum)</span>
                            )}
                        </div>
                        <div>
                          {!templateSettings.hours &&
                          !templateSettings.maxHours ? (
                            <span>{totalHours} (No minimum or maximum)</span>
                          ) : (
                            <span>
                              {totalHours}
                              <span className="text-gray-5"> / </span>
                              {templateSettings.maxHours >
                              templateSettings.hours
                                ? templateSettings.maxHours
                                : templateSettings.hours}
                              {templateSettings.hours !==
                                templateSettings.maxHours &&
                                (!!templateSettings.maxHours &&
                                templateSettings.maxHours >
                                  templateSettings.hours ? (
                                  <span> maximum</span>
                                ) : (
                                  !!templateSettings.hours &&
                                  templateSettings.hours >
                                    templateSettings.maxHours && (
                                    <span> minimum</span>
                                  )
                                ))}
                              {!!templateSettings.hours &&
                                !templateSettings.maxHours &&
                                totalHours > templateSettings.hours && (
                                  <span className="text-success-custom bg-success-custom mx-1 px-1">
                                    Exceeds
                                  </span>
                                )}
                            </span>
                          )}
                        </div>
                        {!!templateSettings.maxHours &&
                          templateSettings.maxHours >= templateSettings.hours &&
                          totalHours > templateSettings.maxHours &&
                          totalHours > templateSettings.hours && (
                            <div className="d-flex gap-1 align-items-center text-danger-custom">
                              <FontAwesomeIcon icon={faTriangleExclamation} />
                              {templateSettings.maxHours >
                              templateSettings.hours ? (
                                <span>
                                  Cannot exceed {templateSettings.maxHours}{' '}
                                  credit hours
                                </span>
                              ) : (
                                templateSettings.maxHours ===
                                  templateSettings.hours && (
                                  <span>
                                    Must equal {templateSettings.maxHours}{' '}
                                    credit hours
                                  </span>
                                )
                              )}
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="d-flex gap-1 px-3 py-1">
                      {!templateSettings.upperDivisionHours ? (
                        <FontAwesomeIcon
                          icon={faCheck}
                          className="text-success"
                          style={{ width: '24px', height: '24px' }}
                        />
                      ) : (
                        <div style={{ paddingTop: '2px' }}>
                          <Doughnut
                            data={createDoughnutData(
                              totalUpperDivisionHours,
                              templateSettings.upperDivisionHours ?? 0
                            )}
                            style={{ width: '24px' }}
                          />
                        </div>
                      )}
                      <div>
                        <div className="fw-bold">
                          <span>Total Upper Division Credit Hours</span>
                        </div>
                        <div>
                          {!templateSettings.upperDivisionHours ? (
                            <span>{totalUpperDivisionHours} (No minimum)</span>
                          ) : (
                            <span>
                              {totalUpperDivisionHours}
                              <span className="text-gray-5"> / </span>
                              {templateSettings.upperDivisionHours} minimum
                            </span>
                          )}
                          {!!templateSettings.upperDivisionHours &&
                            totalUpperDivisionHours >
                              templateSettings.upperDivisionHours && (
                              <span className="text-success-custom bg-success-custom mx-1 px-1">
                                Exceeds
                              </span>
                            )}
                        </div>
                      </div>
                    </div>

                    {generalStudiesList &&
                      generalStudiesList
                        .toSorted((a, b) =>
                          Object.keys(a)[0].localeCompare(Object.keys(b)[0])
                        )
                        .map((obj, index) => (
                          <Fragment key={index}>
                            {Object.entries(obj).map(([key, value]) => (
                              <Fragment key={key}>
                                {value.creditHours.goal >
                                  value.upperDivisionHours.goal && (
                                  <div className="d-flex gap-1 px-3 py-1">
                                    {!value.creditHours.goal ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-success"
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          paddingTop: '2px',
                                        }}
                                      >
                                        <Doughnut
                                          data={createDoughnutData(
                                            value.creditHours.actual,
                                            value.creditHours.goal ?? 0
                                          )}
                                          style={{ width: '24px' }}
                                        />
                                      </div>
                                    )}
                                    <div>
                                      <div className="fw-bold">
                                        <span>
                                          {value.gs_label} ({key})
                                        </span>
                                      </div>
                                      <div>
                                        {!value.creditHours.goal ? (
                                          <span>
                                            {value.creditHours.actual} credit
                                            hours (No minimum)
                                          </span>
                                        ) : (
                                          <span>
                                            {value.creditHours.actual}
                                            <span className="text-gray-5">
                                              {' '}
                                              /{' '}
                                            </span>
                                            {value.creditHours.goal} minimum
                                            credit hours
                                          </span>
                                        )}
                                        {!!value.creditHours.goal &&
                                          value.creditHours.actual >
                                            value.creditHours.goal && (
                                            <span className="text-success-custom bg-success-custom mx-1 px-1">
                                              Exceeds
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {value.upperDivisionHours.goal !== 0 && (
                                  <div className="d-flex gap-1 px-3 py-1">
                                    {!value.upperDivisionHours.goal ? (
                                      <FontAwesomeIcon
                                        icon={faCheck}
                                        className="text-success"
                                        style={{
                                          width: '24px',
                                          height: '24px',
                                        }}
                                      />
                                    ) : (
                                      <div
                                        style={{
                                          paddingTop: '2px',
                                        }}
                                      >
                                        <Doughnut
                                          data={createDoughnutData(
                                            value.upperDivisionHours.actual,
                                            value.upperDivisionHours.goal ?? 0
                                          )}
                                          style={{ width: '24px' }}
                                        />
                                      </div>
                                    )}
                                    <div>
                                      <div className="fw-bold">
                                        <span>
                                          {value.gs_label} ({key}) - Upper
                                          Division
                                        </span>
                                      </div>
                                      <div>
                                        {!value.upperDivisionHours.goal ? (
                                          <span>
                                            {value.upperDivisionHours.actual}{' '}
                                            credit hours (No minimum)
                                          </span>
                                        ) : (
                                          <span>
                                            {value.upperDivisionHours.actual}
                                            <span className="text-gray-5">
                                              {' '}
                                              /{' '}
                                            </span>
                                            {value.upperDivisionHours.goal}{' '}
                                            minimum credit hours
                                          </span>
                                        )}
                                        {!!value.upperDivisionHours.goal &&
                                          value.upperDivisionHours.actual >
                                            value.upperDivisionHours.goal && (
                                            <span className="text-success-custom bg-success-custom mx-1 px-1">
                                              Exceeds
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </Fragment>
                            ))}

                            {/* <div key={index}>
                            {Object.entries(obj).map(([key, value]) => (
                              // <div key={key}>
                              //   <strong>{key}:</strong> {JSON.stringify(value)}
                              // </div>
                              <div key={key} className="mb-2 row">
                                <div
                                  style={{
                                    width: '50px',
                                    margin: '0 -10px 0 0',
                                  }}
                                >
                                  <div className="d-flex align-items-center">
                                    <div style={{ width: '24px' }}>
                                      <Doughnut
                                        data={createDoughnutData(
                                          value.creditHours.actual,
                                          value.creditHours.goal ?? 0
                                        )}
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div style={{ width: '40px', padding: '0' }}>
                                  <span>
                                    {value.creditHours.actual}/
                                    {value.creditHours.goal}
                                  </span>
                                </div>
                                <div
                                  style={{ width: '170px', padding: '0' }}
                                ></div>
                              </div>
                            ))}
                          </div> */}
                          </Fragment>
                        ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-1">
              <div className="d-flex gap-1 align-items-center px-3">
                <h4>Manage Course Lists</h4>
                {isLocked && holdsLock && (
                  <span
                    style={{ width: '10px', padding: '5px' }}
                    onClick={() => handleEditCourseList(null)}
                    className="text-maroon"
                    title="New Course List"
                    role="button"
                  >
                    <FontAwesomeIcon icon={faPlus} />
                  </span>
                )}
              </div>
              {courseLists &&
                Object.keys(courseLists).map((id) => (
                  <div key={id} className="d-flex ps-4 pt-1">
                    <div
                      className="px-1 underline-hover"
                      onClick={() => handleEditCourseList(id)}
                      role="button"
                    >
                      {courseLists[id]?.courseListName ||
                        'Untitled course list'}
                    </div>
                  </div>
                ))}
              <ChecksheetHistory checksheetId={checksheetId} tab="courseList" />
            </div>

            <ChecksheetHistory checksheetId={checksheetId} tab="history" />
          </TabPanel>
        </div>
      )}

      <Modal show={showModal} onHide={handleCloseModal} backdrop="static">
        {showCourseListModal ? (
          <CourseListForm
            courseListId={selectedCourseListId}
            handleClose={handleCloseModal}
            isLocked={isLocked}
            holdsLock={holdsLock}
          />
        ) : (
          // ) : showGeneralStudyModal ? (
          //   <GeneralStudy
          //   />
          <div></div>
        )}
      </Modal>
    </>
  );
};

export default ChecksheetValidation;
