import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown } from 'react-bootstrap';
import { Button } from '@asu/components-core';
import {
  setTemplateComponentIds,
  removeTemplateComponent,
} from 'state/slices/templateSlice';
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import {
  restrictToVerticalAxis,
  restrictToParentElement,
} from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import {
  faArrowDown,
  faArrowUp,
  faBarsStaggered,
  faGripVertical,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DropdownCustomToggle from './DropdownCustomToggle';
import { classNames } from 'utils';

const SortableItem = ({ id, index, items, setItems }) => {
  let showDivider = true;
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
  };

  const templateComponentObjects = useSelector(
    (state) => state.template.templateComponentObjects
  );

  let componentName = '';

  if (templateComponentObjects[id]) {
    componentName = JSON.parse(
      templateComponentObjects[id].details
    ).componentName;
  }

  if (index === items.length - 1) {
    showDivider = false;
  }

  const handleMoveUp = () => {
    setItems((items) => {
      return arrayMove(items, index, index - 1);
    });
  };

  const handleMoveDown = () => {
    setItems((items) => {
      return arrayMove(items, index, index + 1);
    });
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
      <div className="d-flex gap-3 justify-content-between align-items-center py-1 bg-white">
        <div className="d-flex gap-3 align-items-center">
          <FontAwesomeIcon icon={faGripVertical} className="text-gray-4" />{' '}
          <div className="fw-bold">{componentName}</div>
        </div>
        <div className="d-flex gap-1 align-items-center">
          <FontAwesomeIcon
            icon={faArrowUp}
            className={classNames(
              index === 0 ? 'pe-none text-gray-4' : 'text-maroon'
            )}
            onClick={handleMoveUp}
          />
          <FontAwesomeIcon
            icon={faArrowDown}
            className={classNames(
              index === items.length - 1 ? 'pe-none text-gray-4' : 'text-maroon'
            )}
            onClick={handleMoveDown}
          />
        </div>
      </div>
      {showDivider && (
        <hr className="m-0" onClick={() => console.log('Down')} />
      )}
    </div>
  );
};

const TemplateComponentOptions = ({ id }) => {
  const templateComponentIds = useSelector(
    (state) => state.template.templateComponentIds
  );

  const [items, setItems] = useState([...templateComponentIds]);
  const [showDropdown, setShowDropdown] = useState(false);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 10,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const dispatch = useDispatch();

  const handleReorderComponents = () => {
    dispatch(setTemplateComponentIds(items));
    setShowDropdown(false);
  };

  const handleResetComponentOrder = () => {
    setItems([...templateComponentIds]);
    setShowDropdown(false);
  };

  const handleRemoveComponent = () => {
    setItems(items.filter((item) => item !== id));
    dispatch(removeTemplateComponent(id));
  };

  const handleDragEnd = (e) => {
    const { active, over } = e;
    if (active.id !== over.id) {
      console.log(`Switching ${active.id} with ${over.id}`);
      setItems((items) => {
        const oldIndex = items.indexOf(active.id);
        const newIndex = items.indexOf(over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
  };

  useEffect(() => {
    setItems([...templateComponentIds]);
  }, [templateComponentIds]);

  return (
    <div className="d-flex align-items-center gap-3">
      <Dropdown
        show={showDropdown}
        onToggle={() => setShowDropdown(!showDropdown)}
      >
        <Dropdown.Toggle
          as={DropdownCustomToggle}
          id="dropdown-custom-components"
        >
          <div
            className="text-maroon fw-semibold"
            onClick={handleResetComponentOrder}
          >
            <FontAwesomeIcon icon={faBarsStaggered} className="me-1" />
            Reorder
          </div>
        </Dropdown.Toggle>

        <Dropdown.Menu style={{ width: '345px' }}>
          <Dropdown.Header className="text-gray-7 text-wrap">
            Drag and drop or use the arrows to reorder sections
          </Dropdown.Header>
          <Dropdown.ItemText>
            <DndContext
              sensors={sensors}
              collisionDetection={closestCenter}
              onDragEnd={handleDragEnd}
              modifiers={[restrictToParentElement, restrictToVerticalAxis]}
            >
              <SortableContext
                items={items}
                strategy={verticalListSortingStrategy}
              >
                {items.map((componentId, index, array) => (
                  <SortableItem
                    id={componentId}
                    index={index}
                    items={array}
                    setItems={setItems}
                    key={componentId}
                  />
                ))}
              </SortableContext>
            </DndContext>
          </Dropdown.ItemText>
          <Dropdown.ItemText>
            <div className="d-flex gap-1">
              <Button
                label="Apply changes"
                color="maroon"
                size="small"
                onClick={handleReorderComponents}
              />
              <Button
                label="Cancel"
                color="gray"
                size="small"
                onClick={handleResetComponentOrder}
              />
            </div>
          </Dropdown.ItemText>
        </Dropdown.Menu>
      </Dropdown>
      <div
        className="text-maroon fw-semibold"
        onClick={handleRemoveComponent}
        role="button"
      >
        <FontAwesomeIcon icon={faTrash} className="me-1" />
        Remove
      </div>
    </div>
  );
};

export default TemplateComponentOptions;
