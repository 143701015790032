import React, { Fragment, useState } from 'react';
import { Button } from '@asu/components-core';
import { Dropdown, Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { clearComponent } from 'state/slices/componentSlice';
import { addTemplateComponent } from 'state/slices/templateSlice';
import DropdownCustomToggle from './DropdownCustomToggle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFolderClosed } from '@fortawesome/free-solid-svg-icons';
import ComponentForm from './ComponentForm';

const AddComponentDropdown = () => {
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false);
  const [searchString, setSearchString] = useState('');

  const componentList = useSelector((state) => state.dataLists.componentList);
  const folderList = useSelector((state) => state.dataLists.folderList);
  const year = useSelector((state) => state.settings.year);

  const handleOpenModal = () => {
    dispatch(clearComponent());
    setShowModal(true);
  };
  const handleCloseModal = () => setShowModal(false);

  const handleAddComponent = (component) => {
    const obj = {
      sk: component['sk'].S,
      updatedBy: component['updatedBy'].S,
      updatedOn: component['updatedOn'].S,
      details: component['details'].S,
      pk: component['pk'].S,
    };

    dispatch(addTemplateComponent({ id: component['sk'].S, object: obj }));
  };

  const sortByProperty = (a, b, property) => {
    if (a.details[property] > b.details[property]) return 1;

    if (a.details[property] < b.details[property]) return -1;

    return 0;
  };

  return (
    <>
      <div>
        <Dropdown className="add-component">
          <Dropdown.Toggle
            as={DropdownCustomToggle}
            id="dropdown-custom-components"
          >
            <Button label="Add section" color="gold" size="small" />
          </Dropdown.Toggle>

          <Dropdown.Menu className="py-0" style={{ width: '340px' }}>
            <Dropdown.ItemText>
              <input
                type="text"
                className="w-100 px-3 py-1"
                placeholder="Search library"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />
            </Dropdown.ItemText>
            {folderList
              // .toSorted((a, b) => sortByFolderName(a, b))
              .map((folderName) => (
                <Fragment key={folderName}>
                  <Dropdown.ItemText className="add-component-folder">
                    <div className="d-flex justify-content-between align-items-center px-3 pt-1">
                      <div className="d-flex gap-1">
                        <FontAwesomeIcon
                          icon={faFolderClosed}
                          className="text-gray-5"
                          style={{ margin: '2px 0' }}
                        />
                        <div className="fw-bold">{folderName}</div>
                      </div>
                    </div>
                  </Dropdown.ItemText>
                  {componentList
                    .filter(
                      (component) =>
                        component.details.folder === folderName &&
                        component.details.componentName.includes(
                          searchString
                        ) &&
                        component.details.year === year
                    )
                    .toSorted((a, b) => sortByProperty(a, b, 'componentName'))
                    .map((component) => (
                      <Dropdown.Item
                        onClick={() =>
                          handleAddComponent(component.componentObj)
                        }
                        className="ps-6 pe-3 text-gray-7"
                        key={component.id}
                        role="button"
                      >
                        {component.details.componentName}
                      </Dropdown.Item>
                    ))}
                </Fragment>
              ))}
            {componentList
              .filter(
                (component) =>
                  !component.details.folder &&
                  component.details.componentName.includes(searchString) &&
                  component.details.year === year
              )
              .toSorted((a, b) => sortByProperty(a, b, 'componentName'))
              .map((component) => (
                <Dropdown.Item
                  onClick={() => handleAddComponent(component.componentObj)}
                  className="px-3 text-gray-7"
                  key={component.id}
                  role="button"
                >
                  {component.details.componentName}
                </Dropdown.Item>
              ))}
            {/* {componentList.map((component) => (
            <Dropdown.Item
              onClick={() => handleAddComponent(component.componentObj)}
              className="px-4"
              key={component.id}
              role="button"
            >
              {component.details.componentName}
            </Dropdown.Item>
          ))} */}
            <Dropdown.Item
              onClick={handleOpenModal}
              className="d-flex justify-content-center py-1 fw-bold bg-maroon text-white"
              role="button"
            >
              Create new section
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <ComponentForm handleClose={handleCloseModal} addToTemplate={true} />
      </Modal>
    </>
  );
};

export default AddComponentDropdown;
