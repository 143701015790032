import React from 'react';
import TemplateList from 'components/TemplateList';
const Templates = () => {
  return (
    <div className="my-4">
      <TemplateList />
    </div>
  );
};

export default Templates;
