import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import useServiceAuth from 'auth/useServiceAuth';
import { componentDelete, componentGetAll } from 'apis/componentAPIs';
import { setComponentList } from 'state/slices/dataListsSlice';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ComponentList = () => {
  const { getAccessToken } = useServiceAuth();
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const componentList = useSelector((state) => state.dataLists.componentList);

  const {
    data: componentData,
    isPending: getComponentIsLoading,
    isSuccess: getComponentIsSuccess,
    refetch,
  } = useQuery({
    queryKey: ['components'],
    queryFn: async () => {
      const token = await getAccessToken();
      return componentGetAll({ token });
    },
  });

  const { mutate: deleteComponent } = useMutation({
    mutationFn: componentDelete,
    onSuccess: async () => {
      refetch();
    },
  });

  const handleDeleteComponent = async (id) => {
    const token = await getAccessToken();
    deleteComponent({ id, token });
  };

  useEffect(() => {
    if (getComponentIsSuccess) {
      const next = [];

      componentData.forEach((component) => {
        if (component['details']) {
          const jsonObject = JSON.parse(component['details'].S);
          const obj = {
            id: component['sk'].S,
            details: jsonObject,
            componentObj: component,
          };
          next.push(obj);
        }
      });

      dispatch(setComponentList(next));
    }
  }, [componentData, dispatch, getComponentIsSuccess]);

  if (getComponentIsLoading) return 'Loading...';

  return (
    <div className="container">
      <h3>Section List</h3>
      <ul>
        {componentList.map((component) => (
          <li key={component.id} className="d-flex align-items-center">
            <div
              onClick={() => navigate(`/component?id=${component.id}`)}
              className="text-decoration-underline d-flex gap-2 m-1"
              role="button"
            >
              {component.details.componentName}
            </div>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => handleDeleteComponent(component.id)}
            />
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ComponentList;
