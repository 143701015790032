import StaticData from 'components/StaticData';
export function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const getCoursesDescr = (courses, courseLists) => {
  if (!Array.isArray(courses) || courses.length === 0) return '';

  return courses
    .map((course) => getCourseDescr(course, courseLists))
    .filter(Boolean) // Remove empty or undefined descriptions
    .join(' ');
};

export const getCourseDescr = (course, courseLists) => {
  if (!course) return '';

  const formatCheckCourses = (checkCourses) =>
    checkCourses
      .map((cc, index, array) => {
        const prefix = index > 0 && index === array.length - 1 ? 'and ' : '';
        return `${prefix}${cc.subject} ${cc.number}`;
      })
      .join(', ');

  const formatRecommendedCourses = (courses) =>
    courses
      ?.map((c, index) => {
        const courseDetails = [
          c.connector,
          c.subject,
          c.number,
          c.proposedTitle ? 'Proposed' : '',
        ]
          .filter(Boolean)
          .join(' ');
        return index === 0 ? courseDetails : `or ${courseDetails}`;
      })
      .join(', ');

  return [
    course.connector !== 'or' && course.connector && ' ' + course.connector,
    course.itemType === 'req_check' && course.checkType === 'gpa'
      ? 'Check:'
      : '',
    course.itemType === 'req_check' && course.checkType === 'milestone'
      ? 'Milestone:'
      : '',
    course.gpa ? `Minimum ${course.gpa}` : '',
    course.checkSubtype === 'asu_cum_gpa' ? 'ASU Cumulative GPA' : '',
    course.checkSubtype === 'major_gpa' ? 'Major GPA' : '',
    ['subject', 'courses'].includes(course.checkSubtype) ? 'GPA in' : '',
    course.checkSubtype === 'subject' ? 'all' : '',
    course.checkSubtype === 'courses' && course.checkCourses
      ? formatCheckCourses(course.checkCourses)
      : '',
    course.milestone ? Object.values(course.milestone)[0] : '',
    course.subject && `${course.subject} `,
    course.number && `${course.number}:`,
    course.topic?.description ||
      (course.proposedTitle && `${course.proposedTitle} Proposed`) ||
      course.description ||
      '',
    course.division
      ? (['Upper', 'Lower'].includes(course.division)
          ? `${course.division} Division`
          : `${course.division}-Level`) +
        (course.itemType !== 'req_course_list' ? ' ' : '')
      : '',
    course.itemType === 'req_elective' || course.itemType === 'req_subject'
      ? 'Elective'
      : '',
    course.itemType === 'req_gs' && course.gs
      ? `${StaticData.gsList.find((gs) => gs.value === course.gs)?.label} (${course.gs})`
      : '',
    course.group ? `${course.group}:` : '',
    course.text || '',
    course.customText || '',
    courseLists && courseLists[course.courseListId]
      ? `List: ${courseLists[course.courseListId]?.courseListName || 'Untitled course list'}`
      : '',
    course?.recommended?.courses?.length
      ? `Recommended: ${formatRecommendedCourses(course.recommended.courses)}`
      : '',
    course.checkSubtype === 'subject' ? 'courses' : '',
  ]
    .filter(Boolean)
    .join(' ');
};

//set default velue for empty properties for JSON object/array.
//we use this for general study validations. hours, gpa, upperdivision hours
export const addJsonPropertyWithDefault = (json, properties) => {
  if (Array.isArray(json)) {
    return json.map((obj) => {
      const clonedObj = { ...obj }; // Clone the object
      properties.forEach((property) => {
        if (!clonedObj[property]) {
          clonedObj[property] = 0;
        }
      });
      return clonedObj;
    });
  } else {
    const clonedObj = { ...json };
    properties.forEach((property) => {
      if (!clonedObj[property]) {
        clonedObj[property] = 0;
      }
    });
    return clonedObj;
  }
};

export const createDoughnutData = (current = 0, total = 0, error = false) => {
  let fulfilled = 0;
  let remainder = 100;

  if (current > 0 && total > 0) {
    fulfilled = Math.round((current / total) * 100);
    remainder = 100 - fulfilled;

    fulfilled = fulfilled >= 100 ? 100 : fulfilled;
    remainder = remainder <= 0 ? 0 : remainder;
  }

  return {
    datasets: [
      {
        data: [fulfilled, remainder],
        backgroundColor: [error ? '#bd4800' : '#78BE20', '#E8E8E8'],
        borderWidth: 0,
      },
    ],
  };
};
