import React, { useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import DropdownCustomToggle from './DropdownCustomToggle';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@asu/components-core';
import useServiceAuth from 'auth/useServiceAuth';
import { useMutation, useQuery } from '@tanstack/react-query';
import { checksheetSave } from 'apis/checksheetAPIs';
import { templateGetAllByYear } from 'apis/templateAPIs';
import { setTemplateList } from 'state/slices/dataListsSlice';

const NoChecksheetFound = ({ checksheetId, program }) => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const idArray = checksheetId.split('*');
  const paramYear = idArray[0];
  const paramCollege = idArray[1];
  const paramAcadPlan = idArray[2];
  const paramAcadSubplan = idArray[3];
  let mainOwner = {};

  const [error, setError] = useState('');

  const catalogYear = useSelector((state) => state.settings.year);
  const role = useSelector((state) => state.user.role);
  const templateList = useSelector((state) => state.dataLists.templateList);

  if (!!program?.owners?.length) {
    let highestPercent = 0;

    program.owners.forEach((programOwner) => {
      if (programOwner.percentOwned > highestPercent) {
        highestPercent = programOwner.percentOwned;
        mainOwner = programOwner;
      }
    });
  }

  const { data: templateData, isSuccess } = useQuery({
    queryKey: ['templates', catalogYear],
    queryFn: async () => {
      const token = await getAccessToken();
      return templateGetAllByYear({ token, year: catalogYear });
    },
    enabled: !!catalogYear && !!role && role.includes('PROVOST'),
  });

  const {
    mutate: saveChecksheet,
    error: saveChecksheetError,
    isError: saveChecksheetIsError,
  } = useMutation({
    mutationFn: checksheetSave,
  });

  const getProgramType = (program) => {
    if (program.degreeDescriptionShort) {
      return program.degreeDescriptionShort;
    } else return program.acadPlanType;
  };

  const handleCreateChecksheet = async (template) => {
    const progType = getProgramType(program);
    const jsonData = {
      year: paramYear,
      templateId: template['sk'].S,
      checksheetName: program.acadPlanDescription + ', ' + progType,
      programType: progType,
      acadplan: paramAcadPlan,
      acadsubplan: paramAcadSubplan,
      college: paramCollege,
      department: mainOwner.departmentAcadOrg ?? '',
      componentRequirements: {},
      courseLists: {},
    };

    const token = await getAccessToken();
    await saveChecksheet({
      jsonData: jsonData,
      token: token,
    });

    if (saveChecksheetIsError) setError(saveChecksheetError.message);
  };

  useEffect(() => {
    if (isSuccess) {
      const next = [];
      templateData.forEach((template) => {
        if (template['details']) {
          const jsonObject = JSON.parse(template['details'].S);
          const obj = {
            id: template['sk'].S,
            details: jsonObject,
            templateObj: template,
          };
          next.push(obj);
        }
      });

      dispatch(setTemplateList(next));
    }
  }, [templateData, dispatch, isSuccess]);

  if (saveChecksheetIsError) return <div>{error}</div>;

  return (
    <div className="container mt-4">
      <div className="fs-4 mb-2">
        A checksheet for{' '}
        <span className="fw-bold">
          {program.acadPlanDescription}, {getProgramType(program)}
        </span>{' '}
        does not exist.
      </div>
      <div>
        {!!role && role.includes('PROVOST')
          ? 'Please choose a template to create new checksheet.'
          : 'You must be a Provost user to create a new checksheet.'}
      </div>
      <div className="row">
        <div className="col uds-full-width">
          <div>
            <Dropdown className="add-component">
              <Dropdown.Toggle
                as={DropdownCustomToggle}
                id="dropdown-custom-components"
              >
                <Button label="Choose Template" color="gold" />
              </Dropdown.Toggle>

              <Dropdown.Menu className="py-0" style={{ width: '340px' }}>
                <Dropdown.ItemText>
                  <input
                    type="text"
                    className="w-100 px-3 py-1"
                    placeholder="Search library"
                  />
                </Dropdown.ItemText>

                {templateList
                  // .filter((component) => !component.details.folder)
                  // .toSorted((a, b) => sortByProperty(a, b, 'componentName'))
                  .map((template) => (
                    <Dropdown.Item
                      onClick={() =>
                        handleCreateChecksheet(template.templateObj)
                      }
                      className="px-3"
                      key={template.id}
                      role="button"
                    >
                      {template.details.templateName}
                    </Dropdown.Item>
                  ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NoChecksheetFound;
