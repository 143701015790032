import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentComponent: '',
  componentId: '',
  year: { value: '2023', label: '2023-2024' },
  componentName: 'Section Heading',
  notes: '',
  componentSettings: {
    hours: 0,
    upperDivisionHours: 0,
  },
  // componentRequirementList: [],
  subsections: {},
  folder: null,
  isComponentLoading: false,
};

export const componentSlice = createSlice({
  name: 'component',
  initialState,
  reducers: {
    setCurrentComponent: (state, action) => {
      state.currentComponent = action.payload;
    },
    setAll: (state, action) => {
      state.componentId = action.payload.componentId;
      state.year = action.payload.year;
      state.componentName = action.payload.componentName;
      state.notes = action.payload.notes;
      state.componentSettings = action.payload.componentSettings;
      // state.componentRequirementList = action.payload.componentRequirementList;
      state.subsections = action.payload.subsections;

      state.folder = action.payload.folder;
      state.isComponentLoading = action.payload.isComponentLoading;

      return state;
    },
    setComponentId: (state, action) => {
      const updatedState = { ...state };

      updatedState.componentId = action.payload;

      return updatedState;
    },
    setYear: (state, action) => {
      const updatedState = { ...state };

      updatedState.year = action.payload;

      return updatedState;
    },
    setComponentName: (state, action) => {
      const updatedState = { ...state };

      updatedState.componentName = action.payload;

      return updatedState;
    },
    setNotes: (state, action) => {
      const updatedState = { ...state };

      updatedState.notes = action.payload;

      return updatedState;
    },
    setComponentSettings: (state, action) => {
      const updatedState = { ...state };

      updatedState.componentSettings = action.payload;

      return updatedState;
    },
    // setComponentRequirementList: (state, action) => {
    //   const updatedState = { ...state };

    //   updatedState.componentRequirementList = action.payload;

    //   return updatedState;
    // },
    setSubsections: (state, action) => {
      const updatedState = { ...state };

      updatedState.subsections = action.payload;

      return updatedState;
    },
    addRequirement: (state, action) => {
      const updatedState = { ...state };
      const { subsectionId, requirement } = action.payload;
      const updatedSubsections = { ...updatedState.subsections };
      const updatedSubsection = {
        ...updatedSubsections[subsectionId],
      };
      const updatedRequirements = [...updatedSubsection.requirements];

      updatedRequirements.push(requirement);
      updatedSubsection.requirements = updatedRequirements;
      updatedSubsections[subsectionId] = updatedSubsection;
      updatedState.subsections = updatedSubsections;

      return updatedState;
    },
    removeRequirement: (state, action) => {
      const updatedState = { ...state };
      const { subsectionId, requirementId } = action.payload;
      const updatedSubsections = { ...updatedState.subsections };
      const updatedSubsection = {
        ...updatedSubsections[subsectionId],
      };
      const updatedRequirements = [...updatedSubsection.requirements];
      const requirementIndex = updatedRequirements.findIndex(
        (req) => req.requirementId === requirementId
      );

      updatedRequirements.splice(requirementIndex, 1);
      updatedSubsection.requirements = updatedRequirements;
      updatedSubsections[subsectionId] = updatedSubsection;
      updatedState.subsections = updatedSubsections;

      return updatedState;
    },
    moveRequirement: (state, action) => {
      const updatedState = { ...state };
      const { oldSubsectionId, newSubsectionId, requirement } = action.payload;
      const updatedSubsections = { ...updatedState.subsections };
      let updatedOldSubsection = {
        ...updatedSubsections[oldSubsectionId],
      };
      let updatedNewSubsection = {
        ...updatedSubsections[newSubsectionId],
      };

      if (!Object.keys(updatedNewSubsection).length) {
        updatedNewSubsection = {
          requirements: [],
          hours: 0,
          upperDivisionHours: 0,
          subsectionName: '',
        };
      } else if (!updatedNewSubsection.requirements) {
        updatedNewSubsection.requirements = [];
      }

      if (!!Object.keys(updatedOldSubsection).length) {
        const updatedOldRequirements = [...updatedOldSubsection.requirements];
        const requirementIndex = updatedOldRequirements.findIndex(
          (req) => req.requirementId === requirement.requirementId
        );

        updatedOldRequirements.splice(requirementIndex, 1);
        updatedOldSubsection.requirements = updatedOldRequirements;
        updatedSubsections[oldSubsectionId] = updatedOldSubsection;
      }

      const updatedNewRequirements = [...updatedNewSubsection.requirements];

      updatedNewRequirements.push(requirement);
      updatedNewSubsection.requirements = updatedNewRequirements;
      updatedSubsections[newSubsectionId] = updatedNewSubsection;
      updatedState.subsections = updatedSubsections;

      return updatedState;
    },
    setFolder: (state, action) => {
      const updatedState = { ...state };

      updatedState.folder = action.payload;

      return updatedState;
    },
    clearComponent: (state) => {
      console.log('clearComponent');

      return { ...initialState };
    },
    setComponentLoading: (state, action) => {
      state.isComponentLoading = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setCurrentComponent,
  setAll,
  setComponentId,
  setYear,
  setComponentName,
  setNotes,
  setComponentSettings,
  // setComponentRequirementList,
  setSubsections,
  addRequirement,
  removeRequirement,
  moveRequirement,
  setFolder,
  clearComponent,
  setComponentLoading,
} = componentSlice.actions;

export default componentSlice.reducer;
