import { Button } from '@asu/components-core';
import {
  faCheckCircle,
  faGear,
  faPenToSquare,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StaticData from 'components/StaticData';
import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const RolloverSuccess = () => {
  const destinationYear = useSelector(
    (state) => state.rollover.destinationYear
  );

  return (
    <div className="px-0 mt-5 text-gray-7 container-xl">
      <div>
        <h1 className="mb-4">
          <span className="highlight-gold">Rollover successful</span>
          <FontAwesomeIcon icon={faCheckCircle} className="text-success ms-4" />
        </h1>
        <p style={{ width: '47%' }}>
          All checksheets, templates, and subplan configurations have been
          successfully rolled over to the new catalog year.
        </p>
        <hr
          style={{ height: '8px', width: '22%', backgroundColor: '#FFC627' }}
        />
        <h2 className="mb-4">
          Action items
          {destinationYear &&
            ' for ' +
              StaticData.yearList.find((year) => year.value === destinationYear)
                .label}
        </h2>
        <div className="d-flex gap-3 align-items-center">
          <div
            className="d-flex flex-column justify-content-between align-items-start flex-grow-1 flex-shrink-1  p-4 border border-gray-3 align-self-stretch"
            style={{ flexBasis: '0' }}
          >
            <div>
              <FontAwesomeIcon
                icon={faGear}
                className="mb-1"
                style={{ height: '40px' }}
              />
              <div className="fw-bold fs-4 mb-4">
                Change default catalog year
              </div>
            </div>
            <Button
              label="Go to Settings"
              color="maroon"
              element={Link}
              to="/settings"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-between align-items-start flex-grow-1 flex-shrink-1  p-4 border border-gray-3 align-self-stretch"
            style={{ flexBasis: '0' }}
          >
            <div>
              <FontAwesomeIcon
                icon={faPenToSquare}
                className="mb-1"
                style={{ height: '40px' }}
              />
              <div className="fw-bold fs-4">Review and edit templates</div>
              <div
                className="fw-bold bg-gold px-1 mb-4"
                style={{ width: 'min-content' }}
              >
                Optional
              </div>
            </div>
            <Button
              label="Go to Templates"
              color="maroon"
              element={Link}
              to="/templates"
            />
          </div>
          <div
            className="d-flex flex-column justify-content-between align-items-start flex-grow-1 flex-shrink-1  p-4 border border-gray-3 align-self-stretch"
            style={{ flexBasis: '0' }}
          >
            <div>
              <FontAwesomeIcon
                icon={faToggleOn}
                className="mb-1"
                style={{ height: '40px' }}
              />
              <div className="fw-bold fs-4">Enable/disable subplans</div>
              <div
                className="fw-bold bg-gold px-1 mb-4"
                style={{ width: 'min-content' }}
              >
                Optional
              </div>
            </div>
            <Button
              label="Go to Subplans"
              color="maroon"
              element={Link}
              to="/subplanactivation"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RolloverSuccess;
