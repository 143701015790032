import { Button } from '@asu/components-core';
import {
  faCheckCircle,
  faSpinner,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Toast from 'react-bootstrap/Toast';
import { Link } from 'react-router-dom';

function RolloverToast({ finished, handleClose }) {
  return (
    <Toast>
      {/* <Toast.Header>
        <strong className="me-auto">Bootstrap</strong>
        <small>11 mins ago</small>
      </Toast.Header> */}
      <Toast.Body>
        {finished ? (
          <div>
            <FontAwesomeIcon
              icon={faCheckCircle}
              className="me-1 text-success"
            />
            <span className="me-2">Rollover finished!</span>
            <Button
              label="Next steps"
              color="gold"
              size="xsmall"
              element={Link}
              to={'/rollover-success'}
              onClick={handleClose}
            />
            <FontAwesomeIcon
              icon={faXmark}
              onClick={handleClose}
              className="ms-10 text-success"
            />
          </div>
        ) : (
          <div>
            <FontAwesomeIcon icon={faSpinner} spin className="me-1" />
            Rollover in progress...
          </div>
        )}
      </Toast.Body>
    </Toast>
  );
}

export default RolloverToast;
