import axios from 'axios';

export async function checksheetGetAll({ token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get-all`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    // console.log(response.data);
    res = response.data;
  });

  return res;
}

export async function checksheetGetAllByYear({ year, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get-all/${year}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    // console.log(response.data);
    res = response.data;
  });

  return res;
}

export async function checksheetGetAllByYearAndCollege({
  year,
  college,
  token,
}) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get-all/${year}/${college}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = [];

  await axios.get(api, headers).then((response) => {
    // console.log(response.data);
    res = response.data;
  });

  return res;
}

export async function checksheetGet({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    // console.log('ChecksheetData: ', response.data);
    res = response.data;
  });
  // .catch((error) => {
  //   console.log('get Error response status:', error.response.status);
  //   console.log('get Error response data:', error.response.data);
  // });

  return res;
}

// export async function checksheetGetCourseList({ id, courseListId, token }) {
//   const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get/${id}`;
//   console.log('API Endpoint:', api);

//   const headers = {
//     Authorization: `Bearer ${token}`,
//   };

//   try {
//     const response = await axios.get(api, { headers });
//     console.log('ChecksheetData:', response.data);

//     const { details } = response.data;

//     if (
//       !details ||
//       !details.courseLists ||
//       !details.courseLists[courseListId]
//     ) {
//       return {};
//     }

//     return details.courseLists[courseListId];
//   } catch (error) {
//     console.error(
//       'Error fetching course list:',
//       error.response?.status,
//       error.response?.data
//     );
//     return {};
//   }
// }

export async function checksheetDelete({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/delete/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };

  return await axios.delete(api, headers).then((response) => {
    console.log(response.data);
  });
}

export async function checksheetSave({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/save`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
      window.location.reload();
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetUpdate({ id, jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/update/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .patch(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetUpdateProgress({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/update/progress`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .patch(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetGetProgressByYear({ year, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get-progress/${year}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .get(api, headers)
    .then((response) => {
      // console.log('ProgressData: ', response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('get Error response status:', error.response.status);
      console.log('get Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetCopy({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/copy`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .put(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetLock({ id, jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/lock/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .patch(api, jsonData, headers)
    .then((response) => {
      console.log(response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('Error response status:', error.response.status);
      console.log('Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetGetHistory({ id, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/get/history/${id}`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios
    .get(api, headers)
    .then((response) => {
      // console.log('Checksheet history: ', response.data);
      res = response.data;
    })
    .catch((error) => {
      console.log('get Error response status:', error.response.status);
      console.log('get Error response data:', error.response.data);
    });

  return res;
}

export async function checksheetCopyAll({ jsonData, token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/copy-all`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.put(api, jsonData, headers).then((response) => {
    console.log(response.data);
    res = response.data;
  });
  // .catch((error) => {
  //   console.log('Error response status:', error.response.status);
  //   console.log('Error response data:', error.response.data);
  // });

  return res;
}

export async function checksheetCopyAllProgress({ token }) {
  const api = `${process.env.REACT_APP_API_ENDPOINT}/checksheet/copy-all/progress`;
  console.log('api: ' + api);
  const headers = {
    headers: { Authorization: `Bearer ${token}` },
  };
  let res = '';

  await axios.get(api, headers).then((response) => {
    // console.log(response.data);
    res = response.data;
  });

  return res;
}
