import { Button } from '@asu/components-core';
import React, { useEffect, useState } from 'react';
import StaticData from './StaticData';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faSpinner } from '@fortawesome/free-solid-svg-icons';
import {
  checksheetCopyAll,
  // checksheetCopyAllProgress,
} from 'apis/checksheetAPIs';
import {
  useMutation,
  //  useQuery
} from '@tanstack/react-query';
import useServiceAuth from 'auth/useServiceAuth';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPolling,
  setSourceYear as setGlobalSource,
  setDestinationYear as setGlobalDestination,
  setInitiator,
  setError,
} from 'state/slices/rolloverSlice';
import { Link } from 'react-router-dom';

const RolloverCatalog = () => {
  const { getAccessToken } = useServiceAuth();
  const dispatch = useDispatch();

  const [sourceYear, setSourceYear] = useState('2025');
  const [destinationYear, setDestinationYear] = useState('2025');
  const [startingRollover, setStartingRollover] = useState(false);
  const [showFinished, setShowFinished] = useState(false);

  const currentStatus = useSelector((state) => state.rollover.currentStatus);
  const isPolling = useSelector((state) => state.rollover.isPolling);
  const rolloverError = useSelector((state) => state.rollover.error);
  const asurite = useSelector((state) => state.user.asurite);
  const catalogYear = useSelector((state) => state.settings.year);

  const { mutate: copyAllChecksheet } = useMutation({
    mutationFn: checksheetCopyAll,
    onSuccess: () => {
      console.log('Copy all success');
    },
    onError: () => {
      console.log('Copy all error');
    },
  });

  const handleCopyAll = async () => {
    setStartingRollover(true);

    const token = await getAccessToken();

    await copyAllChecksheet({
      jsonData: { yearfrom: sourceYear, yearto: destinationYear },
      token,
    });

    dispatch(setGlobalSource(sourceYear));
    dispatch(setGlobalDestination(destinationYear));
    dispatch(setInitiator(asurite));
    dispatch(setPolling(true));
    dispatch(setError(false));
    setShowFinished(true);
  };

  useEffect(() => {
    if (
      (isPolling && currentStatus === 'IN PROGRESS') ||
      (!isPolling && currentStatus === 'DONE')
    )
      setStartingRollover(false);
  }, [currentStatus, isPolling]);

  useEffect(() => {
    if (catalogYear) {
      setSourceYear(catalogYear);
      setDestinationYear(String(Number(catalogYear) + 1));
    }
  }, [catalogYear]);

  return (
    <div>
      <h2>Select catalog years</h2>
      <div className="d-flex gap-3 mb-6">
        <div>
          <div className="fw-bold">Source catalog year</div>
          <select
            value={sourceYear}
            onChange={(e) => setSourceYear(e.target.value)}
            style={{ width: '182px' }}
          >
            {StaticData.yearList.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </select>
        </div>
        <FontAwesomeIcon
          icon={faArrowRight}
          className="mt-4"
          style={{ width: '24px', height: '24px' }}
        />
        <div>
          <div className="fw-bold">Destination catalog year</div>
          <select
            value={destinationYear}
            onChange={(e) => setDestinationYear(e.target.value)}
            style={{ width: '182px' }}
          >
            {StaticData.yearList.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <Button
        label="Start rollover"
        color={
          startingRollover || currentStatus === 'IN PROGRESS'
            ? undefined
            : 'gold'
        }
        onClick={handleCopyAll}
        disabled={startingRollover || currentStatus === 'IN PROGRESS'}
      />
      {startingRollover || currentStatus === 'IN PROGRESS' ? (
        <div className="d-flex gap-1 align-items-center mt-3">
          <FontAwesomeIcon icon={faSpinner} spin />
          <div className="fw-bold">
            {startingRollover
              ? 'Starting rollover. Please wait.'
              : 'Rollover in progress. You may now navigate away from this page.'}
          </div>
        </div>
      ) : showFinished ? (
        !rolloverError ? (
          <div className="d-flex gap-3 align-items-center mt-3">
            <div className="fw-bold">Rollover completed!</div>
            <Button
              label="Next steps"
              color="maroon"
              element={Link}
              to={'/rollover-success'}
            />
          </div>
        ) : (
          <div className="fw-bold mt-3">
            There was an error during the rollover process. Please try again.
          </div>
        )
      ) : null}
    </div>
  );
};

export default RolloverCatalog;
