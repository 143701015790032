import { createSlice } from '@reduxjs/toolkit';

const rolloverSlice = createSlice({
  name: 'rollover',
  initialState: {
    currentStatus: null,
    isPolling: true,
    sourceYear: '',
    destinationYear: '',
    initiator: '',
    isInitialRun: true,
    error: false,
  },
  reducers: {
    setRolloverStatus: (state, action) => {
      state.currentStatus = action.payload;
    },
    setPolling: (state, action) => {
      state.isPolling = action.payload;
    },
    setSourceYear: (state, action) => {
      state.sourceYear = action.payload;
    },
    setDestinationYear: (state, action) => {
      state.destinationYear = action.payload;
    },
    setInitiator: (state, action) => {
      state.initiator = action.payload;
    },
    setIsInitialRun: (state, action) => {
      state.isInitialRun = action.payload;
    },
    setError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  setRolloverStatus,
  setPolling,
  setSourceYear,
  setDestinationYear,
  setInitiator,
  setIsInitialRun,
  setError,
} = rolloverSlice.actions;
export default rolloverSlice.reducer;
